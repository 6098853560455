<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="course_activity_id" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('course_activity')">
                                        <course-activity-selectbox
                                            v-model="formData.course_activity_id"
                                            type="assessment"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="subject" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('effect_ratio')">
                                        <b-row>
                                            <b-col sm="6" md="2">
                                                <b-form-input type="number" step="0.01" v-model="formData.effect_ratio" />
                                            </b-col>
                                            <b-col sm="6" md="10" class="mt-2">
                                                <b-form-input v-model="formData.effect_ratio"
                                                                      type="range"
                                                                      mim="0"
                                                                      max="100"
                                                                      step="0.01"
                                                                      :class="errors[0] ? 'is-invalid':''" />
                                            </b-col>
                                        </b-row>
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="2">
                                <ValidationProvider name="number" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('activity_count')">
                                        <b-form-input type="number" v-model="formData.number" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button
                    variant="primary"
                    @click="save"
                    :disabled="formLoading">
                    {{ $t("save") }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CourseActivitySelectbox from "@/components/interactive-fields/CourseActivitySelectbox"

// Services
import EctsService from "@/services/EctsService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        CourseActivitySelectbox
    },
    props: {
        course: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
        };
    },
    created() {
        this.formData = this.course
    },
    methods: {
        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                if(this.formData.actionType == 'new'){
                    this.formData.course_id = this.formData.id
                    EctsService.storeCourseCoordinatorCourseAssessments(this.formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$emit('closeModal', true)
                        this.formLoading = false
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate)
                        this.formLoading = false
                    })
                } else {
                    EctsService.updateCourseCoordinatorCourseAssessments(this.formData.id, this.formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$emit('closeModal', true)
                        this.formLoading = false
                    }).catch(e => {
                        this.showErrors(e, this.$refs.formValidate)
                        this.formLoading = false
                    })
                }

            }
        }
    },
}
</script>
