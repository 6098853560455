<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
            <template slot="option" slot-scope="{ option }">
                <span v-html="option.status === 'a'
                    ? `<b>${option.text}</b> - <b>${$t('active')}</b>`
                    : `${option.text} - ${$t('passive')}`">
                </span>
            </template>

        </multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError">
        </span>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import UniversityService from "@/services/UniversityService";

	// Other
	import qs from "qs";
    import style from "@/modules/reservation/pages/style";

	export default {
		props: {
			countryId: {
				default: null
			},
            status: {
                default: null
            },
			isCountryRequired: {
				type: Boolean,
				default: false
			},
            type:{
                type: Number,
                default: null
            },
            isTypeRequired:{
                type: Boolean,
                default: false
            },
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            public: {
                type: Boolean,
                default: false
            },
            isOther: {
                type: Boolean,
                default: false
            },
			transfer: {
                type: String,
                default: ""
            },
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		watch: {
			countryId: function () {
				this.getOptions();
			},
            type: function () {
				this.getOptions();
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
                if (this.selected[0].status==='p'){
                    this.$toast.error('Seçilen üniversitenin durumu pasiftir.!')
                }
			},

		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
                this.clearOptions();
				if (this.isCountryRequired && !this.countryId) {
					return;
				}
                if (this.isTypeRequired && !this.type) {
					return;
				}



                if(this.public){
					const config = {
                    params: {
						country_id: this.countryId,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                    UniversityService.getAllPublic(config)
                                     .then(response => {
										const data = response.data.data;
										data.map(item => {
                                            this.options.push({
                                                value: item.id,
                                                text: item.name,
                                            });
                                        })
										if(this.isOther){
											this.options.push({
												value: 'other',
												text: this.transfer ? this.$t('university_not_list') : this.$t('other'),
											});
										}
									})
									.then(() => {
										this.selected = this.setSelected(this.value, this.options)
									})
                }
                else {
					let filter = {
						country_id: this.countryId ? this.countryId:null,
						type: this.type ? this.type:null,
                        status: this.status ? this.status:null,
					}

					const config = {
                    params: {
                        filter: filter,
						country_id: this.countryId,
                        sort: this.getLocaleField('name'),
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                    UniversityService.getAll(config)
						.then(response => {
							const data = response.data.data;
							data.map(item => {
								this.options.push({
									value: item.id,
                                    text:item.name,
                                    status:item.status,
								});
							})
							if(this.isOther){
								this.options.push({
									value: 'other',
									text: this.$t('other'),
								});
							}
						})
						.then(() => {
							this.selected = this.setSelected(this.value, this.options)
						})
                }
			},
			clearOptions() {
				this.options = [];
				this.selected = null;
			}
		}
	}
</script>
