const translations = {

    document_request_info_text: "Öğrencilerimizin talep ettikleri (öğrenci belgesi, transkript, disiplin sicili, dil yeterlik belgesi vb.) belgeleri kolay temin etmelerini sağlamak amacıyla 27.07.2022 tarihinden itibaren OIS üzerinden gerçekleştirilecek talepler nitelikli elektronik imzalı olarak alınabilecektir. Ancak ıslak imzalı belge talep eden öğrencilerimiz, OIS üzerinden başvurularını gerçekleştirdikten sonra bu belgelerini Beşiktaş Kampüsü Öğrenci İşleri Daire Başkanlığı’ndan almaları gerekmektedir.Gerek ıslak imza gerekse de nitelikli elektronik imzalı olarak aldığınız belgelerin “resmî belge” niteliğini haiz olması nedeniyle; yürürlükteki yasa ve yönetmeliklere aykırı kullanılması, değiştirilmesi, veriliş amacına aykırı olarak kullanılması ve başkasına kullandırılmasının Yükseköğretim Kurumları Öğrenci Disiplin Yönetmeliği kapsamında disiplin cezasını gerektiren eylemlerden olduğu gibi Türk Ceza Kanunu kapsamında da suç teşkil ettiğini hatırlatmak isteriz. Öğrenci belgenizi E-Devlet şifresi ile E-Devlet üzerinden alabilirsiniz. E-Devlet üzerinden alınan öğrenci belgeleri tüm kurum ve kuruluşlarda geçerlidir.",
    kvkk_content_pilotaj: "Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium",
    info_text_content: "Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium Lorem ipsium ",
    kvkk_content:
    "<h4>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Lisansüstü Öğrenciler</h4>"+
    "Bu metin, Bahçeşehir Üniversitesi’nin kişisel verilerinizi nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin işlenmesine ilişkin konular, aşağıda başlıklar halinde sıralanmıştır."+
    "<div class='mb-2'><b>	Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
    "Bahçeşehir Üniversitesi (“BAU”) olarak, lisansüstü programlarda öğrenim gören öğrencilerimizin kişisel verilerinin nasıl "+
    " işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Bir diğer ifadeyle, kişisel verilerinizi işlerken "+
    "veri sorumlusu olarak hareket ediyoruz.  <br/><br/>"  +
    "<div class='mb-2'><b> Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor?</b></div>" +
    "Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz: <br/><br/>" +
    "− <b>	Kişisel verilerinizi toplama kanalları </b>  ➢  Fiziki ortamlarda form doldurmanız veya bilgilerinizi bizimle paylaşmanız;"+
    "öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital ortamlarda yer alan iletişim formlarını doldurmanız;"+
    "telefon, e-posta, sosyal medya hesapları veya BAU whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar"+
    "aracılığıyla kişisel verileriniz toplanmaktadır.   <br/><br/>" +
    "− <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢  Kişisel verileriniz, kısmen otomatik  veya veri kayıt" +
    "sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.<br/><br/>" +
    "− <b>Kişisel  verilerinizin  toplanmasının  hukuki  sebepleri</b>  ➢ Kişisel  verileriniz,  Kanun’un  5." +
    "maddesinde belirtilen hukuki sebeplerden hukuki yükümlülüklerimizin yerine getirilmesi," +
    "sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin" +
    "zorunlu olması, ilgili  kişinin  temel  hak  ve  özgürlüklerine  zarar  vermemek  kaydıyla  veri" +
    "işlenmesinin meşru menfaatlerimiz için zorunlu olması ile ilgili kişinin açık rızasının buluması" +
    "hukuki sebeplerine dayalı olarak işlenmektedir. <br/>" +
    " <br/>" +
    "<div class='mb-2'><b> Hangi kişisel verilerim işleniyor?</b></div>" +
    "BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br/>" +
    "<div class='mt-2 mb-4 table-dropdown no-scrollbar border rounded  table-responsive'>"+
    "<table class='table b-table table-striped table-bordered'>"+
    "<thead>"+
    "<tr>"+
    "<th>Kimlik</th>"+
    "<th>İletişim</th>"+
    "<th>Eğitim</th>"+
    "<th>Diğer Bilgileriniz</th>"+
    "</tr>"+
    "</thead>"+
    "<tbody>"+
    "<tr>"+
    "<td>Ad-soyad, kimlik numarası, cinsiyet, uyruk, doğum yeri ve tarihi, kimlik fotokopisi, öğrenci numarası, uluslararası öğrencilerimizin pasaport bilgileri</td>"+
    "<td>Telefon numarası, e-posta adresi, iş ve ev adresi.</td>"+
    "<td>Lisansüstü başvuru bilgileri, okul/fakülte/bölüm bilgileri, mezuniyet ortalaması, mezuniyet yılı ve derecesi, ingilizce yeterlilik bilgileri, diploma ve transkript bilgileri, alınan dersler, proje, ödev, sunum, tez vb. eğitim materyalleri, danışman bilgisi, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, uluslararası öğrencilerimizin kabul ve denklik bilgileri</td>"+
    "<td>Askerlik durumu, çalıştığınız sektör, çalıştığınız kurum, mesleğiniz, çalıştığınız birim, unvanınız, iş deneyiminiz, fotoğrafınız, özgeçmiş ve referans bilgileri, işlem güvenliği bilgileri, öğrenci işlem kayıtları, imza, banka ve IBAN bilgileri.</td>"+
    "</tr>"+
    "</tbody>"+
    "</table>"+
    "</div>"+
    "<del>Mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ayrıca sağlık verilerini de işliyoruz.</del>"+
    "<div class='mb-2'><b>  Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
    "Kişisel Verilerin Korunması Kanunu’nun (\"Kanun”) 5. maddesi, kişisel verilerin işlenmesine ilişkin hukuki" +
    "sebepleri düzenlemektedir. Kişisel verilerinizi bu madde kapsamında, aşağıda belirtilen amaçlar ve hukuki sebeplere uygun olarak la yurtiçinde veya yurtdışında işliyoruz:</br><br/>" +
    "<div class='row'>"+
    "<div class='col-4'>Sözleşmenin kurulması veya ifası</div>"+
    "<div class='col-8'>"+
    "Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, <hr>"+
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</div>"+
    "<div class='col-8'>"+
    "Öğrenci kayıt sözleşmesinin imzalanması; lisansüstü programları kayıt, nakil ve kayıt dondurma/iptal "+
    "süreçlerinin yürütülmesi. Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması,"+
    " Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi,"+
    " BAU öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi,"+
    " Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
    " Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması,"+
    " Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması,"+
    " Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması,"+
    " Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi,"+
    "<hr>"+
    "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de"+
    " dahil olmak üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi,"+
    " Öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin"+
    " alınması ve sonuçlandırılması, Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi,"+
    " Faaliyetlerimizin iyileştirilmesine ilişkin çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması,"+
    " Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına ilişkin süreçlerin yürütülmesi; bu kapsamda,"+
    " öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe süreçlerinin yürütülmesi."+
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</div>"+
    "<div class='col-8'>"+
    "Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, "+
    "yüksek lisans, burs ve benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda"+
    " haklarımızın korunabilmesi, kullanılabilmesi ve yasal süreçlerin yürütülmesi.<hr>"+
    "</div>"+
    "<div class='col-4 d-flex align-items-center'>Veri işlemenin Kanunlarda açıkça öngörülmesi ve"+
    " hukuki yükümlülüklerimizin yerine getirilmesi</div>"+
    "<div class='col-8'>"+
    "Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili" +
    "kurum veya kuruluşların Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü "+
    "durumlarda, yasal yükümlülüklerimizin yerine getirilmesi"+
    "</div>"+
    "<div class='col-4'>Kayıt İşlemleri</div>"+
    "<div class='col-8'>"+
    "Lisansüstü programları kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi<hr>"+
    "</div>"+
    "<div class='col-4'>Eğitim ve Öğretim Faaliyetleri</div>"+
    "<div class='col-8'>"+
    "Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
    " öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve"+
    " sosyal gelişimlerinin takibi, ölçme ve değerlendirme süreçlerinin yürütülmesi<hr>"+
    "</div>"+
    "<div class='col-4'>Organizasyon ve Etkinlikler</div>"+
    "<div class='col-8'>"+
    "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi.<hr>"+
    "</div>"+
    "<div class='col-4'>Diğer Faaliyetlerimizdeki Amaçlar</div>"+
    "<div class='col-8'>"+
    "İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama"+
    " kullanıcı hesaplarının oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması,"+
    " faaliyetlerimizin denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi,"+
    " faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe"+
    " süreçlerinin yürütülmesi. <hr>"+
    "</div>"+
    "</div>"+
    "<div class='mb-2'><b> Kişisel verilerimin aktarıldığı taraflar ve aktarılma amaçları nelerdir  başkalarına aktarılıyor mu? </b></div>" +
    "Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir: <br/>" +
    "<ul>"+
    "<li>	Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki"+
    " yükümlülüklerimizin yerine getirilmesi  → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
    "<li>	Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın " +
    " devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.</li>" +
    "<li>	Denetim ve raporlamaların yapılabilmesi, organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, iş ortaklarımız ile olan ilişkilerin yönetimi,"+
    " Bahçeşehir Grup Şirketleri politika ve kurallarına uyumluluğun sağlanması → İş Ortakları.  </li>" +
    "<li>	Kişisel verileriniz üçüncü taraflarla paylaşılırken Kanun’un 8. ve 9. maddelerinde belirtilen kurallara uygunluk sağlıyoruz.</li>" +
    "</ul>"+
    "<div class='mb-2'><b> Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örneklerileri nelerdir?</b></div>" +
    "Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
    "açıklanmıştır: verilerinizi işlerken Kişisel Verilerin Korunması Kanunu’nun 5. maddesinde belirtilen “sözleşmenin kurulması "+
    " veya ifası”, “bir hakkın tesisi, kullanılması veya korunması”, “kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması” "+
    "ile “hukuki yükümlülüklerimizin yerine getirilmesi” hukuki sebeplerine dayanmaktayız. Bu hukuki sebepler, aşağıda örnek veri işleme "+
    "faaliyetleri ile açıklanmıştır: <br/>" +
    " <br/>" +
    " <br/>" +
    "<b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br/>" +
    "Sözleşmenin kurulması veya ifası" +
    " <br/><br/>" +
    "<b>- Örneğin, ders seçme/dersten çekilme işlemlerinin yerine getirilmesi.</b>  <br/>" +
    "Bir hakkın tesisi,kullanılması veya korunması" +
    " <br/><br/>" +
    "<b>- Örneğin, üniversite, fakülte ya da öğrenci etkinlikleri hakkında duyuruların yapılması.</b>  <br/>" +
    "Meşru menfaatlerimiz için zorunlu olması" +
    " <br/><br/>" +
    "<b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br/>" +
    "Hukuki yükümlülüklerimiz"+
    "<br/><br/>" +
    "<div class='mb-2'><b> Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
    "Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
    "haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
    "Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
    "aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
    "<ul>" +
    "<li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
    "<li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
    "<li>E-posta yoluyla (1) → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
    "</ul>"+
    "Sistemlerimizde daha önce kayıtlı bir e-posta adresinizin olması ve bu adresi kullanmanız suretiyle.<br><br>"+
    " <br/>" +
    "<div class='mb-2'><b> Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
    "BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br/>" +
    "<ul>"+
    "<li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
    "<li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>"+
    "</ul>",
        kvkk_lisans_content:
        "<h4>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Lisans Öğrencileri</h4>"+
        "Bu metin, Bahçeşehir Üniversitesi’nin (“BAU”) öğrenci kişisel verilerini nasıl işlediği hakkında sizleri bilgilendirmeyi amaçlamaktadır. Kişisel verilerinizin   işlenmesine   ilişkin   konular,   aşağıda   başlıklar   halinde sıralanmıştır."+
        "<div class='mb-2'><b> Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
        "Bahçeşehir Üniversitesi olarak, öğrencilerimizin kişisel verilerinin nasıl"+
        " işleneceğini belirliyor ve veri kayıt sisteminin yönetimini sağlıyoruz. Bir diğer ifadeyle,"+
        " kişisel verilerinizi işlerken veri sorumlusu olarak hareket ediyoruz. <br/><br/>"  +
        "<div class='mb-2'><b>  Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor? </b></div>" +
        "Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:  <br/><br/>" +
        "− <b>Kişisel verilerinizi toplama kanalları</b>  ➢  Fiziki ortamlarda form doldurmanız veya bilgilerinizi" +
        "bizimle paylaşmanız; öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital" +
        "ortamlarda yer alan iletişim formlarını doldurmanız; telefon, e-posta, sosyal medya hesapları" +
        "veya BAU whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar" +
        "aracılığıyla kişisel verileriniz toplanmaktadır.  <br/><br/>" +
        "− <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢  Kişisel verileriniz, kısmen otomatik  veya veri kayıt" +
        "sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.  <br/><br/>" +
        "− <b>Kişisel  verilerinizin  toplanmasının  hukuki  sebepleri</b>  ➢ Kişisel  verileriniz,  Kanun’un  5." +
        "maddesinde belirtilen hukuki sebeplerden hukuki  yükümlülüklerimizin  yerine  getirilmesi," +
        "sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin" +
        "zorunlu  olması,  ilgili  kişinin  temel  hak  ve  özgürlüklerine  zarar  vermemek  kaydıyla  veri" +
        "işlenmesinin meşru menfaatlerimiz için zorunlu olması" +
        "hukuki sebeplerine dayalı olarak işlenmektedir. <br/>" +
        " <br/>" +
        "<div class='mb-2'><b> Hangi kişisel verilerim işleniyor?</b></div>" +
        "BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br/>" +
        "<div class='mt-2 mb-4 table-dropdown no-scrollbar border rounded  table-responsive'>"+
        "<table class='table b-table table-striped table-bordered'>"+
        "<thead>"+
        "<tr>"+
        "<th>Kimlik</th>"+
        "<th>İletişim</th>"+
        "<th>Eğitim</th>"+
        "<th>Diğer Bilgileriniz</th>"+
        "</tr>"+
        "</thead>"+
        "<tbody>"+
        "<tr>"+
        "<td>Ad-soyad, anne-baba adı, kimlik numarası, uyruk, doğum yeri ve tarihi, cinsiyet, öğrenci numarası</td>"+
        "<td>Telefon numarası, adres, eposta adresi</td>"+
        "<td>Fakülte, bölüm, sınıf, staj bilgileri, danışman bilgisi, proje, ödev, sunum, tez vb. eğitim materyalleri, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, transkript, alınan dersler, hazırlık okuma bilgisi, ÖSYM yerleşme ve puan bilgileri, yatay geçiş bilgisi, yan dal/çift ana dal bilgisi, kredi bilgileri, mezun olunan okul bilgileri.</td>"+
        "<td>Fotoğraf, meslek bilgisi, banka bilgileri, öğrenci işlem kayıtları, IBAN, gelir bilgisi, disiplin işlemleri bilgileri, işlem güvenliği bilgileri, imza, mazeret nedeni, ayrılma/kayıt dondurma nedeni, kayıt, mezuniyet ve ayrılma tarihleri, yakınlarınızın kimlik ve iletişim bilgileri, anket çalışmaları neticesinde elde edilen bilgiler.</td>"+
        "</tr>"+
        "</tbody>"+
        "</table>"+
        "</div>"+
        "Pilotaj bölümüne kayıt yatptırmak isteyen öğrencilerimizin,"+
        "<del>mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ve engelli öğrencimizin öğrencilerimizin</del>"+
        " ayrıca sağlık verilerini de işliyoruz. Plotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin adli sicil kaydına ilişkin bilgilerini de işliyoruz.<br/><br/>" +
        "<div class='mb-2'><b> Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
        "Kişisel Verilerin Korunması Kanunu’nun (\"Kanun”) 5. maddesi, kişisel verilerin işlenmesine ilişkin hukuki" +
        "sebepleri düzenlemektedir.  Kişisel verilerinizi  bu madde kapsamında, aşağıda belirtilen amaçlar  ve" +
        "hukuki sebeplere uygun olarak "+
        "<del>la yurtiçinde veya yurtdışında</del> işliyoruz:</br><br/>" +
        "<div class='row'>"+
        "<div class='col-4'>Sözleşmenin kurulması veya ifası</div>"+
        "<div class='col-8'>"+
        "Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, <hr>"+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</div>"+
        "<div class='col-8'>"+
        "Öğrenci kayıt sözleşmesinin imzalanması; lisans programları kayıt, nakil ve kayıt dondurma/iptal "+
        "süreçlerinin yürütülmesi. Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması,"+
        " Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi,"+
        " BAU öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi,"+
        " Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
        " Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması,"+
        " Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması,"+
        "Öğrenci zorunlu-gönüllü staj işlemlerinin yürütülmesi"+
        " Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması,"+
        " Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi,"+
        "<hr>"+
        "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de"+
        " dahil olmak üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi,"+
        " Öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin"+
        " alınması ve sonuçlandırılması, Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi,"+
        " Faaliyetlerimizin iyileştirilmesine ilişkin çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması,"+
        " Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına ilişkin süreçlerin yürütülmesi; bu kapsamda,"+
        " öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe süreçlerinin yürütülmesi."+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</div>"+
        "<div class='col-8'>"+
        "Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, "+
        "yüksek lisans, burs ve benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda"+
        " haklarımızın korunabilmesi, kullanılabilmesi ve yasal süreçlerin yürütülmesi.<hr>"+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>Veri işlemenin Kanunlarda açıkça öngörülmesi ve"+
        " hukuki yükümlülüklerimizin yerine getirilmesi</div>"+
        "<div class='col-8'>"+
        "Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili" +
        "kurum veya kuruluşların Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü "+
        "durumlarda, yasal yükümlülüklerimizin yerine getirilmesi"+
        "Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin ceza mahkumiyeti verileri, mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi, öğrencinin pilotaj bölümüne kayıt için uygunluğunun tespit edilmesi ve yetkili kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir."+
        "</div>"+
        "<div class='col-4 d-flex align-items-center'>İlgili kişinin açık rızasının bulunması</div>"+
        "<div class='col-8'>"+
        "Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin sağlık verileri, sağlık durumunun kontrolü, sağlık durumunun bölüm yeterliliğini karşılayıp karşılamadığının tespiti, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir."+
        "</div>"+
        "<div class='col-4'>Üniversite kayıt İşlemleri</div>"+
        "<div class='col-8'>"+
        "Üniversite kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, oryantasyon aktivitelerinin gerçekleştirilmesi<hr>"+
        "</div>"+
        "<div class='col-4'>Eğitim ve Öğretim Faaliyetleri</div>"+
        "<div class='col-8'>"+
        "Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi,"+
        " öğrencilerimizin yetenek ve kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve"+
        " sosyal gelişimlerinin takibi, ölçme ve değerlendirme süreçlerinin yürütülmesi, zorunlu-gönüllü staj işlemlerinin yürütülmesi <hr>"+
        "</div>"+
        "<div class='col-4'>Organizasyon ve Etkinlikler</div>"+
        "<div class='col-8'>"+
        "Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi.<hr>"+
        "</div>"+
        "<div class='col-4'>Diğer Faaliyetlerimizdeki Amaçlar</div>"+
        "<div class='col-8'>"+
        "İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama"+
        " kullanıcı hesaplarının oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması, anket çalışmalarının"+
        " yürütülmesi, faaliyetlerimizin denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi,"+
        " faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe"+
        " süreçlerinin yürütülmesi. <hr>"+
        "</div>"+
        "</div>"+
        "<div class='mb-2'><b> Kişisel verilerimin aktarıldığı taraflar ve aktarılma amaçları nelerdir  başkalarına aktarılıyor mu? </b></div>" +
        "Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir:  <br/>" +
        "<ul>"+
        "<li>Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi," +
        " hukuki yükümlülüklerimizin yerine getirilmesi → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
        "<li>Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın" +
        " devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.  </li>" +
        "<li>	Denetim ve raporlamaların yapılabilmesi, organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, iş ortaklarımız ile olan ilişkilerin yönetimi,"+
        " Bahçeşehir Grup Şirketleri politika ve kurallarına uyumluluğun sağlanması → İş Ortakları.  </li>" +
        "<li>	Kişisel verileriniz üçüncü taraflarla paylaşılırken Kanun’un 8. ve 9. maddelerinde belirtilen kurallara uygunluk sağlanmaktadır.</li>" +
        "Kişisel verileriniz, veli bilgilendirme beyan formu ile yetkilendirmeniz durumunda, formda belirttiğiniz kişi/kişiler ile de paylaşılacaktır."+
        "</ul>"+
        "<div class='mb-2'><b> Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örnekleriler</b></div>" +
        "Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
        "açıklanmıştır: <br/>" +
        " <br/>" +
        " <br/>" +
        "<b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br/>" +
        "Sözleşmenin kurulması veya ifası" +
        " <br/><br/>" +
        "<b>- Örneğin, ders seçme/dersten çekilme işlemlerinin yerine getirilmesi.</b>  <br/>" +
        "Bir hakkın tesisi,kullanılması veya korunması" +
        " <br/><br/>" +
        "<b>- Örneğin, üniversite, fakülte ya da öğrenci etkinlikleri hakkında duyuruların yapılması.</b>  <br/>" +
        "Meşru menfaatlerimiz için zorunlu olması" +
        " <br/><br/>" +
        "<b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br/>" +
        "Hukuki yükümlülüklerimiz"+
        "<br/><br/>" +
        "<div class='mb-2'><b> Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
        "Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
        "haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
        "Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
        "aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
        "<ul>" +
        "<li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
        "<li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
        "<li>E-posta yoluyla (1) → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
        "</ul>"+
        "Sistemlerimizde daha önce kayıtlı bir e-posta adresinizin olması ve bu adresi kullanmanız suretiyle.<br><br>"+
        " <br/>" +
        "<div class='mb-2'><b> Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
        "BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br/>" +
        "<ul>"+
        "<li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
        "<li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>"+
        "</ul>",
    graduate_app_info: "<h5>Yüksek Lisans Programına Başvuru Yapacak Adayların Dikkatine</h5>" +
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde bulunan lisansüstü programlara başvurular online başvuru"+
        " üzerinden yapılmaktadır. Adaylar, başvuru sürecini enstitü ile yürütmelidir.</p>"+
        "<p>Başvuru sürecinin ilk aşaması olan online başvuruda öğrencinin en fazla 3 adet programa başvurma hakkı bulunmaktadır."+
        " 3 tercihi de doldurmak zorunda değildir. İlk tercih ettiğiniz programa ek olarak 2 ayrı programa "+
        "daha başvuru yapmak öğrenci inisiyatifindedir.</p>"+
        "Tezsiz yüksek lisans programlarına başvuracak adaylar için ALES puan şartı aranmamaktadır."+
        " Ancak üniversitemizin uyguladığı başarı bursları arasında yer alan başvurduğu bölümün ilgili"+
        " puan türünden ALES 85 ve üzeri puana sahip olan öğrenciler, %20 oranında ALES başarı bursundan faydalanabilirler."+
        "<p>Tezli yüksek lisans programlarına başvuracak adaylar için ALES puan şartı aranmaktadır."+
        " ALES puan kriteri için enstitü ile iletişime geçilmelidir.</p>"+
        "<p>Eğitim dili İngilizce olan programlar için aday öğrencinin TOEFL, YDS, YÖKDİL ve BAU İngilizce Yeterlik "+
        "sınavlarından yeterli puanda ve güncel tarihli sınav sonuçlarının olması şartı aranmaktadır. "+
        "(Puan kriterleri için enstitü ile iletişime geçilmelidir. TOEFL, BAU İngilizce Yeterlik Sınav"+
        " geçerlilik süresi 2 yıl, YDS, YÖKDİL sınavlarının geçerlilik süresi 5 yıldır.)</p>"+
        "<p>Eğitim dili Türkçe olan programlara başvuru için İngilizce yeterlilik belgesi şartı aranmamaktadır."+
        " Ancak yeterli puanda dil yeterlilik sınav sonucu olan öğrenciler,"+
        " üniversitemizin uyguladığı başarı bursları arasında yer alan %10 oranında dil başarı bursundan faydalanabilirler</p>"+
        "<h5>Doktora Programına Başvuru Yapacak Adayların Dikkatine!</h5>" +
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde bulunan lisansüstü programlara başvurular ortak online başvuru üzerinden yapılmaktadır."+
        " Adaylar, başvuru sürecini tercih ettiği programın bağlı olduğu enstitü ile yürütmelidir.</p>"+
        "<p>Lisansüstü Eğitim Enstitüsü bünyesinde yer alan doktora programlarına başvuru yapacak olan "+
        "adayların sadece tek program tercih hakkı bulunmaktadır</p>"+
        "<p>Doktora programlarına başvuru aşamasında, adayların tüm şartları sağlamış olması zorunludur."+
        " Doktora başvuru sürecinde özel öğrencilik durumu uygulanmamaktadır.</p>"+
        "<p>Tüm şartları sağlayan adaylar, yazılı bilim sınavına girmeye hak kazanırlar. "+
        "Yazılı sınavdan başarılı olan adaylar, sözlü sınava tabi tutulurlar. "+
        "Programa kabul edilmeleri halinde, kesin kayıt yapma hakkı elde ederler.</p>"+
        "<p><p>Önemli not:</p> 6 Şubat 2013 tarihinde YÖK Lisansüstü Eğitim ve Öğretim Yönetmeliği’nde yapılan "+
        "değişikliklere istinaden doktora programlarına;</p>"+
        "<ul><li><u>Tezli yüksek lisans derecesi ile başvuran adaylarda,</u> ALES notunun ilgili puan türünden "+
        "en az 60 olması, YDS, YÖKDİL puanının en az 65 olması (ya da muadili puan türü) şartı aranmaktadır.</li></ul>"+
        "<p>06.02.2013 tarihinden önce tezsiz yüksek lisans programına kayıt yaptırmış ya da programdan"+
        " mezun olan adaylar tezli yüksek lisans derecesi ile başvuran adaylar ile aynı şartlara tabi tutulacaktır.</p>"+
        "<p>(Bkz: <a href='http://www.resmigazete.gov.tr/eskiler/2013/02/20130206-10.htm'>http://www.resmigazete.gov.tr/eskiler/2013/02/20130206-10.htm</a>)</p>"+
        "<p><b>NOT:</b> Doktora programlarına lisans derecesi ile başvurabilme kriteri enstitüler için değişiklik gösterebilir."+
        " Konu ile alakalı olarak ilgili enstitü ile iletişime geçilmelidir.</p>"+
        "<p><b>NOT:</b> Enstitünün kararlarına göre doktora programlarının ALES ve "+
        "dil yeterlilik başvuru puan kriteri farklılık gösterebilmektedir.</p>",

    graduate_kvkk_content: "<div class='mb-2'>" +
        "            <h2>Kişisel Verilerin İşlenmesine Dair Aydınlatma Metni – Ön Lisans, Lisans Öğrencileri</h2>" +
        "        </div>" +
        "        <br>" +
        "        <div class='text-left'>" +
        "            <div class='mb-2'><b>Kişisel verilerimin işlenmesinden kim sorumlu?</b></div>" +
        "        Bahçeşehir Üniversitesi olarak,  öğrencilerimizin  kişisel verilerinin  nasıl işleneceğini belirliyor  ve  veri " +
        "        kayıt sisteminin yönetimini sağlıyoruz.  Bir diğer ifadeyle, kişisel verilerinizi işlerken veri sorumlusu " +
        "        olarak hareket ediyoruz." +
        "        <br><br>" +
        "        <div class='mb-2'><b>Kişisel verilerim nasıl ve hangi hukuki sebeplerle toplanıyor?</b></div>" +
        "        Kişisel verilerinizi aşağıdaki kanallar ve yöntemler aracılığıyla topluyoruz:<br><br>" +
        "        − <b>Kişisel verilerinizi toplama kanalları </b>  ➢ Fiziki ortamlarda form doldurmanız veya bilgilerinizi " +
        "        bizimle paylaşmanız; öğrenci bilgi sistemlerine bilgi girişi yapmanız; internet sitesi ya da dijital " +
        "        ortamlarda yer alan iletişim formlarını doldurmanız; telefon, e-posta, sosyal medya hesapları " +
        "        veya  BAU  whatsapp hattı üzerinden bizimle iletişime geçmeniz halinde belirtilen kanallar " +
        "        aracılığıyla kişisel verileriniz toplanmaktadır. <br><br>" +
        "        − <b>Kişisel verilerinizi toplama yöntemleri</b>  ➢ Kişisel verileriniz, kısmen otomatik,  otomatik  veya " +
        "        veri kayıt sisteminin parçası olmak kaydı ile otomatik olmayan yöntemler ile toplanmaktadır.<br><br>" +
        "        − <b>Kişisel verilerinizin toplanmasının hukuki sebepleri</b> ➢ Kişisel verileriniz, Kanun’un 5. " +
        "        maddesinde belirtilen hukuki sebeplerden hukuki yükümlülüklerimizin yerine getirilmesi, " +
        "        sözleşmenin kurulması ve ifası, bir hakkın tesisi, kullanılması veya korunması için veri işlemenin " +
        "        zorunlu olması, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri " +
        "        işlenmesinin meşru menfaatlerimiz için zorunlu olması hukuki sebeplerine dayalı olarak " +
        "        işlenmektedir.<br>" +
        "         <br>" +
        "        <div class='mb-2'><b>Hangi kişisel verilerim işleniyor?</b></div>" +
        "        BAU olarak adayların aşağıda belirtilen kişisel verilerini yurtiçinde veya yurtdışında işliyoruz:  <br>" +
        "        <div class='mt-2'>" +
        "        <table class='table b-table table-striped table-bordered'>" +
        "        <tr>" +
        "            <th>Kimlik</th>" +
        "            <th>İletişim</th>" +
        "            <th>Eğitim</th>" +
        "            <th>Diğer Bilgileriniz</th>" +
        "        </tr>" +
        "        <tr>" +
        "            <td>Ad-soyad, kimlik numarası, cinsiyet, uyruk, doğum yeri ve tarihi, kimlik fotokopisi, öğrenci numarası, uluslararası öğrencilerimizin pasaport bilgileri</td>" +
        "            <td>Telefon numarası, eposta adresi, iş ve ev adresi</td>" +
        "            <td>Fakülte, bölüm, sınıf, staj bilgileri, danışman bilgisi, proje, ödev, sunum, tez vb. eğitim materyalleri, bursluluk durumu, sınav sonuçları, not ortalaması, başarı durumu, devamsızlık durumu, transkript, alınan dersler, hazırlık okuma bilgisi, ÖSYM yerleşme ve puan bilgileri, yatay geçiş bilgisi, yan dal/çift ana dal bilgisi, kredi bilgileri, mezun olunan okul bilgileri</td>" +
        "            <td>Fotoğraf, meslek bilgisi, banka bilgileri, öğrenci işlem kayıtları, IBAN, gelir bilgisi, disiplin işlemleri bilgileri, işlem güvenliği bilgileri, imza, mazeret nedeni, ayrılma/kayıt dondurma nedeni, kayıt, mezuniyet ve ayrılma tarihleri, yakınlarınızın kimlik ve iletişim bilgileri, anket çalışmaları neticesinde elde edilen bilgiler.</td>" +
        "        </tr>" +
        "        </table>" +
        "        </div>" +
        "        Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin, mazeret nedeniyle sağlık raporu sunan öğrencilerimizin ve engelli öğrencilerimizin öğrencilerimizin <b>ayrıca sağlık verilerini</b> de işliyoruz. Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerimizin <b>adli sicil kaydına ilişkin bilgilerini</b> de işliyoruz.  <br><br>" +
        "        <div class='mb-2'><b>Kişisel verilerim hangi amaçlarla ve hangi hukuki sebeplere dayalı olarak işleniyor?</b></div>" +
        "        Kişisel Verilerin Korunması Kanunu’nun ('Kanun')"+
"5.maddesi, kişisel verilerin işlenmesine ilişkin hukuki"+
"        sebepleri düzenlemektedir.  Kişisel verilerinizi  bu madde kapsamında, aşağıda belirtilen amaçlar  ve" +
"        hukuki sebeplere uygun olarak la yurtiçinde veya yurtdışında işliyoruz: <br><br>" +
"        <hr>" +
"        <div><b>Sözleşmenin kurulması veya ifası</b></div>" +
"        <div>" +
"            Öğrenci tarafından sunulan bilgi ve belgelerin doğruluğunun teyit edilmesi, Öğrenci kayıt sözleşmesinin imzalanması; ön lisans, lisans programları kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, Öğrenci bilgilerinin BAU tarafından kullanılan kurumsal kayıt sistemlerine aktarılması, Öğrencilere ilişkin duyuru ve bilgilendirmelerin yapılması, Öğrenci okul kartlarının basılması ve teslim edilmesi, BAU Öğrenci portal kaydı oluşturulması; öğrencilere kurumsal eposta hesap ve şifresinin tanımlanması ve iletilmesi, Öğrenci danışman atama süreçlerinin yürütülmesi, Ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, Öğrenci devamsızlık durumu takibinin yapılması ve sistemlere girilmesi, Sistemlere ders notu girişlerinin yapılması, Ölçme ve değerlendirme süreçlerinin yürütülmesi, Öğrencilerin mazeret başvurularının değerlendirilmesi ve cevaplanması, Öğrenci zorunlu-gönüllü staj işlemlerinin yürütülmesi, Tez ve proje danışmanlık faaliyetlerinin yürütülmesi, Öğrencilerin mezuniyet ve ilişik kesme işlemlerinin tamamlanması, Öğrenci belgesi, transkript, geçici mezuniyet belgesi, diploma, disiplin, ilişik kesme belgesi düzenlenmesi ve teslim edilmesi, <br><br><hr><br> <br> " +
"        </div>" +
"        <div class='col-12 d-flex align-items-center'><b>Kişisel veri işlemenin meşru menfaatlerimiz için zorunlu olması</b></div>" +
"        <div>" +
"            Organizasyon, fuar, yarışma ve etkinliklerin düzenlenmesi, Öğrenciler ile sınav, ders dönemi başlangıcı ve etkinlikler de dahil olmak " +
"            üzere iletişim süreçlerinin yürütülmesi, Bilimsel araştırma, yayın ve danışmanlık faaliyetlerinin sürdürülmesi, Öğrencilerimizin yetenek" +
"             ve kariyer gelişimlerine yönelik faaliyetlerin organize edilmesi, Öneri, talep ve şikayetlerin alınması ve sonuçlandırılması, " +
"             Faaliyetlerimizin incelenmesi, denetlenmesi ve raporlanması süreçlerinin yürütülmesi, Faaliyetlerimizin iyileştirilmesine ilişkin " +
"             çalışmaların yürütülmesi; bu kapsamda anket çalışmalarının yapılması, Kampüslerimizin ve operasyonlarımızın güvenliğinin sağlanmasına " +
"             ilişkin süreçlerin yürütülmesi; bu kapsamda, öğrencinin kampüs alanına giriş ve çıkışlarının kontrol edilmesi, Finans ve muhasebe " +
"             süreçlerinin yürütülmesi,<br><br><hr><br>" +
"        </div>" +
"        <div class='col-4 d-flex align-items-center'><b>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması</b></div>" +
"        <div>" +
"            Öğrencilerin kayıt yenileme, yaz okulu, çift anadal programı, erasmus programı, yatay/dikey geçiş, lisansüstü, yüksek lisans, burs ve " +
"            benzeri öğrencilikle ilgili işlemlerin yürütülmesi, Olası bir uyuşmazlık durumunda haklarımızın korunabilmesi, kullanılabilmesi ve yasal " +
"            süreçlerin yürütülmesi.<br><br><hr><br>" +
"        </div>" +
"        <div class='col-4 d-flex align-items-center'><b>Veri işlemenin Kanunlarda açıkça öngörülmesi ve hukuki yükümlülüklerimizin yerine getirilmesi</b></div>" +
"        <div>" +
"            Disiplin işlemleri ve hukuk işlerinin takibi ve yürütülmesi, Yükseköğretim Kurulu başta olmak üzere yetkili kurum veya kuruluşların " +
"            Üniversitemizden talepte bulunması ya da bu kurumlara bildirim yapmamızın öngörüldüğü durumlarda, yasal yükümlülüklerimizin yerine " +
"            getirilmesi. mevzuattan kaynaklı yükümlülüklerimizin yerine getirilmesi, öğrencinin pilotaj bölümüne kayıt için uygunluğunun tespit " +
"            edilmesi ve yetkili kurum ve kuruluşlara bilgi verilmesi amaçlarıyla işlenecektir<br><br>" +
"        </div>" +
"        <div class=\"page-number\">2/3</div>" +
"        <div><b>İlgili kişinin açık rızasının bulunması</b></div>" +
"        <div>" +
"            Pilotaj bölümüne kayıt yaptırmak isteyen öğrencilerin sağlık verileri, sağlık durumunun kontrolü, sağlık durumunun bölüm yeterliliğini " +
"            karşılayıp karşılamadığının tespiti, faaliyetlerin mevzuata uygun yürütülmesi ve yetkili kişi, kurum ve kuruluşlara bilgi verilmesi " +
"            amaçlarıyla işlenecektir.<br><br><hr><br>" +
"        </div>" +
"        <div><b>Üniversite Kayıt İşlemleri</b></div>" +
"        <div>Üniversite kayıt, nakil ve kayıt dondurma/iptal süreçlerinin yürütülmesi, oryantasyon aktivitelerinin gerçekleştirilmesi <br><br><hr><br>" +
"        </div>" +
"        <div><b>Eğitim ve Öğretim Faaliyetleri</b></div>" +
"        <div>" +
"            Eğitim ve öğretim faaliyetlerinin yürütülmesi, ders seçme/dersten çekilme işlemlerinin gerçekleştirilmesi, öğrencilerimizin yetenek ve " +
"            kariyer gelişimlerine yönelik aktivitelerin yürütülmesi, öğrencilerin akademik ve sosyal gelişimlerinin takibi, ölçme ve değerlendirme " +
"            süreçlerinin yürütülmesi, zorunlu-gönüllü staj işlemlerinin yürütülmesi.<br><br><hr><br>" +
"        </div>" +
"        <div><b>Organizasyon ve Etkinlikler</b></div>" +
"        <div>" +
"            Organizasyon, fuar, yarışma ve etkinliklerin 4 düzenlenmesi, öğrenci etkinliklerinin yürütülmesi, kurumsal iletişim süreçlerinin yürütülmesi<br><br><hr><br>" +
"        </div>" +
"        <div><b>Diğer Faaliyetlerimizdeki Amaçlar</b></div>" +
"        <div>" +
"            İletişim süreçlerinin yürütülmesi; öğrenciler için duyuru ve bilgilendirmelerin yapılması, dijital uygulama kullanıcı hesaplarının " +
"            oluşturulması ve yönetimi, talep ve şikayetlerin alınması ve sonuçlandırılması, anket çalışmalarının yürütülmesi, faaliyetlerimizin " +
"            denetlenmesi ve raporlanması, disiplin ve hukuk işlerinin takibi ve yürütülmesi, faaliyetlerimizin mevzuata uygun olarak yürütülmesi, " +
"            yetkili kişi, kurum ya da kuruluşlara bilgi verilmesi, finans ve muhasebe süreçlerinin yürütülmesi.<br><br><hr><br>" +
"        </div> " +
"        <div class='mb-2'><b>Kişisel verilerim in aktarıldığı taraflar ve aktarılma amaçları nelerdirbaşkalarına aktarılıyor mu?</b></div>" +
"        Kişisel verilerinizi yurtiçi veya yurtdışındaki üçüncü kişilere aktarıyoruz. Kişisel verilerinizi kimlere, hangi amaçlarla aktardığımız aşağıda belirtilmektedir: <br>" +
"        <ul>" +
"        <li>Faaliyetlerimizin mevzuata uygun olarak yürütülmesi, yetkili kişi, kurum veya kuruluşlara bilgi verilmesi, hukuki yükümlülüklerimizin " +
"            yerine getirilmesi → Yüksek Öğretim Kurumu ve Diğer Yetkili Kişi, Kurum veya Kuruluşlar.</li>" +
"        <li>Üçüncü kişilerden mal veya hizmet tedariki kapsamında destek alınması, operasyonlarımızın" +
"         devamlılığının sağlanması, finans ve muhasebe işlerinin yürütülmesi → Tedarikçiler.  </li>" +
"        </ul>" +
"        Kişisel verileriniz, veli bilgilendirme beyan formu ile yetkilendirmeniz durumunda, formda belirttiğiniz" +
"    kişi/kişiler ile de paylaşılacaktır<br>" +
"        <div class='mb-2'><b>Kişisel verilerimi işlerken dayanılan hukuki sebep ve veri işleme faaliyeti örnekleri</b></div>" +
"        Kişisel verilerinizi işlerken dayandığımız hukuki sebepler, aşağıda örnek veri işleme faaliyetleri ile" +
"        açıklanmıştır:" +
"        <br>" +
"         <br>" +
"         <br>" +
"        <b>- Örneğin, öğrenci kayıt sözleşmesinin imzalanması için bilgilerinizin kullanılması.</b> <br>" +
"        Sözleşmenin kurulması veya ifası" +
"         <br><br>" +
"        <b>- Örneğin, ders seçme/dersten çekilmeişlemlerinin yerine getirilmesi.</b>  <br>" +
"        Bir hakkın tesisi,kullanılması veya korunması" +
"         <br><br>" +
"        <b>- Örneğin, üniversite, fakülte ya da öğrencietkinlikleri hakkında duyuruların yapılması.</b>  <br>" +
"        Meşru menfaatlerimiz için zorunlu olması" +
"         <br><br>" +
"        <b>- Örneğin, Yüksek Öğretim Kurumu denetimleri kapsamında talep edildiğinde bilgilerinizin sunulması.</b>  <br>" +
"        Hukuki yükümlülüklerimiz" +
"        <br><br>" +
"        <div class='mb-2'><b>Kişisel veri sahibi olarak haklarımı nasıl kullanabilirim?</b></div>" +
"        Kişisel Verilerin Korunması Kanunu’nun 11. maddesinde kişisel veri sahibi ilgili kişilere tanınan bazı" +
"        haklar  bulunmaktadır. Bu haklarınızı kullanmak için  Veri Sorumlusuna Başvuru Usul ve Esasları" +
"        Hakkında  Tebliğ’de  (kısaca  “Tebliğ”)  bazı  usuller  belirlenmiştir.  Haklarınızı  kullanmak  isterseniz," +
"        aşağıdaki yöntemlerden biri ile, ya da Tebliğ’de belirtilen diğer yöntemlerle talebinizi bize iletebilirsiniz:" +
"        <ul>" +
"        <li>İnternet sitesi aracılığıyla → <a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki İlgili Kişi Başvuru Formu’nu doldurarak</li>" +
"        <li>Posta  yoluyla  →  Çırağan Cad. Osmanpaşa Mektebi Sok. No: 4 –  6,  34353,  Beşiktaş/İstanbul posta göndererek.</li>" +
"        <li>E-posta yoluyla1 → BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta göndererek.</li>" +
"        </ul>" +
"         <br>" +
"        <div class='mb-2'><b>Detaylı bilgiye nasıl ulaşabilirim?</b></div>" +
"        BAU’nun kişisel verileri nasıl işlediği hakkında detaylı bilgi almak için; <br>" +
"        <ul>" +
"        <li><a href='https://bau.edu.tr/'>bau.edu.tr</a> adresindeki “Kişisel Verilerin İşlenmesi ve Korunması Politikası”nı inceleyebilir,</li>" +
"        <li>BAU İrtibat Kişisi’nin <a href='mailto:kvkk@bau.edu.tr'>kvkk@bau.edu.tr</a> adresine e-posta gönderebilirsiniz.</li>" +
"        </ul>",
    course_transfer_form_info: " İntibak belgenizi/belgelerinizi yükledikten sonra \"Talebi Gönder\" butonuna tıklayarak talebinizi oluşturursunuz. İntibak işlemleriniz tamamlandığında tarafınıza bilgi verilecektir. “Taleplerim” ekranında “İntibak” sekmesine giderek intibak işleminizi onaylamanız gerekmektedir. Transkripti Bahçeşehir Üniversitesine ait olan öğrencilerin transkriptleri sistem tarafından otomatik yüklenir. Detaylı bilgi için <a href=\"#\" class='text-primary' download>Kullanım Kılavuzuna</a> göz atınız.",
    conservatory_app_approve_text: "Burada yapılan ön kayıt işlemi öncesinde asıl kayıt için gerekli şartları sağladığınızdan emin olunuz. Burada sınav için yaptığınız ön kayıt, sınava girdikten sonra başarılı olsanız dahi, asıl kayıt için gerekli şartları yerine getiremediğinizde geçersiz olacaktır.",
    conservatory_app_aprove_multimedia_text: "Konservatuar başvuruları kapsamında Bahçeşehir Üniversitesi’ne ileteceğiniz ses, görüntü, fotoğraf ve video kayıtlarınıza ilişkin aşağıdaki muvafakatnameyi onaylayabilirsiniz.\n" +
        "Bahçeşehir Üniversitesi’nin paylaşacağım ses, görüntü, fotoğraf ve/veya video kayıtlarını konservatuar başvuruları kapsamında yeterlilik ve yetenek değerlendirmesi için kullanmasını kabul ediyorum.",
    fashion_design_app_approve_text: "Burada yapılan ön kayıt işlemi öncesinde asıl kayıt için gerekli şartları sağladığınızdan emin olunuz. Burada sınav için yaptığınız ön kayıt, sınava girdikten sonra başarılı olsanız dahi, asıl kayıt için gerekli şartları yerine getiremediğinizde geçersiz olacaktır.",
    fashion_design_app_aprove_multimedia_text: "Tekstil ve Moda Tasarımı başvuruları kapsamında Bahçeşehir Üniversitesi’ne ileteceğiniz ses, görüntü, fotoğraf ve video kayıtlarınıza ilişkin aşağıdaki muvafakatnameyi onaylayabilirsiniz. Bahçeşehir Üniversitesi’nin paylaşacağım ses, görüntü, fotoğraf ve/veya video kayıtlarını Tekstil ve Moda Tasarımı başvuruları kapsamında yeterlilik ve yetenek değerlendirmesi için kullanmasını kabul ediyorum.",
    privacy_notice_approve: `Kişisel verilerin korunumu ve <strong>aydınlatma metnini okudum</strong>, anladım kabul ediyorum.`,
    privacy_notice_message: `Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul adresinde bulunan BAHÇEŞEHİR ÜNİVERSİTESİ (Bundan böyle “FUTURE CAMPUS” olarak anılacaktır) unvanlı topluluğumuza aktarılan veya tarafımız nezdinde elde edilen kişisel verilerin korunması konusundaki temel bilgilere aşağıda yer verilmektedir. <br>
        Aşağıdaki detaylı açıklamalar, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10.maddesinden doğan aydınlatma yükümlülüğünün yerine getirilmesi amacıyla, başta internet sitemizi ve/veya ileride uygulamaya konulabilecek mobil uygulamalarımızı kullanan kişiler ile etkinliklerimize katılanlar olmak üzere iş ortakları, çalışanlar, danışmanlar, müşteriler ve iş yerlerimizi ziyaret eden ya da internet ağımıza bağlanan kişilerin dikkatine sunulmaktadır.<br>
        FUTURE CAMPUS, yürürlükteki mevzuat hükümlerine uygun olarak düzenlenmiş olan ve usulüne uygun olarak yayınlanmakta olan işbu Aydınlatma Metnini yürürlükteki mevzuatta yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutmaktadır. Böyle bir durumda Aydınlatma Metnine ilişkin değişiklik ve güncellemeler internet sitelerimiz ile mobil uygulamalarımızda ayrıca belirtiliyor olacaktır.  <br>
        FUTURE CAMPUS ile paylaşılan kişisel veriler, FUTURE CAMPUS’ün gözetimi ve kontrolü altındadır. FUTURE CAMPUS, yürürlükteki ilgili mevzuat hükümleri gereğince bilgi gizliliğinin ve bütünlüğünün korunması amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize sunarız.<br>
        <strong>1. KİŞİSEL VERİLERİN TOPLANMASININ YASAL DAYANAĞI</strong><br>
        FUTURE CAMPUS olarak kişisel verilerin işlenmesi konusunda yürürlükteki mevzuat hükümlerine uygun olarak faaliyet yürütmekteyiz. Başta kişisel verilerin korunması hakkında genel esasları belirleyen KVKK olmak üzere FUTURE CAMPUS’ün platformlarında yer alan işlem başlıklarının tabi olduğu yürürlükteki mevzuat hükümleri ile kişisel verilerin korunması için bazı hallerde cezai yaptırımlar öngören 5237 Sayılı Türk Ceza Kanunu’na uygun hareket edilmektedir.<br>
        <strong>2. KİŞİSEL VERİLERİN TOPLANMA YÖNTEMİ</strong><br>
        <a href="www.futureaisummit.org">www.futureaisummit.org</a> uzantılı internet sitemiz (“İnternet Sitesi”) ve/veya ileride uygulamaya konulabilecek mobil uygulamalarımızı (“Mobil Uygulama”) kullanarak, gerçekleştirdiğimiz Future AI Summit ve bununla sınırlı olmamak kaydıyla gerçekleştirilecek farklı organizasyonlarımız esnasında veya tarafımız ile herhangi bir kanaldan iletişime geçtiğinizde (İnternet Sitesi, Mobil Uygulama ve bunlarla sınırlı olmamak kaydıyla FUTURE CAMPUS’ün kontrolünde olan her türlü çevrimiçi platform ile fiziki lokasyon bundan böyle “Platform” olarak nitelendirilecektir), etkinliklerimize, toplantılarımıza, panel veya seminerlerimize ya da programlarımıza katıldığınızda, bu organizasyonların alanlarını ziyaret ettiğinizde, etkinliklerimize katılım için ya da destek veya sponsorluk anlamında ödemeler yaptığınızda, Platformlarımıza üyelik başvurusu yaptığınızda ya da Platformlarımızı ziyaret ettiğinizde sunmuş olduğunuz kişisel verileriniz; FUTURE CAMPUS’ün ilgili departmanları tarafından sizlerin rızası ve mevzuat hükümleri uyarınca işbu Aydınlatma Metni ve İnternet Sitemizde bulunan Gizlilik Politikası kapsamında işlenmektedir.<br>
        <strong>3. KİŞİSEL VERİLERİN KULLANILDIĞI ALANLAR</strong><br>
        FUTURE CAMPUS tarafından, yürürlükteki mevzuat hükümlerine uygun olarak elde edilen kişisel verilerin üçüncü kişiler ile paylaşımı, yine kişisel veri sahiplerinin mevzuat hükümleri çerçevesindeki izni doğrultusunda ya da yürürlükteki mevzuat hükümlerindeki istisnalar çerçevesinde gerçekleştirilmektedir. Kişisel veriler, hizmetin ifası ya da işlemlerin gereği olarak belirtilen haller dışında, yürürlükteki mevzuat hükümlerine aykırı olacak bir şekilde üçüncü kişilerle paylaşılmamaktadır.<br>
        Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer kamu kurumları ile de kişisel veriler paylaşılmaktadır. Ancak taahhüt ettiğimiz hususları temin edebilmek sunulan faaliyetlere ilişkin daha faydalı olmak adına analiz yapabilmek, istatistik çalışması yürütebilmek ve Platformların kalite kontrolünü yapabilmek için de anlaşmalı üçüncü kişilere kişisel veri aktarımı yapılabilmektedir. Böyle bir durumda, aktarılan kişisel bilgiler anonim hale getirilmektedir.<br>
        Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden FUTURE CAMPUS sorumlu değildir.<br>
        Kişisel verileriniz FUTURE CAMPUS’ün yöneticileriyle, tedarikçilerle, iş ortaklarıyla, destekçiler ve sponsorlarıyla, yürütülen projelere destek veren kişilerle, faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, verilerin bulut ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla, Platform paydaşlarına Platformların faaliyetleri ile ilgili olsun veya olmasın ticari elektronik iletilerin gönderilmesi konusunda anlaşmalı olduğumuz yurtiçi/yurtdışındaki kuruluşlarla, Bankalararası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla ve sizlere daha iyi hizmet sunabilmek ve paydaş memnuniyetini sağlayabilmek için çeşitli tanıtım ve bilinirlik faaliyetleri kapsamında yurtiçi ve yurtdışındaki çeşitli ajans ve anket şirketleriyle ve yurtiçi/yurtdışı diğer üçüncü kişilerle ve ilgili iş ortaklarımızla paylaşılabilmektedir.<br>
        <strong>4. KİŞİSEL VERİLERİ İŞLEME AMAÇLARI</strong><br>
        FUTURE CAMPUS tarafından elde edilen kişisel verileriniz aşağıdaki amaçlar doğrultusunda işlenebilecektir:<br>
        · İnternet sitesi ve/veya Mobil uygulama üzerinden ve bunlarla sınırlı olmamak kaydıyla FUTURE CAMPUS’ün kontrolünde bulunan Platformlara kayıt oluşturan, etkinliklere katılan veya organizasyon alanlarını ziyaret eden kişilerin kimlik bilgilerini kontrol ve teyit etmek;<br>
        · FUTURE CAMPUS ile herhangi bir şekilde iş birliği, proje ortaklığı, bağış ve destek sunulması, destek alınmasını, hizmet veya başka bir sözleşme kapsamında işlem yapan kişilerin iletişim için adres ve diğer gerekli bilgilerini kaydetmek;<br>
        · Akdedilmiş yazılı olsun veya olmasın her türlü sözleşmenin, anlaşmanın, mutabakatın koşulları, güncel durumu ve güncellemeleri ile ilgili Platformları kullanan ya da ziyaret eden kişiler ile iletişime geçmek, ilgili kişilere gerekli bilgilendirmeleri yapabilmek;<br>
        · Elektronik (internet/mobil vs.) veya kağıt ortamında yürütülebilecek işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek,<br>
        · Yürürlükteki mevzuat hükümleri uyarınca FUTURE CAMPUS’ün üstlenmiş olduğu ya da işin doğası gereği yükümlülüğünde bulunduğu hususların yerine getirilmesi;<br>
        · Kamu güvenliğine ilişkin hususlarda ilgili kurum ve kuruluşlardan gelen talepler halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek;<br>
        · İş ortaklarımıza, çalışanlarımıza, danışmanlarımıza, Platformları kullananlara ve ziyaretçilerimize daha iyi bir deneyim sağlamak;<br>
        · Platformları kullanan ve/veya ziyaret eden kişilerin ihtiyaçlarının belirlenmesi, temel odak noktalarının tespiti ile ilgi alanlarının değerlendirilmesi;<br>
        · Platformlarda yer alan hizmet ve destek detayları hakkında Platformu kullanan destekçilere ya da ihtiyaç sahiplerine bilgi verebilmek, kampanyaları aktarmak;<br>
        · Platformu kullanan kişilerin ihtiyaçlarının karşılanabilmesinin maksimum seviyede tutulması, memnuniyet düzeyinin arttırılması;<br>
        · Destekçilerin ve ihtiyaç sahiplerinin tanımlanması, sosyal çevre analizlerinin yapılması, bu anonim analizleri çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmek ve bu kapsamda anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler düzenlemek,<br>
        · Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından Platform kullanıcılarına hizmetlerimizle ilgili olarak öneriler sunabilmek, ilgili kişileri bilgilendirebilmek,<br>
        · Hizmetlerimiz ile ilgili Platform kullanıcılarının şikayet ve önerilerini değerlendirebilmek,<br>
        · Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı kullanabilmek,<br>
        · Dolandırıcılık başta olmak üzere her türlü yasadışı faaliyetin önüne geçebilmek.<br>
        <strong>5. KİŞİSEL VERİ SAHİPLERİNİN HAKLARI</strong><br>
        KVKK’nin 11.maddesi uyarınca aşağıdaki haklara sahip olduğunuzu hatırlatmak isteriz:<br>
        a) Kişisel verilerinizin işlenip işlenmediğini öğrenme; işlenmişse buna ilişkin bilgi talep etme,<br>
        b) Kişisel verilerinizin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,<br>
        c) Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri öğrenme,<br>
        d) Kişisel verileriniz eksik veya yanlış işlenmişse bu hususun düzeltilmesini isteme,<br>
        e) KVKK’nin 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini talep etme,<br>
        f) Kişisel verilerinizin aktarıldığı üçüncü kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,<br>
        g) Kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,<br>
        h) Kişisel verilerinizin KVKK’ye aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.<br>
        <strong>6. MUVAFAKAT</strong><br>
        FUTURE CAMPUS ile herhangi bir şekilde iletişime geçerek kişisel verilerinizi paylaşmanız halinde, yukarıda yapılan açıklamalar çerçevesinde <a href="www.futureaisummit.org">www.futureaisummit.org</a> adresinde yer alan Gizlilik Politikası dahilinde KVKK’ye uygun olarak FUTURE CAMPUS tarafından kişisel verilerinizin toplanmasına, işlenmesine, güncellenmesine, periyodik olarak kontrol edilmesine, veri tabanında tutulmasına ve saklanmasına ve 4. maddede belirtilen kurum ve kuruluşlarla paylaşılmasına ve kişisel verilerinizin bunlar tarafından da tutulmasına ve saklanmasına muvafakat etmiş olmaktasınız.<br>
        <strong>7. BAŞVURU</strong><br>
        Kişisel veri sahipleri, sorularını, görüşlerini veya taleplerini <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> adresine e-posta vasıtasıyla veya Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul adresinde bulunan yetkili temsilcilere posta ile veya elden teslimli olarak iletebilir.<br>
        Talepler <a href="www.futureaisummit.org">www.futureaisummit.org</a> adresinde bulunan Başvuru Formu ile gerçekleştirilmelidir.<br>
        <strong>İşbu bilgilendirme yazısı, KVKK’nin 10. maddesi uyarınca aydınlatma yükümlülüğünü yerine getirmek üzere hazırlanmıştır.</strong><br>
        <strong>BAHÇEŞEHİR ÜNİVERSİTESİ</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
    privacy_policy_approve:`Kişisel verilerin korunumu ve <strong>gizlilik sözleşmesini okudum</strong>, anladım kabul ediyorum.`,
    privacy_policy_message: `
        <strong>1. GİRİŞ</strong><br>
        Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul adresinde bulunan BAHÇEŞEHİR ÜNİVERSİTESİ (Bundan sonra “FUTURE CAMPUS” olarak anılacaktır) unvanlı topluluk olarak kişisel verilerinizin güvenliği hususuna azami hassasiyet göstermekteyiz. Kişisel verileriniz tarafımızca 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“KVKK”) uygun olarak işlenmekte ve muhafaza edilmektedir.<br>
        <a href="www.futureaisummit.org">www.futureaisummit.org</a> uzantılı internet sitesini (“İnternet Sitesi”) ve ileride uygulamaya konulabilecek mobil uygulamaları (“Mobil Uygulama”) ve bunlarla sınırlı olmamak üzere FUTURE CAMPUS kontrolünde olan herhangi bir çevrimiçi platformu ya da fiziki mekanı kullanarak ya da ziyaret ederek (tamamı bundan böyle “Platform” olarak anılacaktır), organize edilen etkinlere katılarak (“Etkinlikler”) işbu Gizlilik Politikasını (“Politika”) okuyup, anladığınızı ve bu Politikada belirtilmiş hususları kabul ettiğinizi teyit etmiş olursunuz.<br>
        Politikada yer alan herhangi bir hususun değiştirilmesi halinde Politika İnternet Sitemizde güncel haliyle, değişiklik tarihi de belirtilmek suretiyle yayınlanacaktır. Değişikliği izleyen 30 (otuz) gün boyunca bu durumu İnternet Sitesinden makul ve fark edilecek göze çarpan bir bağlantı ile bilgilerinize sunacağız.<br>
        <strong>2. VERİ SORUMLUSU OLARAK BİLGİLENDİRME</strong><br>
        FUTURE CAMPUS olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca ve yürürlükteki mevzuat hükümleri kapsamında veri sorumlusu sıfatıyla, kişisel verileriniz bu sayfada açıklandığı çerçevede; kaydedilecek, saklanacak, güncellenecek, mevzuatın izin verdiği durumlarda üçüncü kişilere açıklanabilecek, devredilebilecek, sınıflandırılabilecek ve KVKK’de sayılan şart ve koşullara uygun yöntemlerle işlenebilecektir.<br>
        <strong>3. KİŞİSEL VERİLERİN NE ŞEKİLDE İŞLENEBİLECEĞİ</strong><br>
        FUTURE CAMPUS ile paylaştığınız kişisel verileriniz, tamamen veya kısmen, otomatik olarak veyahut herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek, yeniden düzenlenerek, kısacası veriler üzerinde gerçekleştirilen her türlü işleme konu olarak tarafımızca işlenebilecektir. KVKK kapsamında veriler üzerinde gerçekleştirilen her türlü işlem “kişisel verilerin işlenmesi” olarak kabul edilmektedir.<br>
        <strong>4. İŞLENEBİLECEK KİŞİSEL VERİLER</strong><br>
        FUTURE CAMPUS, FUTURE CAMPUS’e ait herhangi bir Platforma üye olma, bu platformlar üzerinden işlem yapma, bu Platformlar vasıtasıyla ayni ya da nakdi destekte bulunma, bahse konu desteklerden yararlanma, Etkinliklere katılma, bilgi alma, herhangi bir satın alma, hizmet tedariği, bültenler alma, Etkinliklere katılma amaçları ile ilgili kişi tarafından girilen kişisel veriler, tüm bunlara katılımınızla ilgili veriler ve FUTURE CAMPUS ile bağlantı kurduğunuz üçüncü taraf platformlarından elde edilen veriler gibi belirli veriler toplanmaktadır. Ayrıca cihazınız hakkında bilgiler ve Platform üzerinde hangi kısımlar ile etkileşime girdiğiniz veya hangi kısmını kullanarak zaman geçirdiğiniz gibi bazı veriler otomatik olarak toplanmaktadır.<br>
        Aşağıda, topladığımız verileri daha iyi anlayabilmeniz için bazı örnekler verilmiştir:<br>
        <strong>Hesap Verileri:</strong> Belirli özellikleri (kullanıcı hesabı oluşturma, etkinliklere kaydolma ve katılma, Platformlardaki hizmetlerden faydalanma gibi) kullanmak için bir kayıt oluşturmanız gerekebilir. Kayıt oluşturduğunuzda veya güncellediğinizde ad-soyad, e-posta, telefon numarası doğum tarihi/yaş bilgisi, cinsiyet, adres, yaşanılan ülke, çalışılan/sahibi olunan şirket unvanı ve sektörü, görev unvanı gibi verileri toplayıp saklarız.<br>
        <strong>Paylaşılan İçerik:</strong> FUTURE CAMPUS’e ilişkin bir sosyal medya veya internet sayfasında yorumlar yayınlamak, sorular sormak veya yanıtlamak, FUTURE CAMPUS’e ait hesaplara, FUTURE CAMPUS yöneticilerine veya çalışanlarına ya da FUTURE CAMPUS destekçilerine, sponsorlara, iş ortaklarına mesajlar göndermek veya fotoğraflar ya da yüklediğiniz başka çalışmaları yayınlamak dahil olmak üzere başkalarıyla etkileşime girmenize ve herkese açık olarak içerik paylaşmanıza imkan sağlanmıştır. Bu tür paylaşılan içerikler, nerede yayınlandığına bağlı olarak başkaları tarafından herkese açık olarak görüntülenebilir.<br>
        <strong>Kullanıcı ve Ziyaretçi Verileri:</strong> Platformlara ya da Etkinliklere kaydolduğunuzda ya da katıldığınızda, yararlanacağınız olanaklara ilişkin bilgileriniz; FUTURE CAMPUS ile etkileşimleriniz ve FUTURE CAMPUS’ün sunacağı hizmetlere ilişkin gereksinimlerini yerine getirmek için gönderilen diğer öğeler dahil olmak üzere belirli verileri toplarız.<br>
        <strong>Ödeme Verileri:</strong> Platformlar dahilinde gerçekleştirmiş olduğunuz ödemelerle ilgili verileri (adınız-soyadınız, ödeme yaptığınız ödeme hizmetine yönelik bilgileriniz, adresiniz) toplarız. Adınız-soyadınız, T.C. kimlik numaranız, kredi kartı bilgileriniz, fatura adresiniz ve posta kodunuz gibi belirli ödeme ve fatura verilerini ödeme işleme ortaklarımıza doğrudan sağlamalısınız. Güvenlik nedeniyle FUTURE CAMPUS kredi kartı numaralarının tamamı veya kart kimlik doğrulama verileri gibi hassas kart sahibi verilerini toplamamakta veya saklamamaktadır.<br>
        <strong>İş Ortaklarının Verileri:</strong> FUTURE CAMPUS çalışanı, danışmanı, iş ortağı, destekçisi veya gönüllüsü iseniz ödeme hesabınıza ilişkin kişisel verilerinizi, güvenlik nedeniyle FUTURE CAMPUS hassas banka hesabı bilgilerini toplar veya saklar. Ödeme ve fatura bilgilerinizin toplanması, kullanılması ve ifşa edilmesi işbu Gizlilik Politikası ve ödeme hesabı sağlayıcınızın diğer şartlarına bağlıdır.<br>
        <strong>Başka Hesaplarınız Hakkında Veriler:</strong> FUTURE CAMPUS Platformlarına yönelik kullanıcı hesaplarınız, bir başka hesabınıza bağlıysa sosyal medya veya diğer çevrimiçi hesaplarınızdan belirli bilgiler edinebiliriz. Platformlarda, Facebook veya başka bir üçüncü taraf platformu ya da hizmeti aracılığıyla oturum açarsanız bu tür diğer hesapla ilgili belirli bilgilere erişmek için izninizi isteriz. Örneğin, platforma ya da hizmete bağlı olarak adınızı, profil resminizi, hesap kimlik numaranızı, oturum açma e-posta adresinizi, konumunuzu, erişim cihazlarınızın fiziksel konumunu, cinsiyetinizi, doğum gününüzü ve arkadaş veya kişi listenizi toplayabiliriz. Bu platformlar ve hizmetler, API’ları aracılığıyla bize bilgileri sunarlar. Aldığımız bilgiler sizin (gizlilik ayarlarınız aracılığıyla) veya platformun ya da hizmetin bize hangi bilgileri vermeye karar verdiğinize bağlıdır.<br>
        <strong>Çekilişler, Promosyonlar ve Anketler:</strong> Platformlarda yer alan olanaklar veya bir üçüncü taraf platformu aracılığıyla sizi bir anket doldurmaya veya bir promosyona (çekiliş veya yarışma gibi) katılmaya davet edebiliriz. Katılmanız halinde ad-soyad, e-posta, telefon numarası doğum tarihi/yaş bilgisi, cinsiyet, adres, yaşanılan ülke, çalışılan/sahibi olunan şirket unvanı ve sektörü, görev unvanı gibi katılımınızın bir parçası olarak bize sağladığınız verileri toplar ve saklarız. Promosyonun resmi kurallarında veya başka bir gizlilik politikasında aksi belirtilmediği sürece bu veriler bu Politika şartlarına tabidir. Toplanan veriler, kazananlara bildirimde bulunmak ve ödülleri dağıtmak dahil olmak üzere promosyonu veya anketi yönetmek için kullanılacaktır. Bir ödül
        kazanmak için bazı bilgilerinizi herkese açık olarak (örneğin kazananlar sayfasında) yayınlamamıza izin vermeniz gerekebilir. Bir anket ya da promosyonu yönetmek için bir üçüncü taraf platformu kullandığımızda üçüncü tarafın gizlilik politikası geçerli olur.<br>
        <strong>İletişim ve Destek:</strong> Destek almak veya bir sorun ya da endişe bildirmek (bir hesap oluşturmuş olsanız da olmasanız da) için bizimle iletişime geçmeniz halinde sizin hakkınızda iletişim bilgileri, mesajlar ve adınız, e-posta adresiniz, konumunuz, işletim sisteminiz, IP adresiniz ve sizin sağladığınız ve bizim otomatik yöntemlerle topladığımız (aşağıda yer verdiğimiz) diğer tüm bilgiler gibi diğer verileri toplar ve saklarız. Bu verileri, Politikaya uygun şekilde size yanıt vermek ve sorunuzu cevaplamak ya da endişenizi araştırmak suretiyle gidermek için kullanırız.<br>
        <strong>5. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</strong><br>
        Paylaştığınız kişisel veriler:<br>
        · Sizlere sunmuş olduğumuz hizmetlere ve iş ortaklarımız ile gerçekleştirdiğimiz faaliyetlerin gerçekleştirilebilmesine yönelik sunulan hizmetlerin yerine getirilebilmesi ve hizmetlerin yerine getirilmesinin kolaylaştırılması için;<br>
        · Sizlerle iletişim kurabilmek, bilinirlik ve tanıtım faaliyetlerimizi yönetebilmek için;<br>
        · Kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu görevlilerine bilgi verebilmek için;<br>
        · Sizlere geniş kapsamda çeşitli imkanlar sunabilmek veya bu imkanları sunabilecek kişi veya kurumlarla yasal çerçevede paylaşabilmek için KVKK ile ilgili ikincil düzenlemelere uygun olarak işlenecektir.<br>
        <strong>6. KİŞİSEL VERİLERİN TOPLANMA ŞEKLİ</strong><br>
        Kişisel verileriniz:<br>
        · İnternet sitesi ve Mobil Uygulamalardaki ya da diğer Platformları formlar ile ad-soyad, e-posta, telefon numarası doğum tarihi/yaş bilgisi, cinsiyet, yaşanılan ülke, çalışılan/sahibi olunan şirket unvanı ve sektörü, görev unvanı; kullanıcı adı ve şifresi kullanılarak giriş yapılan sayfalardaki tercihleri, gerçekleştirilen işlemlerin IP kayıtları, tarayıcı tarafından toplanan çerez verileri ile gezinme süre ve detaylarını içeren veriler, konum verileri şeklinde;<br>
        · Etkinlik ve danışma faaliyetlerimiz, kağıt üzerindeki formlar, kartvizitler, dijital ortamlar gibi kanallarımız aracılığıyla sözlü, yazılı veya elektronik ortamdan;<br>
        · FUTURE CAMPUS ile ilişki kurmak ve minvalde iletişime geçmek, FUTURE CAMPUS Etkinliklerine katılmak, ziyaret etmek, FUTURE CAMPUS Platformlarına kullanıcı başvurusu yapmak, iş başvurusu yapmak, teklif vermek gibi amaçlarla, kartvizit, özgeçmiş (cv), teklif vermek ve sair yollarla kişisel verilerini paylaşan kişilerden alınan, fiziki veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya yazılı ya da elektronik ortamdan;<br>
        · Ayrıca farklı kanallardan dolaylı yoldan elde edilen, web sitesi, blog, yarışma, anket, oyun, kampanya ve benzeri amaçlı kullanılan (mikro) web sitelerinden ve sosyal medyadan elde edilen veriler, e-bülten okuma veya tıklama hareketleri, kamuya açık veri tabanlarının sunduğu veriler, sosyal medya platformları (Facebook, Twitter, Google, Instagram, Snapchat v.s.) gibi sosyal paylaşım sitelerinden paylaşıma açık profil ve verilerden işlenebilmekte ve toplanabilmektedir.<br>
        <strong>7. KİŞİSEL VERİLERİN AKTARILMASI</strong><br>
        FUTURE CAMPUS, kişisel verilerinizi, işbu Gizlilik Politikasında yer alan amaçlar ve çalışma alanları doğrultusunda sağlanabilecek olanakları ve faydaları yasal çerçevede eksiksiz ve zamanında sunulabilmek, FUTURE CAMPUS’ün Platform kullanıcılarını kullanıcılar özelinde ve sınırlı olarak sağladığı imkanlardan haberdar edebilmek, üyeleri ile üye olmayan faydalanıcılara ve diğer
        paydaşlarına verilen hizmetlerin gereklerini uygun şekilde yerine getirebilmek, sunulan hizmetleri ve gerçekleştirilen faaliyetleri geliştirebilmek, ÖZETLE Platformları kullanan ve Etkinliğe katılan/katılacak kişilerin faydasına olacak bir şekilde; işlem sahibinin bilgilerini tespit için kimlik, adres ve diğer gerekli bilgileri kaydetmek yükümlülüğünü yerine getirmek, mevzuat gereği ve diğer otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme yükümlülüklerine uymak, kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği başta yargı mercileri olmak üzere her türlü resmi kurumlara ve ilgili kamu görevlilerine bilgi verebilmek, kullanıcılarına geniş kapsamda çeşitli imkanlar sunabilmek veya bu imkanları sunabilecek kişi yahut kurumlarla yasal çerçevede paylaşabilmek için KVKK’nin 8.ve 9.maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarabilecektir.<br>
        Bu kapsamda Kişisel Verileriniz:<br>
        · Hizmetlerin sunulması ve tanıtılması ve faaliyetlerin geliştirilmesine yönelik olarak başta Bahçeşehir ve Uğur Eğitim Kurumları olmak üzere olmak üzere iş birliği yapılan ve/veya hizmet alınan iş ortaklarımız, tedarikçi firmalar ile bankalar, finans kuruluşları, TBB Risk Merkezi ve sair gerçek veya tüzel kişilere,<br>
        · Acil yardım çağrısı halinde konumuzu tespit edecek olan yetkili mercilere,<br>
        · Avukatlar, denetçiler, danışmanlar ve hizmet alınan diğer üçüncü kişilere,<br>
        · Tarafınızca yetki verilmiş olan vekil ve temsilcilerinize,<br>
        · Düzenleyici ve denetleyici kurumlar ile mahkeme ve icra müdürlükleri gibi sair resmi kurumlara, kişisel verileriniz talep etmeye yetkili olan diğer kamu kurum veya kuruluşlarına,<br>
        · Şirketi ile herhangi bir şekilde ilişki içinde bulunan ve telefon numaranıza sahip tüzel kişilere,<br>
        · Mobil hatlarınız üzerinden yaptığınız bankacılık ve finans benzeri işlemleriniz sırasında işlem ve bilgi güvenliğinizin sağlanması ve kimlik doğrulama amacıyla sim kart değişikliği, mobil internete bağlı olduğunuz telefon numarası gibi bilgiler, banka ve finans benzeri kuruluşlara,<br>
        Türkiye’de işlenerek veya Türkiye dışında işlenip muhafaza edilmek üzere, yukarıda sayılan yöntemlerden herhangi birisi ile toplanmış kişisel verileriniz KVKK kapsamında kalmak kaydıyla ve sözleşme amaçlarına uygun olarak yurtdışında bulunan (Kişisel Veriler Kurulu tarafından akredite edilen ve kişisel verilerin korunması hususunda yeterli korumanın bulunduğu ülkelere veya standart sözleşme çerçevesinde uygunluğu mevzuat hükümlerine göre teyit edilmiş kişilere) hizmet aracılarına da aktarılabilecektir.<br>
        <strong>8. KİŞİSEL VERİLERİN SAKLANMASI VE KORUNMASI</strong><br>
        FUTURE CAMPUS, kişisel verilerinizin kaybolması, istismar edilmesi veya değiştirilmesini önlemeye yönelik uygun ve makul şekilde teknik ve organizasyona dayalı önlemler almaktadır.<br>
        FUTURE CAMPUS, kişisel verilerinizin barındığı sistemleri ve veri tabanlarını, KVKK’nin 12.maddesi gereği kişisel verilerin hukuka aykırı olarak işlenmesini önlemekle, yetkisiz kişilerin erişimlerini engellemekle; muhafazalarını sağlamak amacıyla gerekli önlemleri almakla yükümlüdür.<br>
        İnternet üzerinden veri aktarımının internetin doğası gereği güvenli olmadığı kabul edilebileceğinden, Şirketimizin almış olduğu makul tüm önlemler dışında, internet üzerinden gönderilen verilerin güvenliği garanti edilememektedir. Ancak FUTURE CAMPUS, bu verilerin güvenliğine yönelik olağan şartlar altında teknik ve organizasyona dayalı olarak gereken tüm tedbirleri sağlayacaktır.<br>
        Kişisel verilerin yasal olmayan yollarla başkaları tarafından elde edilmesinin öğrenilmesi halinde durum derhal, yasal düzenlemeye uygun ve yazılı olarak Kişisel Verileri Koruma Kurulu’na bildirilecektir.<br>
        <strong>9. KİŞİSEL VERİLERİN GÜNCEL OLMASI</strong><br>
        FUTURE CAMPUS’ün KVKK’nin 4.maddesi uyarınca, kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda FUTURE CAMPUS’ün yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi için tarafımızla kişisel verilerini paylaşanların doğru ve güncel verilerini paylaşması veya kullanılan Platform üzerinden zamanında güncellemesi gerekmektedir.<br>
        Doğrulanmayan ve/veya güncellenmeyen kişisel verilere ilişkin kullanıcıların, Platformlar nezdindeki faydalardan ve imkanlardan yararlanma süreçleri FUTURE CAMPUS tarafından geçici süreyle ya da kalıcı olarak askıya alınabilir.<br>
        <strong>10. KİŞİSEL VERİ SAHİBİNİN HAKLARI</strong><br>
        KVKK’nin 11.maddesi gereğince, kişisel veri sahibi olarak bu tarihten sonraki haklarınız aşağıdaki gibidir:<br>
        a) Kişisel verilerinizin işlenip işlenmediğini öğrenme; işlenmişse buna ilişkin bilgi talep etme,<br>
        b) Kişisel verilerinizin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,<br>
        c) Kişisel verilerinizin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri öğrenme,<br>
        d) Kişisel verileriniz eksik veya yanlış işlenmişse bu hususun düzeltilmesini isteme,<br>
        e) KVKK’nin 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini talep etme,<br>
        f) Kişisel verilerinizin aktarıldığı üçüncü kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme,<br>
        g) Kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,<br>
        h) Kişisel verilerinizin KVKK’ye aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.<br>
        <strong>11. BAŞVURU</strong><br>
        FUTURE CAMPIUS, KVKK kapsamında veri sorumlusudur. Atanacak veri sorumlusu temsilcisi, yasal altyapı sağlandığında işbu belgenin bulunduğu Platformda ilan edilecektir. Kişisel veri sahipleri, sorularını, görüşlerini veya taleplerini <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> adresine e-posta vasıtasıyla veya Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul adresinde bulunan FUTURE CAMPUS yetkili temsilcisine posta ile veya elden teslimli olarak iletebilir.<br>
        Talepler <a href="www.futureaisummit.org">www.futureaisummit.org</a> adresinde bulunan Başvuru Formu ile gerçekleştirilmelidir. FUTURE CAMPUS, kendisine iletilen taleplere gerekçeli olmak ve 30 (otuz) gün içinde cevap vermek kaydıyla olumlu/olumsuz yanıtını, yazılı veya dijital ortamdan verebilir. Taleplere ilişkin gerekli işlemlerin ücretsiz olması esastır. Ancak işlemlerin bir maliyet gerektirmesi halinde, FUTURE CAMPUS, ücret talebinde bulunma hakkını saklı tutar. Bu ücretler, Kişisel Verilerin Korunması Kurulu tarafından, Kişisel Verilerin Korunması Kanunu’nun 13.maddesine göre belirlenen tarife üzerinde tespit edilir.<br>
        İnternet Sitesi, Mobil Uygulamalar ve diğer Platformlarda kişisel verilerinizi paylaşarak işbu Politikayı işburada yer alan işlenme, işlenme yöntemleri, verilerin aktarılması, satışı ve diğer ilgili hususlar hakkındaki şartları kabul ettiğinizi; yasal haklarınızı kullanmadan önce FUTURE CAMPUS’e başvuruda bulunacağınızı; KVKK’de “büyük öneme haiz, belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rıza” şeklinde tanımlanan açık bir rıza ile kabul ettiğinizi beyan etmiş olursunuz.<br>
        <strong>BAHÇEŞEHİR ÜNİVERSİTESİ</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/İstanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
}

export default translations


