<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('program_coordinator')"
                        :is-filter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('program_coordinator')"
                              :is-filter="false" />
            </template>
            <b-row>
                <b-col md="12">
                    <b-button variant="link"
                              v-b-toggle="`course-1`"
                    ><b>{{program.code+' - '+getLocaleText(program, 'name')}}</b></b-button>
                    <b-collapse :id="`course-1`" visible accordion="my-accordion">
                        <b-col md="12" class="ml-4">
                            <b-button variant="link" @click="openDefinitions(program, 'update-form')"><span class="text-black-50">{{ '1. '+$t('program_definitions') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4">
                            <b-button variant="link" @click="openDefinitions(program, 'outcomes')"><span class="text-black-50">{{ '2. '+$t('program_outcome') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4">
                            <b-button variant="link" @click="openDefinitions(program, 'basic-fields')"><span class="text-black-50">{{ '3. '+$t('program_basic_fields') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('programleveloutcome_index')">
                            <b-button variant="link" @click="openDefinitions(program, 'level-outcomes')"><span class="text-black-50">{{ '4. '+$t('program_level_outcomes') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('programbasicfieldoutcome_index')">
                            <b-button variant="link" @click="openDefinitions(program, 'basic-field-outcomes')"><span class="text-black-50">{{ '5. '+$t('program_basic_field_outcomes') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('programbasicfieldoutcome_index')">
                            <b-button variant="link" @click="openDefinitions(program, 'update-course-coordinator')"><span class="text-black-50">{{ '6. '+$t('course_coordinator_assignment') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('ects_updatecoursebyprogramcoordinator')">
                            <b-button variant="link" @click="openDefinitions(program, 'program-courses')"><span class="text-black-50">{{ '7. '+$t('ects_program_courses') }}</span></b-button>
                        </b-col>
                    </b-collapse>
                </b-col>
            </b-row>

            <CommonModal ref="updateModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="closeDefinitions">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="program" :program="program" @closeModal="closeDefinitions" :is="isPage" @openCourseDetail="openCourseDetail"></component>
                </template>
            </CommonModal>
            <CommonModal ref="updateCourseDetailModal" size="xl" @bHideModalHeaderClose="closeCourseDetail">
                <template v-slot:CommonModalTitle>
                    {{ courseUpdateModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <component v-if="course" :is="is2Page" :course="course" @closeComponent="closeCourseDetail" @openComponent="openCourseDetail"></component>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import CommonModal from "@/components/elements/CommonModal"

// Services
import EctsService from "@/services/EctsService"
import ProgramService from "@/services/ProgramService"

// Others

// Pages
import ProgramCourses from "./ProgramCourses"
import qs from "qs";
import ProgramCoursesUpdateForm from "./ProgramCoursesUpdateForm"
import UpdateForm from "@/modules/programs/pages/definition/UpdateForm"
import Outcomes from "@/modules/programs/pages/definition/Outcomes"
import BasicFields from "@/modules/programs/pages/definition/BasicFields"
import LevelOutcomes from "@/modules/programs/pages/definition/LevelOutcomes"
import BasicFieldOutcomes from "@/modules/programs/pages/definition/BasicFieldOutcomes"
import UpdateCourseCoordinator from "@/modules/programs/pages/definition/UpdateCourseCoordinator"
import WeeklyCoursePlan from "./WeeklyCoursePlan"
import WeeklyCoursePlanForm from "./WeeklyCoursePlanForm"
import CourseAssessment from "./CourseAssessment"
import CourseAssessmentForm from "./CourseAssessmentForm"


export default {
    components: {
        ProgramCoursesUpdateForm,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        ProgramCourses,
        UpdateForm,
        Outcomes,
        BasicFields,
        LevelOutcomes,
        BasicFieldOutcomes,
        UpdateCourseCoordinator,
        WeeklyCoursePlan,
        WeeklyCoursePlanForm,
        CourseAssessment,
        CourseAssessmentForm
    },
    metaInfo() {
        return {
            title: this.$t('program_coordinator')
        }
    },
    data() {
        return {
            updateModalTitle: null,
            isPage: null,
            stateData: null,
            program: {},
            course: null,
            courseUpdateModalTitle: null,
            is2Page: null
        }
    },
    created() {
        this.$store.dispatch("auth/initUser");
        this.stateData = this.$store.getters['auth/getUser']
        this.getProgram()
    },
    methods: {
        getProgram(){
            const config = {
                params: {
                    filter: {
                        faculty_code: this.stateData.active_role.faculty_code
                    },
                    sort: 'code',
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            ProgramService.getAllPublic(config).then(response => {
                this.program = response.data.data.filter((item) => {
                    if(item.code == this.stateData.active_role.program_code) {
                        return item
                    }
                })
                this.program = this.program[0]
            }).catch(e => {
                this.showErrors(e)
            })

        },

        openDefinitions(program, page){
            this.isPage = page
            this.program = program
            this.updateModalTitle = program.code+' - '+this.getLocaleText(program, 'name')
            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeDefinitions(){
            this.isPage = null
            this.$refs.updateModal.$refs.commonModal.hide()
        },

        openCourseDetail(data, is2Page){
            this.is2Page = is2Page
            this.course = data
            this.courseUpdateModalTitle = data.code+' - '+this.getLocaleText(data, 'name')
            this.$refs.updateCourseDetailModal.$refs.commonModal.show()
        },

        closeCourseDetail(goBackData){
            this.is2Page = null
            this.course = null
            this.$refs.updateCourseDetailModal.$refs.commonModal.hide()
            if(typeof goBackData === 'object'){
                this.openCourseDetail(goBackData.data, goBackData.component)
            }
        }
    }
}
</script>

