import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/applications', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/applications/' + id);
}

const downloadExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/applications/excel-export',{...config,responseType:'arraybuffer'})
}

const downloadTicket = (id)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/applications/ticket-download/' + id,{responseType:'arraybuffer'})
}

const downloadFile = (uuid)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/applications/download-file/' + uuid,{responseType:'arraybuffer'})
}

const approvedTicket = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/ticket/event/application/approve-application/' + id);
}

export default {
    getAll,
    get,
    downloadExcel,
    downloadTicket,
    downloadFile,
    approvedTicket
}
