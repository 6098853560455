const routes = [
    {
        path: "/new/events",
        component: () => import("../pages/Index.vue"),
        meta: {
            isAuthenticated: true,
            permission: "certificatecenter_index"
        }
    },

];

export default routes;
