<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('faculty_code')">
                                    {{ formData.faculty_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('department_code')">
                                    {{ formData.department_code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_level')">
                                    {{ getLocaleText(formData, 'level_text') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('program_duration')">
                                    {{ formData.duration }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-card bg-variant="light" v-for="(course, index) in coursesData" :key="index" class="mb-2">
                        <b-row>
                            <b-col md="12">
                                <b-button variant="link"
                                          v-b-toggle="`course-${index}`"
                                ><b><i class="ri-file-list-line"></i> {{course.code+' - '+getLocaleText(course, 'name')}}</b></b-button>
                                <b-collapse :id="`course-${index}`" visible accordion="my-accordion-2">
                                    <b-col md="12">
                                        <b-row>
                                            <b-col md="3">
                                                <b-form-group>
                                                    <label class="mb-0" v-if="checkPermission('ects_updatecoursebyprogramcoordinator')">
                                                        <b-button class="pl-0" variant="link" @click="openCourseDetail(course, 'program-courses-update-form')"><span>{{ $t('ects_course_polydef') }}</span></b-button>
                                                    </label>
                                                    <multi-selectbox
                                                        v-model="course.description_status"
                                                        :options="descriptionStatusOptions"
                                                        :multiple="false"
                                                        @input="changeDescStatus(course)"/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="3">
                                                <b-form-group>
                                                    <label class="mb-0" v-if="checkPermission('ects_programcoordinatorweeklycourseplans')">
                                                        <b-button class="pl-0" variant="link" @click="openCourseDetail(course, 'weekly-course-plan')"><span>{{ $t('weekly_course_plan') }}</span></b-button>
                                                    </label>
                                                    <multi-selectbox
                                                        v-model="course.weekly_plans_status"
                                                        :options="descriptionStatusOptions"
                                                        :multiple="false"
                                                        @input="changeWeeklyPlanStatus(course)"/>
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="3">
                                                <b-form-group>
                                                    <label class="mb-0" v-if="checkPermission('ects_programcoordinatorcourseassessments')">
                                                        <b-button class="pl-0" variant="link" @click="openCourseDetail(course, 'course-assessment')"><span>{{ $t('ects_course_assessment') }}</span></b-button>
                                                    </label>
                                                    <multi-selectbox
                                                        v-model="course.assessments_status"
                                                        :options="descriptionStatusOptions"
                                                        :multiple="false"
                                                        @input="changeCourseAssessmentStatus(course)"/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-collapse>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-card>
            </b-col>
        </b-row>

        <CommonModal ref="updateModal" size="md" @bHideModalHeaderClose="closeCourseDetail">
            <template v-slot:CommonModalTitle>
                {{ $t('edit') }}
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <program-courses-update-form v-if="course" :course="course"></program-courses-update-form>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components
import CommonModal from "@/components/elements/CommonModal"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
import EctsService from "@/services/EctsService"

// Pages
import ProgramCoursesUpdateForm from "./ProgramCoursesUpdateForm"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        CommonModal,
        ProgramCoursesUpdateForm,
        MultiSelectbox
    },
    props: {
        program: {
            type: Object,
        },
    },
    data() {
        return {
            formData: {},
            coursesData: [],
            course: null,
            descriptionStatusOptions: [
                {
                    value: "pending",
                    text: this.$t('ects_pending')
                },
                {
                    value: "approved",
                    text: this.$t('ects_approved')
                },
                {
                    value: "edit_request",
                    text: this.$t('ects_edit_request')
                }
            ]
        };
    },
    created() {
        this.formData = this.program
        this.getData()
    },
    methods: {
        getData(){
            EctsService.getProgramCoordinatorCourses().then(response => {
                this.coursesData = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        changeDescStatus(data){
            EctsService.updateCourseByProgramCoordinator(data.id, data).then(response => {
                this.$toast.success(this.$t('api.'+response.data.message))
            }).catch(e => {
                this.showErrors(e, this.$refs.formValidate)
            })
        },

        openCourseDetail(data, component){
            this.$emit('openCourseDetail', data, component)
        },

        closeCourseDetail(){
            this.course = null
        },

        changeWeeklyPlanStatus(data){
            EctsService.updateProgramCoordinatorWeeklyCoursePlan(data.id, data).then(response => {
                this.$toast.success(this.$t('api.'+response.data.message))
            }).catch(e => {
                this.showErrors(e, this.$refs.formValidate)
            })
        },

        changeCourseAssessmentStatus(data){
            EctsService.updateProgramCoordinatorCourseAssessments(data.id, data).then(response => {
                this.$toast.success(this.$t('api.'+response.data.message))
            }).catch(e => {
                this.showErrors(e, this.$refs.formValidate)
            })
        }

    },
}
</script>
