<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider name="academicYear" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.academicYear">
                                    </academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <module-selectbox
                                        :academic_year="defineForm.academicYear"
                                        :validate-error="errors[0]"
                                        value-type="id"
                                        v-model="defineForm.module">
                                    </module-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file
                                            v-model="defineForm.file"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('select_file')"
                                            :drop-placeholder="$t('drop_file')"
                                            ref="fileInput"
                                            multiple></b-form-file>
                                        <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{$t('browse')}}</b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
<!--                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group :label="$t('excel_format')">
                                <div class="alert alert-info">
                                    {{$t('prep_schedule_import_data_excel_format')}}
                                </div>
                            </b-form-group>
                        </div>-->

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                            <b-button @click="download('prep_schedules')" variant="outline-secondary" class="mr-2">{{ $t('download_excel_template') }}</b-button>
                            <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import PrepSchedule from "@/services/PrepSchedule";
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ModuleSelectbox,
        AcademicYearsSelectbox
    },

    data() {
        return {
            defineForm: {
                academicYear: null,
                module: null,
                file: null
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_schedule_draft_title")
        }
    },
    methods: {
        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])

                PrepSchedule.fileImport(this.defineForm.module, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.updateForm.errors.moduleId.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.file) {
                            this.$refs.updateForm.errors.file.push(e.data.errors.file[0]);
                        }
                    }
                })
            }
        },
        download(code) {
            excelImportTemplate.downloadTemplate(code)
                .then(response => {
                    this._downloadFile(response, code + '.xlsx')
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
    },

    created() {

    },

    watch: {

    }
};
</script>
