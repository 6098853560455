<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-6">
                    <ValidationProvider
                        name="ticket_event_id"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_type')">
                            <ticket-event-selectbox
                                v-model="form.ticket_event_id"
                            ></ticket-event-selectbox>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider
                        name="code"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('code')">
                            <b-form-input
                                v-model="form.code"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider
                        name="type"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('type')">
                            <b-form-select
                                v-model="form.type"
                                :options="typeOptions"
                            ></b-form-select>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider
                        name="discount"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('discount')">
                            <b-form-input
                                v-model="form.discount"
                                type="number"
                            ></b-form-input>
                            <span class="position-absolute" style="right: 40px; top: 48%; transform: translateY(-50%);">%</span>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider
                        name="description"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('explanation')">
                            <b-form-textarea
                                v-model="form.description"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider
                        name="status"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <b-form-select
                                v-model="form.status"
                                :options="statusOptions"></b-form-select>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6" v-if="form.type === 'general'">
                    <ValidationProvider
                        name="quota"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('quota')">
                            <b-form-input
                                v-model="form.quota"
                                type="number"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="createForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import TicketEventPromotionCodeService from "@/services/TicketEventPromotionCodeService";
    import TicketEventSelectbox from "@/components/interactive-fields/TicketEventSelectbox.vue";

    export default {
        components: {
            TicketEventSelectbox,
            ValidationProvider, ValidationObserver,
        },
        data() {
            return {
                formLoading: false,
                form: {},
                typeOptions: [
                    {value: 'general', text: this.$t('general') },
                    {value: 'personal', text: this.$t('individual') }
                ],
                statusOptions: [
                    {value: true, text: this.$t('active') },
                    {value: false, text: this.$t('passive') }
                ],
            }
        },
        watch: {
            'form.type'(newValue) {
                if (newValue === 'personal') {
                    this.form.quota = 1;
                }
            },
        },
        methods: {
            async createForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid) {
                    this.formLoading = true;
                    TicketEventPromotionCodeService.store(this.form)
                                    .then((response) => {
                                        this.$toast.success(this.$t("api." + response.data.message));
                                        this.$emit("createFormSuccess")
                                    })
                                    .catch((error) => {
                                        this.showErrors(error, this.$refs.formModalValidate)
                                    }).finally(() => {
                        this.formLoading = false;
                    });
                }
            }
        }
    }
</script>
