<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('asal')"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('asal')"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :exportExcel="checkPermission('asal_excelexport')"
                              @exportExcel="exportExcel" >
                <b-row>
                    <b-col>

                        <b-form-group :label="$t('Talep Türü')">
                            <multiselect v-model="datatable.queryParams.filter.request_type" :options="asal_type" :custom-label="nameWithLang" placeholder="" label="" track-by="" selectLabel="" selectedLabel=""    deselectLabel=""></multiselect>
                        </b-form-group>


                        <b-form-group :label="$t('Talep Nedeni')">
                            <parameter-selectbox code="asal_delay_reasons" v-model="datatable.queryParams.filter.request_reason">
                            </parameter-selectbox>
                        </b-form-group>

                    </b-col>
                    <b-col>

                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>



                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"
                            ></department-selectbox>
                        </b-form-group>



                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"
                                v-model="datatable.queryParams.filter.program_code"
                            ></program-selectbox>
                        </b-form-group>

                    </b-col>
                    <b-col>

                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.student_number">
                            </b-form-input>
                        </b-form-group>


                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>


                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname">
                            </b-form-input>
                        </b-form-group>

                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>

        </app-layout>
    </div>
</template>
<script>
//TEmplate
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
//Other
import qs from 'qs'
//Service
import AsalService from "@/services/AsalService";

export default {
    components: {
        ParameterSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        DatatableFilter,
        Datatable,

    },
    metaInfo() {
        return {
            title: this.$t('asal')
        }
    },
    data() {
        return {
            formId: 0,
            form: {},
            asal_type:[
                { name: this.$t('delay_1'), value: 'E' },
                { name:  this.$t('cancel'), value: 'I' }]
            ,
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [

                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable:false,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('request_type'),
                        field: this.getLocaleField('request_type_text'),
                        sortable: false,
                    },
                    {
                        label: this.$t('national_id'),
                        field: 'national_id',
                        sortable: false,
                    },
                    {
                        label: this.$t('request_reason'),
                        field: 'request_reason_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('reason_to_increase_max_duration'),
                        field: 'reason_to_increase_max_duration_name',
                        sortable: false,
                    },
                    {
                        label: this.$t('extra_time'),
                        field: 'extra_time',
                        sortable: false,
                    },

                    {
                        label: this.$t('yoksis_unit_id'),
                        field: 'yoksis_unit_id',
                        sortable: false,
                    },
                    {
                        label: this.$t('signer_name_surname'),
                        field: 'signer_name_surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('created_at'),
                        field: 'created_at',
                        sortable: false,
                    },

                    {
                        label: this.$t('result'),
                        field: 'result',
                        sortable: false,
                    },

                    {
                        label: this.$t('request_result'),
                        field: 'request_result',
                        sortable: false,
                    },
                    {
                        label: this.$t('declined_reason'),
                        field: 'declined_reason',
                        sortable: false,
                    },
                    {
                        label: this.$t('delay_end_date'),
                        field: 'delay_end_date',
                        sortable: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {
                    },
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        nameWithLang ({ name}) {
            return `${name}`
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return AsalService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        loadData(id) {
            this.formProcess = "update";
            this.formId = id;
            AsalService.get(id)
                .then(response => {
                    this.$refs.updateFormModal.$refs.commonModal.show();
                    this.form = response.data.data;
                }).catch(error => {
                if (error.data.message) {
                    this.$toast.error(this.$t('api.' + error.data.message));
                }
            });
        },
        exportExcel(){
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return AsalService.exportExcel(config)
                              .then((response) => {
                                  this._downloadFile(response,this.$t('asal')+'.xlsx')
                              })
                              .catch(err=>this.showErrors(err))
        }


    }
}
;
</script>
