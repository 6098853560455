<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('type')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.type === 'general' ? $t('general') : $t('individual') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('discount')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.discount) + '%' }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('explanation')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.description) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('quota')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.quota) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('status')">
                    <b-input-group>
                        <div class="label-as-input">{{ form.status === true ? $t('active') : $t('passive') }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import TicketEventPromotionCodeService from '@/services/TicketEventPromotionCodeService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                form: {
                    code: null,
                    name: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                TicketEventPromotionCodeService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.formLoading = false;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                });
            }
        }
    }
</script>
