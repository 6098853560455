const translations = {

    document_request_info_text: "In order to facilitate access to documents requested by our students (student certificates, transcripts, discipline reports, language proficiency certificates, etc.) requests for student documents with qualified electronic signature are to be made through OIS. However, students who request documents with electronic signature must first apply for the relevant documents through OIS and then visit the Student Affairs Office at Beşiktaş Campus to pick them up.We would like to remind you that original signed documents and documents with qualified electronic signature are “official documents” and therefore; using them in violation with the current laws and regulations or outside their intended purposes, modifying them or allowing other persons to use them constitute acts that require disciplinary punishments within the scope of the Higher Education Institutions Student Discipline Regulation and are considered as crimes subject to criminal sanctions within the scope of the Turkish Criminal Law.You may get your Student Certificate through the E-Government portal using your E-Government password. Student certificates obtained through E-Government are valid in all relevant institutions and organizations.",
    course_transfer_form_info: "You may click on \"Send Request\" button to create your request after you upload your course transfer document(s). You will be notified when your course transfer process is completed. You are required to confirm your course transfer process by clicking on \"Course Transfer\" on \"My Requests\" page. For those who with transcripts from Bahçeşehir University, the transcripts will be uploaded automatically by the system. Please refer to the <a href=\"#\" class='text-primary' download>User Guide</a> for further details.",
    privacy_notice_approve: `I have read, understood, and accept the <strong>clarification text on the protection of personal data</strong>`,
    privacy_notice_message: `BAHÇEŞEHİR UNIVERSITY, located at Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul (hereinafter referred to as "FUTURE CAMPUS"), provides the basic information regarding the protection of personal data transferred to or obtained by our organization below. <br>
        The detailed explanations below are presented to the attention of individuals using our website and/or future mobile applications, as well as participants in our events, business partners, employees, consultants, customers, and individuals visiting our workplaces or connecting to our internet network, in accordance with Article 10 of the Personal Data Protection Law No. 6698 (“KVKK”).<br>
        FUTURE CAMPUS reserves the right to update this Privacy Notice, which has been prepared in compliance with the applicable legislation, in line with any changes in the applicable legal framework. In such cases, updates and changes to the Privacy Notice will be explicitly stated on our websites and mobile applications.<br>
        Personal data shared with FUTURE CAMPUS is under the supervision and control of FUTURE CAMPUS. FUTURE CAMPUS assumes the responsibility as the data controller to establish, take, and adapt the necessary technical measures and organization to protect the confidentiality and integrity of information in accordance with applicable legislation. We assure you that periodic penetration tests are conducted in line with international and national technical standards related to data privacy and that our data processing policies are continuously updated.<br>
        <strong>1. LEGAL BASIS FOR PERSONAL DATA COLLECTION</strong><br>
        As FUTURE CAMPUS, we conduct activities in compliance with the applicable legislation regarding personal data processing. This includes primarily the KVKK, which defines general principles regarding personal data protection, and relevant provisions of the Turkish Penal Code No. 5237 that impose criminal sanctions in certain cases.<br>
        <strong>2. METHODS OF PERSONAL DATA COLLECTION</strong><br>
        Personal data you provide while using our website at <a href="www.futureaisummit.org">www.futureaisummit.org</a> (“Website”) and/or our future mobile applications (“Mobile Application”), participating in events such as the Future AI Summit or other activities, contacting us through any channel, attending our events, meetings, panels, or seminars, visiting our organization premises, making payments for event participation or support/sponsorship, applying for membership to our Platforms, or simply visiting our Platforms (collectively referred to as “Platform”), are processed by the relevant departments of FUTURE CAMPUS in compliance with this Privacy Notice and the Privacy Policy on our Website, in accordance with your consent and applicable legal provisions.<br>
        <strong>3. AREAS OF PERSONAL DATA USAGE</strong><br>
        Personal data obtained by FUTURE CAMPUS in compliance with applicable legislation is shared with third parties only within the framework of legal obligations, the consent of the data subject, or exceptions stipulated by the applicable legislation. Personal data is not shared with third parties in violation of the applicable legislation, except in cases required for the performance of services or transactions.<br>
        In addition, personal data may be shared with courts and other public authorities to fulfill legal obligations. Data transfer to third parties is conducted under technical and legal safeguards to prevent rights violations. However, FUTURE CAMPUS is not responsible for violations arising from the data protection policies of third parties or risks within their responsibility.<br>
        Personal data may be shared with the management of FUTURE CAMPUS, suppliers, business partners, supporters, and sponsors; institutions and organizations that support projects; cloud storage providers (domestic/foreign); Platform stakeholders; institutions providing commercial electronic communication services; banks and payment systems; various agencies and survey companies; and other domestic and international third parties, for purposes specified above.<br>
        <strong>4. PURPOSES OF PERSONAL DATA PROCESSING</strong><br>
        Your personal data collected by FUTURE CAMPUS may be processed for the following purposes:<br>
        · Verifying and confirming the identity of individuals registering on the Platforms controlled by FUTURE CAMPUS;<br>
        · Recording contact and other necessary information of individuals collaborating with FUTURE CAMPUS in various capacities;<br>
        · Communicating updates or information about agreements, partnerships, or collaborations with Platform users;<br>
        · Organizing records and documents necessary for any transactions conducted electronically or in paper format;<br>
        · Meeting public safety-related requests from public authorities as required by applicable laws;<br>
        · Improving user experience and satisfaction for Platform users and visitors;<br>
        · Conducting analyses and surveys for better service and feedback collection.<br>
        <strong>5. RIGHTS OF PERSONAL DATA OWNERS</strong><br>
        In accordance with Article 11 of the KVKK, you have the following rights:<br>
        a) To learn whether your personal data is processed; if so, request information;<br>
        b) To learn the purpose of processing and whether it is used in accordance with that purpose;<br>
        c) To know the third parties to whom personal data is transferred domestically or internationally;<br>
        d) To request correction of incomplete or incorrect data;<br>
        e) To request deletion or destruction of personal data as stipulated in Article 7 of the KVKK;<br>
        f) To request notification of these actions to third parties to whom personal data has been transferred;<br>
        g) To object to results against you derived from analysis conducted solely by automated systems;<br>
        h) To request compensation for damages if personal data is processed unlawfully.<br>
        <strong>6. CONSENT</strong><br>
        By contacting FUTURE CAMPUS or sharing your personal data in any manner, you consent to the collection, processing, and sharing of your personal data by FUTURE CAMPUS as outlined above, and in accordance with our Privacy Policy and the KVKK.<br>
        <strong>7. APPLICATION</strong><br>
        Personal data owners can send questions, opinions, or requests via email to <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> or through physical or postal delivery to Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul. Requests must be submitted using the Application Form available at <a href="www.futureaisummit.org">www.futureaisummit.org</a>.<br>
        <strong>This notice has been prepared in compliance with Article 10 of the KVKK to fulfill the obligation of disclosure.</strong><br>
        <strong>BAHÇEŞEHİR UNIVERSITY</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
    privacy_policy_approve:`I have read, understood, and accept the <strong>privacy agreement</strong> regarding the protection of personal data.`,
    privacy_policy_message: `
        <strong>1. INTRODUCTION</strong><br>
        As BAHÇEŞEHİR UNIVERSITY located at Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul (hereinafter referred to as “FUTURE CAMPUS”), we attach the utmost importance to the security of your personal data. Your personal data is processed and stored by us in accordance with the Personal Data Protection Law No. 6698 (“KVKK”).<br>
        By using or visiting the website at <a href="www.futureaisummit.org">www.futureaisummit.org</a> (“Website”), future mobile applications (“Mobile Application”), or any online platform or physical location controlled by FUTURE CAMPUS (collectively referred to as the “Platform”), and by participating in the organized events (“Events”), you confirm that you have read, understood, and accepted this Privacy Policy (“Policy”).<br>
        Any changes made to the provisions of this Policy will be updated and published on our Website, with the effective date of the changes explicitly stated. For 30 (thirty) days following the changes, we will notify you of the changes through a reasonable and noticeable link on the Website.<br>
        <strong>2. INFORMATION AS THE DATA CONTROLLER</strong><br>
        As FUTURE CAMPUS, in accordance with the Personal Data Protection Law No. 6698 (“KVKK”) and the provisions of the applicable legislation, your personal data will be recorded, stored, updated, disclosed to third parties where permitted by legislation, transferred, classified, and processed in the ways specified in this Policy as the data controller.<br>
        <strong>3. HOW PERSONAL DATA IS PROCESSED</strong><br>
        Your personal data shared with FUTURE CAMPUS may be fully or partially processed by automatic or non-automatic means, as part of a data recording system, by collecting, recording, storing, modifying, or reorganizing data. Under KVKK, all such operations are considered “processing of personal data.”<br>
        <strong>4. TYPES OF PERSONAL DATA THAT MAY BE PROCESSED</strong><br>
        FUTURE CAMPUS collects certain personal data that you provide for purposes such as registering on any Platform owned by FUTURE CAMPUS, performing transactions, providing monetary or in-kind support through these Platforms, benefiting from such support, participating in Events, obtaining information, making purchases, subscribing to newsletters, or establishing a connection with FUTURE CAMPUS through third-party platforms. Additionally, certain data such as device information and interactions on the Platform may be collected automatically.<br>
        Below are some examples of the data we collect to provide a better understanding:<br>
        <strong>Account Data:</strong> When you register or update your account to use specific features (such as creating a user account, registering for events, or using services on the Platforms), we collect and store data such as your name, surname, email address, phone number, date of birth/age, gender, address, country of residence, company name and sector, and job title.<br>
        <strong>Shared Content:</strong> You may interact and share public content such as comments, questions, or answers on FUTURE CAMPUS-related social media or websites. Depending on where the shared content is published, it may be publicly viewable by others.<br>
        <strong>User and Visitor Data:</strong> When you register for or attend Events, we collect specific data regarding the services you use or other elements you submit to meet FUTURE CAMPUS service requirements.<br>
        <strong>Payment Data:</strong> We collect data related to payments you make within the Platforms (e.g., name, surname, information about the payment service, and address). Sensitive cardholder data such as credit card numbers or verification codes are not collected or stored by FUTURE CAMPUS for security reasons.<br>
        <strong>Partner Data:</strong> If you are an employee, consultant, partner, supporter, or volunteer of FUTURE CAMPUS, we collect your personal data related to payment accounts and manage it under this Policy.<br>
        <strong>Data About Other Accounts:</strong> If your account on FUTURE CAMPUS Platforms is linked to another account (e.g., social media or online accounts), we may obtain certain information from such accounts. Depending on the platform or service, we may access data such as your name, profile picture, account ID, email address, location, physical location of access devices, gender, birth date, and friends or contact lists.<br>
        <strong>Surveys, Promotions, and Contests:</strong> If you participate in surveys, promotions, or contests through the Platforms, we collect data such as your name, surname, email address, phone number, and other related information.<br>
        <strong>Communication and Support:</strong> When you contact us for support or to report a concern, we collect and store data such as your name, email, location, operating system, IP address, and other data provided by you or collected automatically.<br>
        <strong>5. PURPOSES OF PERSONAL DATA PROCESSING</strong><br>
        Your personal data is processed for the following purposes:<br>
        · To provide and facilitate the services offered to you and the activities carried out with our partners;<br>
        · To communicate with you and manage promotional and awareness activities;<br>
        · To provide information to prosecutors, courts, and public officials upon request and as required by law in matters of public safety and legal disputes;<br>
        · To offer various opportunities to you or share these opportunities with relevant individuals or institutions within the legal framework.<br>
        <strong>6. METHODS OF PERSONAL DATA COLLECTION</strong><br>
        Your personal data may be collected:<br>
        · Via forms on the Website and Mobile Applications or other Platforms;<br>
        · Through activities and consultations via digital channels, physical forms, or other communication methods;<br>
        · Through indirect means such as public databases, social media, newsletters, and other sources.<br>
        <strong>7. TRANSFER OF PERSONAL DATA</strong><br>
        FUTURE CAMPUS may transfer your personal data for purposes outlined in this Policy and in accordance with KVKK Articles 8 and 9 to collaborators, regulatory authorities, and authorized institutions.<br>
        <strong>8. STORAGE AND PROTECTION OF PERSONAL DATA</strong><br>
        FUTURE CAMPUS takes appropriate measures to protect personal data from loss, misuse, or alteration.<br>
        <strong>9. ACCURACY OF PERSONAL DATA</strong><br>
        Under KVKK Article 4, you are responsible for keeping your personal data accurate and updated.<br>
        <strong>10. RIGHTS OF PERSONAL DATA OWNERS</strong><br>
        As per KVKK Article 11, you have the following rights:<br>
        a) To learn whether your personal data has been processed;<br>
        b) To request information about processing;<br>
        c) To learn the purpose of processing;<br>
        d) To request corrections if data is incomplete or inaccurate;<br>
        e) To request deletion or destruction of your data;<br>
        f) To object to the processing of data;<br>
        g) To request compensation for damages caused by unlawful processing.<br>
        <strong>11. APPLICATION</strong><br>
        Personal data owners can submit their inquiries or requests to <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a> or via mail to Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul. Requests must be submitted using the Application Form available on <a href="www.futureaisummit.org">www.futureaisummit.org</a>.<br>
        <strong>BAHÇEŞEHİR UNIVERSITY</strong><br>
        <strong>Çırağan Caddesi Osmanpaşa Mektebi Sok. No:4-6 Beşiktaş/Istanbul</strong><br>
        <a href="mailto:info@futureaisummit.org">info@futureaisummit.org</a>`,
}

export default translations


