<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('thesis_and_projects_admin_title')" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
                <HeaderMobile :title="$t('thesis_and_projects_admin_title')" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :export-excel="checkPermission('thesisandproject_export')" @exportExcel="excelExport">
                <b-row>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" size="sm"
                                v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox
                                v-model="datatable.queryParams.filter.semester"
                                code="semester" sort="code"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('semester_status')">
                            <parameter-selectbox code="semester_statuses"
                                                 v-model="datatable.queryParams.filter.semester_status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="text" size="sm"
                                v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('student_name')">
                            <b-form-input type="text" size="sm"
                                v-model="datatable.queryParams.filter.student_name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('student_surname')">
                            <b-form-input type="text" size="sm"
                                v-model="datatable.queryParams.filter.student_surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox v-model="datatable.queryParams.filter.department_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('advisor_name')">
                            <b-form-input type="text" size="sm"
                                v-model="datatable.queryParams.filter.advisor_name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('advisor_surname')">
                            <b-form-input type="text" size="sm"
                                v-model="datatable.queryParams.filter.advisor_surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('status')">
                            <multi-selectbox v-model="datatable.queryParams.filter.approve_status" :options="statusOptions"
                                :multiple="false" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>
            <CommonModal size="xl" ref="editModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('thesis_and_projects_admin_update') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <admin-update-form :id="selectedId" @hide="closeEditModal"></admin-update-form>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="lg" ref="approvedModal" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" >
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('coordinator_approved') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                   <admin-approved-status v-if="formProcess=='updateApproved'" :formId="formId" @successStatus="successStatusMessage"/>
                    </div>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import qs from "qs";
import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import AdminUpdateForm from "./AdminUpdateForm";
import CommonModal from "@/components/elements/CommonModal";
import ApprovalStepService from "@/services/ApprovalStepService";
import CurriculumService from "@/services/CurriculumService";
import AdminApprovedStatus from "@/modules/thesisAndProject/pages/AdminApprovedStatus.vue";
import ElectivePoolService from "@/services/ElectivePoolService";

export default {
    components: {
        AdminApprovedStatus,
        AppLayout,
        Header,
        HeaderMobile,

        Datatable,
        DatatableFilter,
        AcademicYearsSelectbox,
        SemesterTypeSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        AdminUpdateForm,
        CommonModal
    },
    data() {
        return {
            formId:null,
            formProcess:null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-pencil-fill',
                                permission: "thesisandproject_update",
                                callback: (row) => {
                                    this.selectedId = row.id
                                    this.$refs.editModal.$refs.commonModal.show()
                                }
                            },
                            {
                                text: this.$t('do_cancel'),
                                class: 'ri-close-circle-line',
                                permission: "approvalstep_cancel",
                                show: (row) => {
                                    if (row.active_step) {
                                        return true;
                                    }
                                    return false;
                                },
                                callback: (row) => {
                                    this.$swal.fire({
                                        text: this.$t('are_you_sure_to_cancel'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('yes'),
                                        cancelButtonText: this.$t('no')
                                    })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                ApprovalStepService.cancel(row.active_step.id).then((response) => {
                                                    this.filter();
                                                });
                                            }
                                        })
                                }
                            },

                            {
                                text: this.$t('do_cancel_proccess'),
                                class: 'ri-delete-bin-2-line',
                                permission: "thesisandproject_delete",
                                callback: (row) => {
                                    this.cancelingProccess(row.id)
                                }
                            },
                            {
                                text: (row) => {
                                    return this.getLocaleText(row, 'step_name')+' '+this.$t('approve_1')
                                },
                                class: 'ri-chat-check-fill',
                                permission: "thesisandproject_delete",
                                show:(row)=>{
                                  return   row.step_name!='' || null? true:false
                                },
                                callback: (row) => {
                                    this.openApprovedModal(row.id)
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('id').toUpper(),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.$t('academic_year').toUpper(),
                        field: this.getLocaleField('academic_year'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('semester').toUpper(),
                        field: this.getLocaleField('semester'),
                        hidden: false,
                        sortable: false,
                        formatFn: (row) => {
                            if (row == 1) {
                                return this.$t('fall')
                            } else {
                                return this.$t('spring')
                            }
                        }
                    },
                    {
                        label: this.$t('student_number').toUpper(),
                        field: 'student.student_number',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('student_name').toUpper(),
                        field: 'student.name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('student_surname').toUpper(),
                        field: 'student.surname',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('program').toUpper(),
                        field: this.getLocaleField('student.program_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('thesis_title').toUpper(),
                        field: 'title',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'approve_status',
                        hidden: false,
                        sortable: false,
                        formatFn: (row) => {
                            return this.$t(row)
                        }
                    },
                    {
                        label: this.$t('step_name').toUpper(),
                        field: this.getLocaleField('step_name'),
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('semester_status').toUpper(),
                        field: `student.${this.getLocaleField('semester_status_name')}`,
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.$t('student_status').toUpper(),
                        field: `student.${this.getLocaleField('student_status_name')}`,
                        hidden: false,
                        sortable: false
                    }

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            selectedId: null,
            statusOptions: [
                {
                    value: 'pending',
                    text: this.$t('pending')
                },
                {
                    value: 'approved',
                    text: this.$t('approved')
                },
                {
                    value: 'rejected',
                    text: this.$t('rejected')
                }
            ],
        }
    },
    metaInfo() {
        return {
            title: this.$t("thesis_and_projects_admin_title")
        }
    },
    methods: {
        filter() {
            this.getRows();
        },

        filterClear() {
            this.filterSet()
            this.getRows();
        },

        filterSet() {
            this.datatable.queryParams.filter = {
                id: null
            };
        },
        formClear(){
            this.formId=null
            this.formProcess=null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },

        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },

        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },

        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return ThesisAndProjectService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total

                    // this.datatable.forEach(itm=>{
                    //     itm.columns.forEach(value=>{
                    //         value.buttons.forEach(i=>{
                    //             i.text=i.text.replace('Danışman','deneme')
                    //         })
                    //
                    //     })
                    // })
                        // this.datatable.columns.forEach(itm=>{
                        //     itm.buttons.forEach(value=>{
                        //         value.text=value.text.replace('Danışman','deneme')
                        //     })
                        // })

                }).finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        deleteItem(row) {
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('elective_pools_delete_confirm_text'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    ElectivePoolService.remove(row.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    })
                }
            })
        },
        excelExport() {
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };
            ThesisAndProjectService.exportExcel(config)
                .then(response => {
                    this.$toast.success(this.$t(response.data.message));
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(err => {
                    this.showErrors(err)
                })
        },
        openApprovedModal(id){
            this.formId=id
            this.formProcess='updateApproved'
            this.$refs.approvedModal.$refs.commonModal.show()
        },
        successStatusMessage(){
            this.formProcess=null
            this.formId=null
            this.$refs.approvedModal.$refs.commonModal.hide()
            this.getRows()
        },
        closeEditModal() {
            this.$refs.editModal.$refs.commonModal.hide()
            this.getRows()
        },

        cancelingProccess(id){
            this.$swal.fire({
                text: this.$t('leave_of_absence_cancel_proccess_text'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    ThesisAndProjectService.cancelingProccess(id).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.getRows()
                    }).catch(e => {
                        this.showErrors(e)
                    })
                }
            })
        }
    },

    created() {
        this.filterSet()
    },

    watch: {}
};
</script>
