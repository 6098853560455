<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduate')"
                        :isFilter="false"
                        :is-back-button="true"
                        :back-button="backButton"
                        :selected-menu="selectedMenu" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('graduate')"
                              :isFilter="false"
                              :is-back-button="true"
                              :back-button="backButton"
                              :selected-menu="selectedMenu" />
            </template>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-lg-5">
                    <info-page :personalInfo="responsePersonalData" :otherInfo="responseOtherData" :editMode="true" />
                </div>
                <div class="mr-lg-5">
                    <menu-page v-model="selectedMenu" :menu-data="menuData" />
                    <b-dropdown :text="computeDropdownMenuName" block split split-variant="outline-primary"
                                variant="primary" class="d-block d-lg-none secondary-border-lighter btn-outline-secondary mb-3">
                        <template v-for="menu in menuData">
                            <b-dropdown-item :key="'dropdownMenu' + menu.slug" @click="selectedMenu = menu.slug"
                                             v-if="(menu.permission == '' || checkPermission(menu.permission)) && menu.show">
                                {{ menu.title.toUpper() }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </div>
                <div class="flex-grow-1 w-100 w-lg-0">
                    <component :is="selectedComponent" :personalInfo="responsePersonalData" :isUpdate="isUpdate" @updateClick="callUpdateModal" @updateSuccess="closeModal($event)"></component>
                </div>
            </div>
            <CommonModal ref="updateModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <component :is="updateNavi" v-if="updateNavi" :isUpdate="isUpdate" :formId=formId @updateSuccess="closeModal($event)" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import CommonModal from "@/components/elements/CommonModal";

//Pages
import GraduateInfo from "./actionTabs/GraduateInfo"
import ContactConfirmations from "./actionTabs/ContactConfirmations"
import EducationInfo from "./actionTabs/EducationInfo"
import JobInformation from "./actionTabs/JobInformation"
import Other from "./actionTabs/Other"
import PersonalInfo from "./actionTabs/PersonalInfo"
import WorkHistoryAdd from "./actionTabs/WorkHistoryAdd"
import WorkHistoryEdit from "./actionTabs/WorkHistoryEdit"
import MeetingsAdd from "./actionTabs/MeetingsAdd"
import MeetingsEdit from "./actionTabs/MeetingsEdit"
import EducationInfoAdd from "./actionTabs/EducationInfoAdd"
import EducationInfoEdit from "./actionTabs/EducationInfoEdit"

import GraduateInfoTab from "./tabs/GraduateInfo"
import PersonalInfoTab from "./tabs/PersonalInfo"
import JobInformationTab from "./tabs/JobInformation"
import EducationInfoTab from "./tabs/EducationInfo"
import ContactConfirmationsTab from "./tabs/ContactConfirmations"
import OtherTab from "./tabs/Other"
import WorkHistoryTab from "./tabs/WorkHistory"
import MeetingsTab from "./tabs/Meetings"

import InfoPage from "./InfoPage"
import MenuPage from "./MenuPage"
import menuData from "@/modules/graduates/data/Menus"

// Services
import AlumniService from '@/services/AlumniService';

// Others

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,

        GraduateInfoTab,
        PersonalInfoTab,
        JobInformationTab,
        EducationInfoTab,
        ContactConfirmationsTab,
        OtherTab,
        WorkHistoryTab,
        MeetingsTab,

        GraduateInfo,
        ContactConfirmations,
        EducationInfo,
        JobInformation,
        Other,
        PersonalInfo,
        WorkHistoryAdd,
        WorkHistoryEdit,
        MeetingsAdd,
        MeetingsEdit,
        EducationInfoAdd,
        EducationInfoEdit,

        InfoPage,
        MenuPage,
    },

    metaInfo() {
        return {
            title: this.$t('graduate')
        }
    },

    data() {
        return {
            formId:null,
            responsePersonalData: null,
            responseOtherData: null,
            studentId: null,
            pageTitle: this.$t('graduate_info'),
            componentNavi: 'GraduateInfoTab',
            microTabs: [
                {
                    name: this.$t('graduate_info'),
                    component: 'GraduateInfoTab'
                },
                {
                    name: this.$t('work_history'),
                    component: 'WorkingHistoryTab'
                },
                {
                    name: this.$t('meetings'),
                    component: 'MeetingsTab'
                }
            ],
            updateModalTitle: '',
            updateNavi: null,
            updateData: null,

            selectedMenu: 'graduate_info',
            selectedComponent: 'GraduateInfoTab',
            menuData: null,
            isUpdate: false,
            isCreate: true
        }
    },
    created() {
        this.studentId = this.$route.params.id;
        this.PersonalData()
        this.OtherData()
        this.menuData = menuData(null)
    },
    methods: {
        tabNavi(elem){
            this.pageTitle = elem.name
            this.componentNavi = elem.component
        },

        callUpdateModal(data,formId){
            this.isUpdate = false
            this.updateModalTitle = this.$t(data[data.length - 1])
            this.updateNavi = this.createTabName(data)
            this.formId = formId
            if (this.updateNavi === 'EducationInfo') {
                this.$refs.updateModal.size = 'md';
            } else {
                this.$refs.updateModal.size = 'xl';
            }
            this.$refs.updateModal.$refs.commonModal.show()
        },

       async closeModal(event){
            this.isUpdate = true
            this.selectedMenu=event
            this.$refs.updateModal.$refs.commonModal.hide()
        },
        createTabName(str){
            let response = ''
            let dataArr = str[0].split('_')

            dataArr.forEach(itm => {
                response += itm.charAt(0).toUpperCase() + itm.slice(1)
            })

            if(str.length > 1) {
                response += str[1].charAt(0).toUpperCase() + str[1].slice(1)
            }
            return response
        },

        createComponentTabName(str){
            return this.createTabName([str])+'Tab'
        },
        PersonalData(){
            return AlumniService.PersonalInfo(this.studentId)
                .then(response => {
                    this.responsePersonalData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        OtherData(){
            return AlumniService.OtherInfo(this.$route.params.id)
                .then(response => {
                    this.responseOtherData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },
        backButton() {
            this.$router.push('/graduates')
        },
    },

    computed: {
        computeDropdownMenuName: {
            get() {
                let selected = (this.menuData || []).find(item => item.slug == this.selectedMenu)
                return selected ? this.toUpperCase(selected.title) : '';
            },
            set() {

            }
        },
    },

    watch: {
        selectedMenu: {
            handler(newValue){
                this.selectedComponent = this.createComponentTabName(newValue)
            }
        }
    }
}
</script>

