const routes = [
    {
        path: "/ects/coordinator/courses",
        component: () => import("../pages/coordinator-courses/Index"),
        meta: {
            isAuthenticated: true,
            permission:'ects_coordinatorcourses'
        }
    },
    {
        path: "/ects/program/coordinator",
        component: () => import("../pages/program-coordinator/Index"),
        meta: {
            isAuthenticated: true,
            //permission:'ects_programcoordinatorcourses'
        }
    },

];

export default routes;
