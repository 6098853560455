<template>
    <div>
        <div class="data-form">
            <ValidationObserver ref="importForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="semester" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('semester')">
                                <semesters-selectbox :validate-error="errors[0]" v-model="defineForm.semester_id" />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="section_type" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('section_type')">
                                <b-form-select v-model="defineForm.section_type" :options="options"
                                    :class="!!errors[0] == true ? 'box-border-color' : ''" />
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file v-model="defineForm.file" :state="errors[0] ? false : null"
                                        :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')" ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary"
                                        @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                    </b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
<!--                                <div class="alert alert-info mt-2" v-if="defineForm.section_type == 1">{{
                                    $t('section_import_excel') }}</div>
                                <div class="alert alert-info mt-2" v-if="defineForm.section_type == 2">{{
                                        $t('section_detail_import_excel') }}</div>-->
                                <b-button v-if="defineForm.section_type == 1" @click="download('sections_import')" variant="outline-secondary" class="my-2">{{ $t('download_excel_template') }}</b-button>
                                <b-button v-if="defineForm.section_type == 2" @click="download('sections_detail_import')" variant="outline-secondary" class="my-2">{{ $t('download_excel_template') }}</b-button>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <processing-button :processing="processing" :label="$t('import')" variant="primary"
                            @click="sendForm" />
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import ProcessingButton from "@/components/elements/ProcessingButton";
import SectionService from "@/services/SectionService";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
    components: {
        ProcessingButton,
        SemestersSelectbox,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            defineForm: {
                semester_id: null,
                file: null,
                section_type: null
            },
            processing: false,
            options: [{ value: null, text: this.$t('select') },
            { value: 1, text: this.$t('section') },
            { value: 2, text: this.$t('section_detail') }]
        }
    },
    metaInfo() {
        return {
            title: this.$t("section_operations")
        }
    },
    methods: {
        async sendForm() {
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                switch (this.defineForm.section_type) {
                    case 1: return this.selectSection();
                    case 2: return this.selectSectionDetail();
                }
            }
        },
        selectSection() {
            this.processing = true
            let formData = new FormData();
            formData.append('file', this.defineForm.file[0])

            SectionService.importSectionAsExcel(this.defineForm.semester_id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    this.$emit('successProcess')
                })
                .catch(e => {
                    this.showErrors(e, this.$refs.importForm)
                })
                .finally(() => {
                    this.processing = false
                })
        },
        selectSectionDetail() {
            this.processing = true
            let formData = new FormData();
            formData.append('file', this.defineForm.file[0])
            formData.append('semester_id', this.defineForm.semester_id)

            SectionService.importSectionDetailAsExcel(formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    this.$emit('successProcess')
                })
                .catch(e => {
                    this.showErrors(e, this.$refs.importForm)
                })
                .finally(() => {
                    this.processing = false
                })
        },
        download(code) {
            excelImportTemplate.downloadTemplate(code)
                .then(response => {
                    this._downloadFile(response, code + '.xlsx')
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
    }
}
</script>
