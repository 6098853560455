<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('certificate_centers')"
                        :isColumns="true"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('certificate_centers')"
                              :isColumns="true"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                    <div class="mb-1" v-for="(column,key) in datatable.columns">
                        <b-form-checkbox
                            :id="'checkbox-'+key"
                            :name="'checkbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </HeaderMobile>
            </template>

            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('event_name')">
                            <b-form-input v-model="datatable.queryParams.filter" type="number"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('event_format')">
                            <parameter-selectbox v-model="datatable.queryParams.filter" code=""></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input v-model="datatable.queryParams.filter" type="date"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="6">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input v-model="datatable.queryParams.filter" type="date"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="showModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
                         v-if="checkPermission('certificatecenter_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                         v-if="checkPermission('certificatecenter_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

//Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

//Page
import ShowForm from './ShowForm'

//Service
import CertificateCenterService from '@/services/CertificateCenterService';

//Other
import qs from 'qs';

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,

        ShowForm,
    },
    metaInfo() {
        return {
            title: this.$t('certificate_centers')
        }
    },
    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        hidden: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                // permission: 'certificatecenter_show',
                                callback: (row) => {
                                    this.showForm(row.id);
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                // permission: 'certificatecenter_delete',
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('club_name'),
                        field: 'club_name',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_name'),
                        field: 'event_name',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_format'),
                        field: 'event_format',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_type'),
                        field: 'event_type',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('speaker'),
                        field: 'speaker',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_start_date'),
                        field: 'event_start_date',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_end_date'),
                        field: 'event_end_date',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('event_location'),
                        field: 'event_location',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('number_of_participants'),
                        field: 'number_of_participants',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('external_participation'),
                        field: 'external_participation',
                        hidden: false
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return CertificateCenterService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        delete(id) {
            this.deleteModal(() => {
                CertificateCenterService.deleteCertificate(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
    }
}
</script>
