<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                   <b-form-group :label="$t('name')">
                       <ValidationProvider
                           name="name"
                           rules="required"
                           v-slot="{ valid, errors }"
                       >
                           <b-input-group class="mb-3" prepend="TR">
                               <b-form-input
                                   v-model="form.name"
                                   :class="errors[0] ? 'is-invalid' : ''"
                               >
                               </b-form-input>
                           </b-input-group>
                           <b-form-invalid-feedback
                               v-if="errors[0]"
                               v-html="errors[0]"
                               class="mb-2"
                           ></b-form-invalid-feedback>
                       </ValidationProvider>
                       <ValidationProvider
                           name="name_en"
                           v-slot="{ valid, errors }"
                       >
                           <b-input-group prepend="EN">
                               <b-form-input
                                   v-model="form.name_en"
                                   :class="errors[0] ? 'is-invalid' : ''"
                               >
                               </b-form-input>
                           </b-input-group>
                           <b-form-invalid-feedback
                               v-if="errors[0]"
                               v-html="errors[0]"
                           ></b-form-invalid-feedback>
                       </ValidationProvider>
                   </b-form-group>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="ticket_event_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('ticket_event')">
                            <ticket-event-selectbox v-model="form.ticket_event_id"
                                                    :validate-error="errors[0]" code="ticket_types" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox v-model="form.type"
                                                 :validate-error="errors[0]" code="ticket_types"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('ticket_count')">
                        <ValidationProvider name="ticket_count" rules="" v-slot="{ errors }">
                            <b-form-input v-model="form.ticket_count"
                                          :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('price')">
                        <ValidationProvider name="price" rules="" v-slot="{ errors }">
                            <b-form-input v-model="form.price"
                                          :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('min_ticket_count')">
                        <ValidationProvider name="min_ticket_count" rules="" v-slot="{ errors }">
                            <b-form-input v-model="form.min_ticket_count"
                                          :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('max_ticket_count')">
                        <ValidationProvider name="max_ticket_count" rules="" v-slot="{ errors }">
                            <b-form-input v-model="form.max_ticket_count"
                                          :state="errors[0] ? false : null"/>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2" :disabled="formLoading">
                {{ $t('save').toLocaleUpperCase('tr-TR') }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import TicketEventTypeService from "@/services/TicketEventTypeService";
    import TicketEventSelectbox from "@/components/interactive-fields/TicketEventSelectbox.vue";

    export default {
        components: {
            TicketEventSelectbox,
            ValidationProvider, ValidationObserver,
        },
        data() {
            return {
                formLoading: false,
                form: {
                    ticket_event_id: null,
                    name: null,
                    name_en: null,
                    type: null,
                    ticket_count: null,
                    price: null,
                    min_ticket_count: null,
                    max_ticket_count: null
                }
            }
        },
        methods: {
            async createForm() {
                if(this.checkPermission('ticketeventtype_store')){
                    const isValid = await this.$refs.formModalValidate.validate()
                    if (isValid) {
                        this.formLoading = true;
                        TicketEventTypeService.store(this.form)
                                      .then((response) => {
                                          this.$toast.success(this.$t("api." + response.data.message));
                                          this.$emit("createFormSuccess")
                                      })
                                      .catch((error) => {
                                          this.showErrors(error, this.$refs.formModalValidate)
                                      }).finally(() => {
                            this.formLoading = false;
                        });
                    }
                } else {
                    this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
                }

            }
        }
    }
</script>
