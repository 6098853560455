const routes = [
    {
        path: "/tickets/:ticket_event_id",
        component: () => import("../pages/Index"),
    },
    {
        path: "/purchase/tickets/:lang?",
        component: () => import("../pages/PurchaseTicket"),
    },
    {
        path: "/ticket/success/message",
        component: () => import("../pages/TicketSuccessMessage.vue"),
    },
    {
        path: "/success/message",
        component: () => import("../pages/SuccessMessage.vue"),
    },
];

export default routes;
