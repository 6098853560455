<template style="background-color: #EAEAEA">
  <div>
    <div class="container py-5 my-0 my-sm-5 px-4">
      <div>
        <h2>{{ $t('purchase_successful').toUpper() }}</h2>
        <h6>{{ $t('ticket_success_message') }}</h6>
      </div>
      <div class="row">
        <div class="col-12">
          <b-card class="mt-2">
            <b-card-header class="bg-white">
              <h5>{{ $t('purchased_tickets').toUpper() }} ({{ buyTickets?.tickets?.length }}) </h5>
            </b-card-header>
            <b-card-body>
              <div class="row">
                <div v-for="(ticket, index) in buyTickets.tickets" :key="index" class="col-md-6 mb-4">
                  <div class="ticket-card">
                    <div class="ticket-icon">
                      <i class="ri-coupon-2-fill"></i>
                    </div>
                    <div class="ticket-details">
                      <p class="name">{{ ticket.name }} {{ ticket.surname }}</p>
                      <p class="event">{{ $t('future_ai_summit').toUpper() }}</p>
                    </div>
                    <div class="ticket-action">
                      <button class="download-button" @click="downloadTicket(ticket.id)">
                        <div class="ticket-download-icon">
                          <i class="ri-download-2-fill"></i>
                        </div>
                        <span>{{ $t('download_ticket').toUpper() }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketEventApplicationService from "@/services/TicketEventApplicationService";

export default {
  metaInfo() {
    return {
      title: this.$t("purchased_tickets"),
    };
  },
  data() {
    return {}
  },
  computed: {
    buyTickets() {
      let data = JSON.parse(localStorage.getItem("buyTickets"))
      return JSON.parse(data);
    },
    secretData() {
      let data = JSON.parse(localStorage.getItem("secretData"))
      return JSON.parse(data);
    }
  },
  methods: {
    downloadTicket(ticketId) {
      const ticket = this.buyTickets.tickets.find(ticket => ticket.id === ticketId)
      let formData = {
        ticket_event_application_ticket_id: ticketId,
        secret: this.secretData,
      }
      TicketEventApplicationService.ticketDownload(formData)
          .then((response) => {
            this._downloadFile(response, this.$t(`${ticket.name} ${ticket.surname}.pdf`))
          })
          .catch(e => {
            this.showErrors(e, null, true)
          })
    }
  },
}
</script>

<style scoped>
.ticket-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
}

.ticket-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-icon i {
  font-size: 60px;
  color: #f653fb;
}

.ticket-details {
  flex: 1;
  margin: 0 20px;
  min-width: 200px;
}

.ticket-details .name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.ticket-details .event {
  font-size: 14px;
  color: #555;
}

.ticket-action {
  flex-shrink: 0;
  margin-top: auto;
  text-align: center;
}

.download-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
  padding: 0;
  max-width: 100%;
}

.ticket-download-icon i {
  font-size: 30px;
  color: grey;
}

.download-button:hover i {
  color: #000;
}

.download-button:hover span {
  color: #000;
}
</style>

