import store from '@/plugins/Store'

const routes = [
    {
        path: "/turkmer/login",
        component: () => import("../pages/Login.vue"),
    },
    {
        path: "/turkmer/register",
        component: () => import("../pages/Register.vue"),
    },
    {
        path: "/turkmer/logout",
        component: () => {
            store.dispatch("turkmerApplication/logout");
        },
    },
    {
        path: "/turkmer/forgot-password",
        component: () => import("../pages/ForgotPassword.vue"),
    },
    {
        path: "/turkmer",
        component: () => import("../../turkmerApplicationForm/pages/ApplicationForm.vue"),
        meta: {
            isTurkmerLogin: true,
        },
    },
];

export default routes;
