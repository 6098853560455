<template>
    <div>
        <b-row>
            <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="mt-2">
                <b-card>
                    <b-row>
                        <b-col md="12" v-if="checkPermission('ects_programcoordinatorstoreweeklycourseplan')">
                            <b-button variant="primary" @click="newCoursePlan">{{ $t('new') }}</b-button>
                        </b-col>
                        <b-col md="12">
                            <b-table
                                :empty-filtered-text="$t('no_result')"
                                :empty-text="$t('no_result')"
                                bordered
                                striped
                                responsive
                                :items="tableData"
                                :fields="tableFields"
                                show-empty
                                class="mb-5 mt-2 table-dropdown no-scrollbar border rounded">
                                <template #cell(buttons)="row">
                                    <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <div id="dropdownListHead">
                                            <b-dropdown-item v-if="checkPermission('ects_programcoordinatorupdateweeklycourseplan')" @click="openUpdateCoursePlan(row.item)">
                                                <i class="ri-edit-box-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('edit')}}
                                            </b-dropdown-item>
                                            <b-dropdown-item v-if="checkPermission('ects_programcoordinatordeleteweeklycourseplan')" @click="deleteCoursePlan(row.item)">
                                                <i class="ri-delete-bin-2-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('delete')}}
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components

// Services
import EctsService from "@/services/EctsService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";
import ProgramOutcome from "@/services/ProgramOutcome";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        course: {
            type: Object,
        },
        forceRefresh: {
            type: Boolean,
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            tableData: [],
            tableFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: 'week',
                    label: this.$t('week')
                },
                {
                    key: 'subject',
                    label: this.$t('subject')
                },
                {
                    key: 'subject_en',
                    label: this.$t('subject')+' (EN)'
                },
                {
                    key: 'preliminary',
                    label: this.$t('preliminary_course_material')
                },
                {
                    key: 'preliminary_en',
                    label: this.$t('preliminary_course_material')+' (EN)'
                },
            ],

            newFormData: {},
        };
    },
    watch: {
        forceRefresh: {
            handler(value) {
                if (value === true) {
                    this.getData()
                }
            }
        }
    },
    created() {
        this.formData = this.course
        this.getData()
    },
    methods: {
        getData(){
            EctsService.getProgramCoordinatorWeeklyCoursePlans(this.course.id).then(response => {
                this.tableData = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        newCoursePlan() {
            this.course.actionType = "new"
            this.$emit('openComponent',this.course, 'weekly-course-plan-form')
        },

        openUpdateCoursePlan(data){
            this.course.actionType = "update"
            data.code = this.course.code
            data.name = this.course.name
            data.language = this.course.language
            this.$emit('openComponent', data, 'weekly-course-plan-form')
        },

        deleteCoursePlan(data){
            this.$swal.fire({
                    title: this.$t('attention'),
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then((result) => {
                if (result.isConfirmed) {
                    EctsService.deleteProgramCoordinatorWeeklyCoursePlan(data.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getData()
                    }).catch(e => {
                        this.showErrors(e)
                    })
                }
            })
        }

    },
}
</script>
