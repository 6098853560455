<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :disabled="disabled"
        >
            <template
                slot="selection"
                slot-scope="{ values, isOpen }"
                v-if="multiple"
            >
                <span
                    class="multiselect__single"
                    v-if="values.length && !isOpen"
                >
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t("no_options") }}</span>
            <span slot="noResult">{{ $t("no_result") }}</span>
        </multiselect>
        <span
            class="invalid-feedback-custom"
            v-show="validateError"
            v-html="validateError"
        />
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import ParameterService from "@/services/ParameterService";

// Other
import qs from "qs";

export default {
    props: {
        code: {
            type: String,
        },
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        multipleReturnType: {
            default: "array",
        },
        multipleShowCount: {
            default: 2,
        },
        validateError: {
            type: String,
            default: "",
        },
        sort: {
            type: String,
            default: "name",
        },
        filterName: {
            type: String,
            default: null,
        },
        isFilterNameRequired: {
            type: Boolean,
            default: false,
        },
        filterOtherUrl: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        public: {
            type: Boolean,
            default: false,
        },
        withoutItems: {
            type: String,
            default: null,
        },
        valueType: {
            default: "code",
        },
        textType: {
            default: null,
        },
        isParameterData:{
            type:Boolean,
            default: false,
        }
    },
    watch: {
        value: function () {
            this.selected = this.setSelected(
                this.value,
                this.options,
                this.multiple,
                this.multipleReturnType
            );
        },
        filterName: function (newValue, oldValue) {
            if (newValue && newValue != oldValue) {
                this.getOptions();
            } else {
                this.options = [];
            }
        },
        withoutItems: function (newValue) {
            this.withoutOptions = newValue.split(",");
            this.getOptions();
        },
    },
    data() {
        return {
            selected: null,
            options: [],
            withoutOptions: [],
        };
    },
    created() {
        if (this.withoutItems) {
            this.withoutOptions = this.withoutItems.split(",");
        }
        this.getOptions();
    },
    methods: {
        translateNSelected: translateNSelected,
        setSelected: setSelected,
        handleInput: handleInput,
        getOptions() {
            const key =
                "parameter_" +
                this.code +
                "_" +
                (this.public ? "public" : "not_public");

            let local = this.getWithExpiry(key);
            if (local) {
                if (this.public) {
                    this.setPublicOptions(local);
                } else {
                    this.setNonPublicOptions(local);
                }
                this.selected = this.setSelected(
                    this.value,
                    this.options,
                    this.multiple,
                    this.multipleReturnType
                );
                return;
            }
            if (!this.public) {
                this.options = [];
                if (this.filterOtherUrl) {
                    if (this.isFilterNameRequired && !this.filterName) {
                        return;
                    }
                    const config = {
                        params: {
                            filter: {
                                parameter_code: this.code,
                                name: this.filterName,
                            },
                            sort: this.sort,
                            limit: -1,
                        },
                        paramsSerializer: (params) =>
                            qs.stringify(params, { encode: false }),
                    };
                    ParameterService.getAllItems(config)
                        .then((response) => {
                            const data = response.data.data;
                            this.setWithExpiry(key, data);
                            this.setPublicOptions(data);
                        })
                        .then(() => {
                            this.selected = this.setSelected(
                                this.value,
                                this.options,
                                this.multiple,
                                this.multipleReturnType
                            );
                        });
                } else {
                    const config = {
                        params: {
                            sort: this.sort,
                            limit: -1,
                        },
                        paramsSerializer: (params) =>
                            qs.stringify(params, { encode: false }),
                    };

                    ParameterService.getItems(this.code, config)
                        .then((response) => {
                            const data = response.data.data;
                            this.setWithExpiry(key, data);
                            this.setNonPublicOptions(data);
                        })
                        .then(() => {
                            this.selected = this.setSelected(
                                this.value,
                                this.options,
                                this.multiple,
                                this.multipleReturnType
                            );
                        });
                }
            } else {

                this.options = [];
                const config = {
                    params: {
                        parameter_code: this.code,
                        limit: -1,
                    },
                    paramsSerializer: (params) =>
                        qs.stringify(params, { encode: false }),
                };
                ParameterService.getItemsPublic(config)
                    .then((response) => {

                        const data = response.data.data;

                        if (data) {
                            data.map((item) => {

                                if (!this.withoutOptions.includes(item.code)) {
                                    let text = this.textType
                                        ? item[this.textType]
                                        : this.getLocaleText(item, "name");
                                    this.options.push({
                                        value: item[this.valueType],
                                        text: text,
                                    });
                                }
                            });
                        }
                    })
                    .then(() => {
                        this.selected = this.setSelected(
                            this.value,
                            this.options,
                            this.multiple,
                            this.multipleReturnType
                        );
                    });
            }
        },
        setPublicOptions(data) {
            if (data) {
                if (this.isParameterData){
                    this.$emit('data', data);
                }
                this.options = [];

                data.map((item) => {
                    if (!this.withoutOptions.includes(item.code)) {
                        let text = this.textType
                            ? item[this.textType]
                            : this.getLocaleText(item, "name");
                        this.options.push({
                            value: item[this.valueType],
                            text: text,
                        });
                    }
                });
            }
        },
        setNonPublicOptions(data) {
            if (this.isParameterData){
                this.$emit('data', data);
            }
            this.options = [];
            if (data && data.items) {
                data.items.map((item) => {
                    if (!this.withoutOptions.includes(item.code)) {
                        let text = this.textType
                            ? item[this.textType]
                            : this.getLocaleText(item, "name");
                        this.options.push({
                            value: item[this.valueType],
                            text: text,
                        });
                    }
                });
            }
        },
    },
};
</script>
