<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file v-model="defineForm.file"
                                                     :state="errors[0] ? false : (valid ? true : null)"
                                                     :placeholder="$t('select_file')"
                                                     :drop-placeholder="$t('drop_file')"
                                                     ref="fileInput"
                                                     multiple
                                        />
                                        <b-button variant="outline-secondary"
                                                  @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                        </b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
<!--                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-table class="mt-2 mb-2" borderless small bordered responsive thead-tr-class="table-success" :items="tableItems" :fields="tableFields"></b-table>
                        </div>-->

                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                            <b-button @click="download('students_excel_import')" variant="outline-secondary" class="mr-2">{{ $t('download_excel_template') }}</b-button>
                            <b-button variant="primary"
                                      @click="sendForm"
                                      label="import"
                            />
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
// Component


// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import JobService from "@/services/JobService";
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
    components: {

        ValidationProvider,
        ValidationObserver
    }
    ,
    data() {
        return {
            defineForm: {
                semester_id: null,
                file: null
            },
            loading: false,
            tableFields: [
                {
                    key: 'academic_year',
                    label: this.$t('sample_template_excel'),
                    tdClass: 'border',
                },
                {
                    key: 'student_number_long',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'name',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'surname',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'registration_date_just',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'registration_type',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'program_code',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'class',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'program_code',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'registration_academic_year',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'mobile_number',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'gender',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'username',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'email_address',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'country',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'city',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'address',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'yoksis_unit_id',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'scholarship_code',
                    label: '',
                    tdClass: 'border',
                },  {
                    key: 'osym_preference_code',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'student_status',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'leaving_academic_year',
                    label: '',
                    tdClass: 'border',
                },{
                    key: 'leaving_semester',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'working_end_date',
                    label: '',
                    tdClass: 'border',
                },{
                    key: 'reason_for_leaving',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'major_type',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'father_name',
                    label: '',
                    tdClass: 'border',
                },
                {
                    key: 'mother_name',
                    label: '',
                    tdClass: 'border',
                },

            ],
            tableItems: [
                {
                    academic_year: this.$t('national_id'),
                    student_number_long: this.$t('student_number_long'),
                    name: this.$t('name'),
                    surname: this.$t('surname'),
                    registration_date_just: this.$t('registration_date_just'),
                    registration_type: this.$t('registration_type'),
                    program_code: this.$t('program_code'),
                    class: this.$t('class'),
                    registration_academic_year: this.$t('registration_academic_year'),
                    semester: this.$t('semester'),
                    mobile_number: this.$t('mobile_number'),
                    gender: this.$t('gender'),
                    username: this.$t('username'),
                    email_address: this.$t('email_address'),
                    country: this.$t('country'),
                    city: this.$t('city'),
                    address: this.$t('address'),
                    yoksis_unit_id: this.$t('yoksis_unit_id'),
                    scholarship_code: this.$t('scholarship_code'),
                    scholarship_rate: this.$t('scholarship_rate'),
                    osym_preference_code: this.$t('osym_preference_code'),
                    student_status: this.$t('student_status'),
                    leaving_academic_year: this.$t('leaving_academic_year'),
                    leaving_semester: this.$t('leaving_semester'),
                    working_end_date: this.$t('working_end_date'),
                    reason_for_leaving: this.$t('reason_for_leaving'),
                    major_type: this.$t('major_type'),
                    father_name: this.$t('father_name'),
                    mother_name: this.$t('mother_name'),
                },

            ]
        }
    }
    ,
    methods: {
        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])
                return JobService.fileImport(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        this.$emit('successProcess',true)
                    })
                    .catch(e => {
                        this.showErrors(e,this.$refs.updateForm)
                    })
            }
        },
        download(code) {
            excelImportTemplate.downloadTemplate(code)
                .then(response => {
                    this._downloadFile(response, code + '.xlsx')
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
    }
}
</script>
