<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('price_simulation')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('price_simulation')"
                              :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row" v-if="faculties">
                <div class="col-12">
                    <b-form-group :label="$t('period')">
                        <payment-periods-selectbox v-model="form.period_id">
                        </payment-periods-selectbox>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Mart Erken İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[3].early_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Mart Peşin İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[3].cash_discount"></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-3">
                    <b-form-group label="Nisan Erken İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[4].early_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Nisan Peşin İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[4].cash_discount"></b-form-input>
                    </b-form-group>
                </div>

                <div class="col-3">
                    <b-form-group label="Mayıs Erken İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[5].early_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Mayıs Peşin İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[5].cash_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Haziran Erken İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[6].early_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Haziran Peşin İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[6].cash_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Temmuz Peşin İndirim Oranı">
                        <b-form-input type="number" v-model="form.month[7].cash_discount"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12">
                    <table class="table table-bordered">
                        <tr>
                            <th class="width-30"></th>
                            <th>{{$t('faculty')}}</th>
                            <th>{{$t('raise_rate')}}</th>
                        </tr>
                        <tr v-for="faculty in faculties">
                            <td><b-form-checkbox v-model="faculty.is_checked"></b-form-checkbox></td>
                            <td>{{faculty.code}} - {{faculty.name}}</td>
                            <td><b-form-input type="number" v-model="faculty.rate"/></td>
                        </tr>
                    </table>
                </div>
                <div class="col-12 mt-3 d-flex">
                    <b-button @click="create">Ücretleri Oluştur</b-button>
                    <b-button @click="run" class="ml-2">Simülasyonu Çalıştır</b-button>
                </div>
            </div>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import qs from 'qs'
import PriceService from "@/services/SimulationPriceService";
import FacultyService from "@/services/FacultyService";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox.vue";

export default {
    components: {
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('price_simulation')
        }
    },
    data() {
        return {
            id: 0,
            form: {
                month:[]
            },
            options_currency: [
                {value: 'TRY', text: this.$t('TRY')},
                {value: 'USD', text: this.$t('USD')},
                {value: "EUR", text: this.$t('EUR')},
            ],
            options_customer_group: [
                {value: 'TR', text: this.$t('TR')},
                {value: 'YU', text: this.$t('YU')},
            ],
            semesters: [
                {value: '1', text: this.$t('fall')},
                {value: '2', text: this.$t('spring')},
            ],
            faculties: null
        }
    },
    methods: {
        create(){
            if(!this.form.period_id){
                return;
            }
            let faculties=[];
            this.faculties.forEach(function(item){
                if(item.rate>0){
                    faculties.push({code: item.code, rate: item.rate});
                }
            });
            if(!faculties.length){
                return;
            }

            this.form.faculties = faculties;

            PriceService.create(this.form).then(response=>{
                this.showMessage(response);
            });
        },
        run(){
            if(!this.form.period_id){
                return;
            }

            let faculties=[];
            this.faculties.forEach(function(item){
                if(item.is_checked){
                    faculties.push(item.code);
                }
            });
            if(!faculties.length){
                return;
            }

            let formData = new FormData();
            formData.append('period_id', this.form.period_id);
            faculties.forEach((item) => formData.append("faculty_code[]", item));

            PriceService.run(formData).then(response=>{
                this.$router.push('/job/logs/detail/'+response.data.data.log_id)
            });
        }
    },
    created() {
        const config = {
            params: {
                filter: {
                    level: 'L,OL',
                },
                sort: 'code',
                limit: -1
            },
            paramsSerializer: (params) => qs.stringify(params, {encode: false})
        };
        FacultyService.getAll(config).then(response=>{
            this.form.month=[];
            this.form.month[3]={early_discount: 0, cash_discount: 0};
            this.form.month[4]={early_discount: 0, cash_discount: 0};
            this.form.month[5]={early_discount: 0, cash_discount: 0};
            this.form.month[6]={early_discount: 0, cash_discount: 0};
            this.form.month[7]={early_discount: 0, cash_discount: 0};
            this.faculties = response.data.data;
        });
    },
    watch: {
        'form.period_id'(newVal){
            PriceService.created({params:{period_id: newVal}}).then(response=>{
                if(response.data.data.discounts){
                    let month=[];
                    response.data.data.discounts.forEach(item=>{
                        this.form.month[item.month]={early_discount: item.early_discount_rate, cash_discount: item.cash_discount_rate};
                    })
                    //this.$set(this.form, 'month', month);
                }


                if(response.data.data.rates){
                    response.data.data.rates.forEach(item=>{
                        this.faculties.forEach(faculty=>{
                            if(faculty.code==item.code){
                                faculty.rate=item.rate;
                            }
                        })
                    })
                }
            });
        }
    }
};
</script>

