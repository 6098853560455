import Base from '@/plugins/Base';

const module = {
    namespaced: true,
    state: () => ({
        data: [],
        data_event_id:null,
        data_secret: null,
        data_tickets: [],
    }),
    getters: {
        getData() {
            return JSON.parse(Base.LocalStorage.get('selectedTicket'))??[]
        },
        getEventId(state) {
            return state.data_event_id
        },
        getSecret(state) {
            return state.data_secret
        },
        getTickets(state) {
            return state.data_tickets
        },
    },
    mutations: {
        setData(state, data) {
            state.data = data
            Base.LocalStorage.set('selectedTicket', JSON.stringify(data))
        },
        setEventData(state, data) {
            state.data_event_id = data
        },
        setSecretData(state, data) {
            state.data_secret = data
            Base.LocalStorage.set('secretData', JSON.stringify(data))
        },
        setTicketsData(state, data) {
            state.data_tickets = data
            Base.LocalStorage.set('buyTickets', JSON.stringify(data))
        },
    },
    actions: {
      async  initData({commit},data) {
            await  commit('setData', data)
        },
        async  initEventId({commit},data) {
            await  commit('setEventData', data)
        },
        async  initSecret({commit},data) {
            await  commit('setSecretData', data)
        },
        async  initTickets({commit},data) {
            await  commit('setTicketsData', data)
        },
        async  deleteData() {
            if (Base.LocalStorage.get('selectedTicket')) {
               await Base.LocalStorage.remove('selectedTicket')
            }
        },
        async  deleteTicketData() {
            if (Base.LocalStorage.get('buyTickets')) {
                await Base.LocalStorage.remove('buyTickets')
            }
        },
    }
}

export default module;
