<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ects_coordinator_course_definitions')"
                        :is-filter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ects_coordinator_course_definitions')"
                              :is-filter="false" />
            </template>
            <b-row v-for="(course, index) in courses" :key="index">
                <b-col md="12">
                    <b-button variant="link"
                              v-b-toggle="`course-${index}`"
                    ><b>{{course.code+' - '+getLocaleText(course, 'name')}}</b></b-button>
                    <b-collapse :id="`course-${index}`" visible accordion="my-accordion">
                        <b-col md="12" class="ml-4" v-if="checkPermission('ects_updatecoursebycoordinator')">
                            <b-button variant="link" @click="openDefinitions(course, 'update-form')"><span class="text-black-50">{{ '1. '+$t('ects_course_definitions') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('ects_coursecoordinatorweeklycourseplans')">
                            <b-button variant="link" @click="openDefinitions(course, 'weekly-course-plan')"><span class="text-black-50">{{ '2. '+$t('weekly_course_plan') }}</span></b-button>
                        </b-col>
                        <b-col md="12" class="ml-4" v-if="checkPermission('ects_coursecoordinatorcourseassessments')">
                            <b-button variant="link" @click="openDefinitions(course, 'course-assessment')"><span class="text-black-50">{{ '3. '+$t('ects_course_assessment') }}</span></b-button>
                        </b-col>
                    </b-collapse>
                </b-col>
            </b-row>

            <CommonModal ref="updateModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="closeDefinitions">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="courseData" :course="courseData" @closeModal="closeDefinitions" :is="isPage" @openUpdate2Modal="openUpdate2Modal" :forceRefresh="forceRefresh"></component>
                </template>
            </CommonModal>
            <CommonModal ref="update2Modal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="closeUpdateModal">
                <template v-slot:CommonModalTitle>
                    {{ update2ModalTitle }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="courseUpdateData" :course="courseUpdateData" @closeModal="closeUpdateModal('full')" :is="isUpdatePage"></component>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import CommonModal from "@/components/elements/CommonModal"
// Pages

// Services
import EctsService from "@/services/EctsService"

// Others

// Pages
import UpdateForm from "./UpdateForm"
import WeeklyCoursePlan from "@/modules/ects/pages/coordinator-courses/WeeklyCoursePlan"
import WeeklyCoursePlanForm from "@/modules/ects/pages/coordinator-courses/WeeklyCoursePlanForm"
import CourseAssessment from "./CourseAssessment"
import CourseAssessmentForm from "./CourseAssessmentForm"


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        UpdateForm,
        WeeklyCoursePlan,
        WeeklyCoursePlanForm,
        CourseAssessment,
        CourseAssessmentForm
    },
    metaInfo() {
        return {
            title: this.$t('ects_coordinator_course_definitions')
        }
    },
    data() {
        return {
            courses: null,
            courseData: null,
            updateModalTitle: null,
            isPage: null,
            courseUpdateData: null,
            isUpdatePage: null,
            update2ModalTitle: null,
            forceRefresh: false
        }
    },
    created() {
        this.getCourses()
    },
    methods: {
        getCourses(){
            EctsService.getAll().then(response => {
                this.courses = response.data.data
            }).catch(e => {
                this.showErrors(e)
            })
        },

        openDefinitions(course, page){
            this.isPage = page
            this.courseData = course
            this.updateModalTitle = course.code+' - '+this.getLocaleText(course, 'name')
            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeDefinitions(){
            this.isPage = null
            this.courseData = null
            this.$refs.updateModal.$refs.commonModal.hide()
        },

        openUpdate2Modal(data, component){
            if(component === undefined){
                this.isUpdatePage = 'weekly-course-plan-form'
            } else {
                this.isUpdatePage = component
            }

            this.courseUpdateData = data
            this.update2ModalTitle = data.actionType == 'new' ? this.$t('new') : this.$t('edit')
            this.$refs.update2Modal.$refs.commonModal.show()
            this.forceRefresh = false
        },

        closeUpdateModal(mode){
            this.isUpdatePage = null
            this.courseUpdateData = null
            this.$refs.update2Modal.$refs.commonModal.hide()
            if(mode == 'full'){
                this.forceRefresh = true
            }

        },
    }
}
</script>

