const routes = [
    {
        path: "/ticket/tracker",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:"ticketeventapplication_index"
        }
    },
    {
        path:"/ticket/tracker/detail/:id",
        component:()=> import("../pages/detail/Index"),
        meta:{
            isAuthenticated:true,
            permission:"ticketeventapplication_ticketinformation"
        }
    }
];

export default routes;
