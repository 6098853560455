<template>
    <div>
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('name')">
                        <b-input-group prepend="TR">
                            <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                        </b-input-group>
                        <b-input-group prepend="EN">
                            <div class="label-as-input">{{ isNotNullValue(form.name_en) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                        <b-form-group :label="$t('ticket_event')">
                            <ticket-event-selectbox v-model="form.ticket_event_id"
                                                    :disabled="true" code="ticket_types" />
                        </b-form-group>
                </b-col>
                <b-col cols="12">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox v-model="form.type"
                                                 :disabled="true" code="ticket_types"></parameter-selectbox>
                        </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('ticket_count')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.ticket_count) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('price')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.price) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('min_ticket_count')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.min_ticket_count) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('max_ticket_count')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(form.max_ticket_count) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
    </div>
</template>

<script>
    //Service
    import TicketEventTypeService from "@/services/TicketEventTypeService";
    import TicketEventSelectbox from "@/components/interactive-fields/TicketEventSelectbox.vue";

    export default {
        components: {TicketEventSelectbox},
        data() {
            return {
                formLoading: false,
                form: {
                    ticket_event_id: null,
                    name: null,
                    name_en: null,
                    type: null,
                    ticket_count: null,
                    price: null,
                    min_ticket_count: null,
                    max_ticket_count: null
                }
            }
        },
        props: {
            formId: {
                type: Number,
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                TicketEventTypeService.get(id)
                              .then((response) => {
                                  this.form = response.data.data;
                              })
                              .catch((error) => {
                                  this.showErrors(error)
                              })
            }
        }
    }
</script>
