<template>
    <div>
        <page-header :title="$t('yoksis_preparatory').toUpper()"
                     @filter-div-status="datatable.filterStatus=$event"
                     :isFilter="false"
                     :isColumns="true">
            <template v-slot:columns>
                <div class="mb-1" v-for="(column,i) in datatable.columns" :key="i">
                    <b-form-checkbox
                        :id="'checkbox-'+i"
                        :name="'checkbox-'+i"
                        :value="false"
                        :unchecked-value="true"
                        v-model="column.hidden"
                        v-if="column.field!='buttons'"
                    >
                        {{ br2space(column.label) }}
                    </b-form-checkbox>
                </div>
            </template>
        </page-header>
    <b-button class="mb-3" @click="sendPrepInfo">{{ $t('preparatory_information_send') }}</b-button>
        <datatable :rows="datatable.rows"
                   :columns="datatable.columns"
                   :query-params="datatable.queryParams"
                   v-show="datatable.showTable"
                   :is-loading="datatable.isLoading"
                   :showPaginate="false"
                   @on-page-change="onPageChange"
                   @on-per-page-change="onPerPageChange"
                   :title="''"
        />
    </div>
</template>
<script>
    // Template
    import PageHeader from '@/components/page/PageHeader';

    // Component
    import Datatable from '@/components/datatable/Datatable';
    // Pages

    // Services
    import YoksisService from "@/services/YoksisService";

    // Other

    export default {
        components: {
            PageHeader,
            Datatable,
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                form: null,
                formProcess: null,

                datatable: {
                    rows: [],
                    showTable: true,
                    isLoading: false,
                    filterStatus: true,
                    total: 0,
                    queryParams: {
                        filter: {},
                        limit: -1,
                        page: 1
                    },
                    columns: [
                        {
                            label: '',
                            html: true,
                            field: 'buttons',
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line',
                                    permission: 'studentprogram_deleteyoksisprep',
                                    callback: (row) => {
                                        this.deleteForm(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            field: 'id',
                            label: this.spaceToBR(this.toUpperCase('id')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'birimId',
                            label: this.spaceToBR(this.toUpperCase('unit_id')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'hazirlikDonemNo',
                            label: this.spaceToBR(this.toUpperCase('preparationPeriodNo')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'muafiyetDurumu',
                            label: this.spaceToBR(this.toUpperCase('exemptionStatus')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'ogretimDili',
                            label: this.spaceToBR(this.toUpperCase('instructionLanguage')),
                            hidden: false,
                            sortable: false
                        },
                        {
                            field: 'basarmaDurumu',
                            label: this.spaceToBR(this.toUpperCase('success_status')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        },
                        {
                            field: 'bitirmeTarihi',
                            label: this.spaceToBR(this.toUpperCase('graduationDate')),
                            hidden: false,
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center'
                        }
                    ]
                }
            }
        },
        mounted() {
            this.filterClear();
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows()
            },
            filterClear() {
                this.filterSet();
                this.getRows()
            },
            filterSet() {

            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                YoksisService.yokPreparatoryList(this.studentProgram.national_id).then(res => {
                    this.datatable.rows = res.data.data;
                    this.datatable.total = res.data.pagination.total;
                }).catch((e) => {
                    this.showErrors(e)
                }).finally(() => {
                    this.datatable.isLoading = false
                })
            },

            // Clear
            clearFormData() {
                this.form = null
                this.formProcess = null
            },

            async sendPrepInfo() {
                return YoksisService.yokPreparatoryUpdate(this.studentProgram.id, this.datatable.rows)
                    .then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$emit('updateFormSuccess');
                    })
                    .catch(e => {
                        this.$toast.error(this.$t('api.'+e.data.message))
                    })
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        cancelButtonText: this.$t('no'),
                        confirmButtonText: this.$t('yes')
                    })
                    .then(result => {
                        if (result.isConfirmed) {
                            YoksisService.yokPreparatoryDelete(id).then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                            }).catch(e => {
                                this.showErrors(e)
                            })
                        }
                    })
            }
        }
    }
</script>

