<template>
    <div>
        <b-row>
            <b-col cols="12" md="2">
                <b-form-group class="mb-0">
                    <b-button variant="primary" block @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import YoksisService from "@/services/YoksisService";

export default {
    methods: {
        getReport(){
            YoksisService.yokReport()
                .then(res=>this._downloadFile(res,this.$t('all_permit_yok_report')+'.xlsx'))
                .catch(err=>this.showErrors(err))
        }
    }
}
</script>
