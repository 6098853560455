<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-3">
                    <ValidationProvider
                        name="event_name"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_name')">
                            <b-form-input
                                v-model="form.event_name"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="event_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('event_type')">
                            <department-selectbox  :multiple="true"
                                                   :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="event_location"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_location')">
                            <b-form-input
                                v-model="form.event_location"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="event_format" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('event_format')">
                            <department-selectbox  :multiple="true"
                                                   :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="event_start_date"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_start_date')">
                            <b-form-input type="date"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="event_end_date"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_end_date')">
                            <b-form-input type="date"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="event_announcement"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('event_announcement')">
                            <b-form-textarea
                                v-model="form.event_announcement"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="participant_count"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('participant_count')">
                            <b-form-input
                                type="number"
                                v-model="form.participant_count"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="external_participation" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('external_participation')">
                            <department-selectbox  :multiple="true"
                                                   :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="there_is_a_protocol"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('there_is_a_protocol')">
                            <b-form-select :options="options"
                            />
                            <span class="invalid-feedback-custom"/>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="speaker_lecturer"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('speaker_lecturer')">
                            <b-form-input
                                v-model="form.konuşmacı_eğitmen"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="budget"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('budget')">
                            <b-form-input
                                v-model="form.budget"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('budget_items')">
                            <b-form-input
                                v-model="form.budget_items"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="material_requests"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('material_requests')">
                            <b-form-textarea
                                v-model="form.material_requests"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="printed_materials"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('printed_materials')">
                            <b-form-textarea
                                v-model="form.printed_materials"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="complimentary"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('complimentary')">
                            <b-form-textarea
                                v-model="form.complimentary"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="attendees_for_refreshments"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('attendees_for_refreshments')">
                            <b-form-input
                                v-model="form.attendees_for_refreshments"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="requirement_list"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('requirement_list')">
                            <b-form-textarea
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="hall_setup"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('hall_setup')">
                            <b-form-textarea
                                v-model="form.hall_setup"
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="transport_audio_video_systems"
                        rules=""
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('transport_audio_video_systems')">
                            <b-form-input
                                v-model="form.transport_audio_video_systems"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="sponsor"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('sponsor')">
                            <b-form-input
                                v-model="form.sponsor"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider name="club" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('club')">
                            <department-selectbox  :multiple="true"
                                                   :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-3">
                    <ValidationProvider
                        name="purpose"
                        rules="required"
                        v-slot="{ valid, errors }"
                    >
                        <b-form-group :label="$t('purpose')">
                            <b-form-textarea
                            ></b-form-textarea>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
    //Component
    //Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    //Service
    import CertificateCenterService from "@/services/CertificateCenterService";
    import LinedTextarea from "@/components/elements/LinedTextarea.vue";

    export default {
        components: {
            LinedTextarea,
            ValidationProvider, ValidationObserver,
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    code: null,
                    name: null,
                },
                options: [
                    {
                        value: 'transkript',
                        text: this.$t('yes'),
                    },
                    {
                        value: 'student-certificate',
                        text: this.$t('no'),
                    }
]
            }
        },
        watch: {
            formId: function (val) {
                this.get(val)
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CertificateCenterService.get(id)
                               .then((response) => {
                                   this.form = response.data.data;
                                   this.formLoading = false;
                               })
                               .catch((error) => {
                                   if (error.data.message) {
                                       this.$toast.error(this.$t("api." + error.data.message));
                                   }
                               });
            },
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    this.formLoading = true;
                    CertificateCenterService.update(this.formId, this.form)
                                   .then((response) => {
                                       this.formLoading=false;
                                       this.$toast.success(this.$t("api." + response.data.message));
                                       this.$emit("updateFormSuccess")
                                   })
                                   .catch((error) => {
                                       this.showErrors(error, this.$refs.formModalValidate)
                                   })
                }
            }
        }
    }
</script>
