<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_code')">
                                    {{ formData.code }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_name')">
                                    {{ getLocaleText(formData, 'name') }}
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4" lg="4">
                                <b-form-group :label="$t('course_language')">
                                    {{ formData.language }}
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col md="12">
                    <b-card>
                        <b-row>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="mode_of_delivery" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_mode_of_delivery')">
                                        <multi-selectbox
                                            v-model="formData.mode_of_delivery"
                                            :options="modeOfDeliveryOptions"
                                            :multiple="false"
                                            :validate-error="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="objectives" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_objectives')">
                                        <b-form-textarea v-model="formData.objectives"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="objectives_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_objectives')+' (EN)'">
                                        <b-form-textarea v-model="formData.objectives_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="content" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('content')">
                                        <b-form-textarea v-model="formData.content"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="content_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('content')+' (EN)'">
                                        <b-form-textarea v-model="formData.content_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="other_components" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_other_components')">
                                        <b-form-textarea v-model="formData.other_components"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="other_components_en" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_other_components')+' (EN)'">
                                        <b-form-textarea v-model="formData.other_components_en"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="notes_books" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_notes_books')">
                                        <b-form-textarea v-model="formData.notes_books"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="12">
                                <ValidationProvider name="other_resources" rules="" v-slot="{errors}">
                                    <b-form-group :label="$t('ects_other_resources')">
                                        <b-form-textarea v-model="formData.other_resources"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="6" />
                                        <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button
                    variant="primary"
                    @click="save"
                    :disabled="formLoading">
                    {{ $t("save") }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
import EctsService from "@/services/EctsService"

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        MultiSelectbox
    },
    props: {
        course: {
            type: Object,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {},
            modeOfDeliveryOptions: [
                {
                    value: "E-Öğrenme - E-Learning",
                    text: "E-Öğrenme - E-Learning"
                },
                {
                    value: "Hibrit - Hybrid",
                    text: "Hibrit - Hybrid"
                },
                {
                    value: "Yüz yüze - Face to face",
                    text: "Yüz yüze - Face to face"
                }
            ]
        };
    },
    created() {
        this.formData = this.course
    },
    methods: {
        async save(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formLoading = true
                EctsService.updateCourseByProgramCoordinator(this.course.id, this.formData).then(response => {
                    this.$toast.success(this.$t('api.'+response.data.message))
                    this.$emit('closeComponent', true)
                    this.formLoading = false
                }).catch(e => {
                    this.showErrors(e, this.$refs.formValidate)
                    this.formLoading = false
                })
            }
        }
    },
}
</script>
