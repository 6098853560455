<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('prep_reports')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('prep_reports')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <div class="mb-4">
                        <h6 class="text-uppercase mb-0">{{ $t('prep_reports') | toUpperCase }}</h6>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('report')">
                                        <multi-selectbox :multiple="false" :validate-error="errors[0]"
                                                         :options="reportOptions"
                                                         v-model="form.report">
                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==12"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="name" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="form.name"
                                                      :validateError="errors[0]">
                                        </b-form-input>
                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                             v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==12"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="surname" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="form.surname"
                                                      :validateError="errors[0]">
                                        </b-form-input>
                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                             v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="academic_year" :rules="form.report!=9&&form.report!=12?'required':''"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('academic_year')">
                                        <academic-years-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.academic_year">
                                        </academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==12"
                                class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="faculty"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.faculty">
                                        </faculty-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==12"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="program"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.program"
                                            :faculty_code="form.faculty">
                                        </program-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report!=11&&form.report!=12"
                                class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="module_id" :rules="form.report!=9&&form.report!=11?'required':''"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('module')">
                                        <module-selectbox
                                            :academic_year="form.academic_year"
                                            :validateError="errors[0]"
                                            value-type="id"
                                            v-model="form.module_id">
                                        </module-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="!['7', '8', '11','12'].includes(form.report)"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="level" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('level')">
                                        <parameter-selectbox
                                            code="prep_levels"
                                            :multiple="form.report!=10?true:false"
                                            :validateError="errors[0]"
                                            v-model="form.level">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="!['7', '8', '11'].includes(form.report)"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="class" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('class')">
                                        <parameter-selectbox
                                            code="prep_class"
                                            :multiple="form.report!=10?true:false"
                                            :validateError="errors[0]"
                                            v-model="form.class">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="!['7', '8', '12'].includes(form.report)"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="start_date" :rules="form.report==9?'required':''"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('start_date')">
                                        <select-date :validation-error="errors[0]"
                                                     v-model="form.start_date"></select-date>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==12"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="student_status"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_status')">
                                        <parameter-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.student_status"
                                            code="student_statuses">
                                            >
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="['12'].includes(form.report)"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="prep_slot_status"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('preparation_slot_status')">
                                        <parameter-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.prep_slot_status"
                                            code="preparation_slot_statuses">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="!['7', '8', '11', '12'].includes(form.report)"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="end_date" :rules="form.report==9?'required':''"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('end_date')">
                                        <select-date :validation-error="errors[0]"
                                                     v-model="form.end_date"></select-date>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-if="form.report==6"
                                class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <b-form-group :label="$t('exam_type')" v-if="['6'].includes(form.report)">
                                    <parameter-selectbox code="prep_exams" :multiple="true"
                                                         v-model="form.exam"></parameter-selectbox>
                                </b-form-group>
                            </div>
                            <div v-if="form.report==10 || form.report==12"
                                 class="col-12 col-sm-4 col-md-4 col-lg-4">
                                <ValidationProvider name="student_numbers" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('students')">
                                        <lined-textarea v-model="form.student_numbers"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '150px', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                             v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <b-form-group>
                                    <b-button variant="primary" @click="send">{{ $t('download_report') }}</b-button>
                                </b-form-group>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import PrepStudentService from "@/services/PrepStudentService";
import PrepExamNotes from "@/services/PrepExamNotes";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";
import LinedTextarea from "@/components/elements/LinedTextarea.vue";

export default {
    components: {
        LinedTextarea,
        SelectDate,
        MultiSelectbox,
        AcademicYearsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        ParameterSelectbox,
        ModuleSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('prep_reports')
        }
    },
    data() {
        return {
            form: {},
            reportOptions: [
                {value: '1', text: this.$t('all_exam_scores')},
                {value: '2', text: this.$t('exam_averages')},
                {value: '3', text: this.$t('success_rate')},
                {value: '4', text: this.$t('proficiency_exam_entry_status')},
                {value: '5', text: this.$t('prep_attendance_status')},
                {value: '6', text: this.$t('passed_exams_report')},
                {value: '7', text: this.$t('success_status_report')},
                {value: '8', text: this.$t('student_absence_report')},
                {value: '9', text: this.$t('passed_absence_report')},
                {value: '10', text: this.$t('student_detail_absence_report')},
                {value: '11', text: this.$t('placemnet_exam_report')},
                {value: '12', text: this.$t('prep_students_slot_situations')},
            ]
        }
    },
    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let reportName = this.getOptionText(this.reportOptions, this.form.report);
                if (this.form.report == '1') {
                    if (this.checkPermission('prepexamscore_report')) {
                        PrepExamNotes.scoreReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '2') {
                    if (this.checkPermission('prepexamscore_averagereport')) {
                        PrepExamNotes.averageReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '3') {
                    if (this.checkPermission('prepexamscore_successratereport')) {
                        PrepExamNotes.successRateReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '4') {
                    if (this.checkPermission('prepexam_statusreport')) {
                        PrepExamNotes.statusReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '5') {
                    if (this.checkPermission('prepattendance_statusreport')) {
                        PrepExamNotes.prepAttendanceStatusReport(this.form).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '6') {
                    if (this.checkPermission('prepexam_statusreport')) {
                        PrepExamNotes.prepExamStatusReport(this.form).then(response => {
                            this._downloadFile(response, reportName + ".xlsx");
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                } else if (this.form.report == '7') {
                    if (this.checkPermission('prepexam_successstatusreport')) {
                        const config = {
                            params: {
                                filter: this.form
                            },
                            paramsSerializer: (params) => qs.stringify(params, {encode: false})
                        }

                        delete config.params.filter['report']

                        PrepExamNotes.getSuccessStatusReport(config)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '8') {
                    if (this.checkPermission('prepstudent_attendancereport')) {
                        PrepStudentService.getAbsenceReport(this.form)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '9') {
                    if (this.checkPermission('prepattendance_instructorattendancereport')) {
                        PrepStudentService.passedAbsenceReport(this.form)
                            .then(response => {
                                this._downloadFile(response, this.$t('passed_absence_report') + '.xlsx')
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '10') {
                    if (this.checkPermission('prepattendance_instructorattendancereport')) {
                        PrepStudentService.getStudentDetailAbsenceReport(this.form)
                            .then(response => {
                                this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                } else if (this.form.report == '11') {
                    if (this.checkPermission('placementexam_excelexport')) {
                          const config = {
                            params: {
                                filter: { ...this.form }
                            },
                            paramsSerializer: (params) => qs.stringify(params, { encode: false })
                        };

                        delete config.params.filter['module_id'];
                        delete config.params.filter['report'];
                        delete config.params.filter['start_date'];
                        delete config.params.filter['prep_slot_status'];
                        delete config.params.filter['faculty'];
                        delete config.params.filter['program'];
                        delete config.params.filter['class'];
                        delete config.params.filter['student_status'];
                        delete config.params.filter['name'];
                        delete config.params.filter['surname'];
                        delete config.params.filter['level'];
                        delete config.params.filter['end_date'];
                        delete config.params.filter['exam'];

                        if (this.form['start_date']) {
                            config.params.filter['date'] = this.form['start_date'];
                        }

                        PrepExamNotes.placementExamReport(config).then(response => {
                            this._downloadFile(response, reportName);
                        }).catch(e => {
                            this.showErrors(e, this.$refs.form, true);
                        });
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }

                }else if (this.form.report == '12') {
                    if (this.checkPermission('studentprogram_prepstudentstatusreport')) {
                        const config = {
                            params: {
                                filter:{
                                    faculty_code:this.form.faculty,
                                    program_code:this.form.program_code,
                                    name:this.form.name,
                                    surname:this.form.surname,
                                    academic_year:this.form.academic_year,
                                    class:this.form.class,
                                    student_status:this.form.student_status,
                                    prep_slot_status:this.form.prep_slot_status,
                                    student_numbers: this.form.student_numbers ? this.form.student_numbers.split('\n') : []
                                }
                            },
                            paramsSerializer: (params) => qs.stringify(params, {encode: false})
                        }

                        PrepStudentService.prepStatusReport(config)
                            .then(response => {
                                this._downloadFile(response, this.$t('prep_students_slot_situations') + '.xlsx')
                            })
                            .catch(e => {
                                console.error(e);
                                this.showErrors(e, this.$refs.form, true);
                            })
                    } else {
                        this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                    }
                }
            }
        }
    }
};
</script>

