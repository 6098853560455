<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('finalize_grades')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('finalize_grades')" :is-filter="false"/>
            </template>
            <ValidationObserver ref="formValidate">
                <b-row class="mt-5">
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="process" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('process')">
                                       <multi-selectbox :multiple="false" :options="options" v-model="formData.process"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('semester')">
                                        <semesters-selectbox v-model="formData.semester_id"
                                                             :setActive="true"
                                                             :validateError="errors[0]">
                                        </semesters-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="program_level" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('program_level')">
                                        <parameter-selectbox code="program_levels" v-model="formData.program_level"
                                                             :validateError="errors[0]">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="faculty_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox v-model="formData.faculty_code"
                                                           :validateError="errors[0]">
                                        </faculty-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="department_code" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox v-model="formData.department_code"
                                                              :faculty_code="formData.faculty_code"
                                                              :validateError="errors[0]">
                                        </department-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="program" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox v-model="formData.program_code"
                                                           :department_code="formData.department_code"
                                                           :validateError="errors[0]">
                                        </program-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="class" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('class')">
                                        <parameter-selectbox code="classes" v-model="formData.class" :multiple="true"
                                                           :validateError="errors[0]">
                                        </parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" lg="6">
                                <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <multi-text v-model="formData.student_number"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <div class="d-flex mt-2">
                    <b-button variant="primary" @click="create">{{ $t('start_process').toUpper() }}</b-button>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";

import {ValidationObserver, ValidationProvider} from "vee-validate";
import StudentCourseService from "@/services/StudentCourseService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import MultiText from "@/components/interactive-fields/MultiText.vue";

export default {
    components: {
        MultiText,
        SemestersSelectbox,
        ParameterSelectbox,
        AppLayout,
        Header,

        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('finalize_grades')
        }
    },

    data() {
        return {
            formData: {},
            entryGradeObj: {
                show: this.checkPermission('studentcourse_convertigrades'),
                title: this.toUpperCase('grade_entry'),
                emit: 'gradeEntry',
                icon: 'ri-edit-2-line'
            },
            options:[
                {value:"finalize", text: "Notları Kesinleştir"},
                {value:"convert-i-grades", text: "Girilmemiş Notları I Notuna Dönüştür"},
                {value:"finalize-na-grades", text: "Na Notlarını Kesinleştir"},
            ]
        }
    },
    methods: {
        async create() {
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_start_process'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no'),
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if(this.formData.process=='finalize'){
                                StudentCourseService.finalizeGradesJob(this.formData)
                                    .then(response => {
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                        this.$router.push('/job/logs/detail/' + response.data.data.log_id);
                                    })
                                    .catch(e => {
                                        this.showErrors(e, this.$refs.formValidate);
                                    })
                            }
                            else if(this.formData.process=='convert-i-grades'){
                                StudentCourseService.convertGradeTo_I(this.formData)
                                    .then(response => {
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                        this.$router.push('/job/logs/detail/' + response.data.data.log_id);
                                    })
                                    .catch(e => {
                                        this.showErrors(e);
                                    })
                            }else if(this.formData.process=='finalize-na-grades'){
                                StudentCourseService.finalizeGradesNa(this.formData)
                                    .then(response => {
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                        this.$router.push('/job/logs/detail/' + response.data.data.log_id);
                                    })
                                    .catch(e => {
                                        this.showErrors(e);
                                    })
                            }
                        }

                    })
            }
        }
    }
};
</script>

