<template style="background-color: #EAEAEA">
    <div>
        <div class="container py-5 my-0 my-sm-5 px-4">
            <div class="row mb-2">
                <div :class="[id == 1 || id == 2 ? 'col-md-8' : 'col-md-12']">
                    <b-card>
                        <b-card-header class="bg-white">
                            <b-row>
                                <b-col md="6" class="d-flex align-items-center">
                                    <h2 style="color: #707070">{{ $t('personal_info').toUpper() }}</h2>
                                </b-col>
                                <b-col v-if="id == 1 || id == 2" md="6"
                                       class="text-right d-flex align-items-center mb-2 md-mt-0">
                                    <p class="mr-5 mb-0 white-space-nowrap">{{ $t('number_of_people') + ':' }}</p>
                                    <div class="d-flex align-items-center justify-content-between"
                                         style="border: 1px solid #ccc; border-radius: 20px; padding: 5px 10px; min-width: 90px; position: relative;">
                                        <div @click="decreaseCount" class="text-center"
                                             style="width: 30px; height: 30px; color: white; background-color: #70706f; cursor: pointer; display: flex; justify-content: center; align-items: center; border: 1px solid #ccc; border-radius: 50%; position: absolute; left: -12px;">
                                            -
                                        </div>
                                        <b-form-input
                                            style="font-weight: bold; font-size: 18px; flex-grow: 1; text-align: center;"
                                            v-model="form.ticket_count"></b-form-input>
                                        <div @click="increaseCount" class="text-center"
                                             style="width: 30px; height: 30px; color: white; background-color: #70706f; cursor: pointer; display: flex; justify-content: center; align-items: center; border: 1px solid #ccc; border-radius: 50%; position: absolute; right: -12px;">
                                            +
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-card-body>
                            <div class="row">
                                <div class="col-12">
                                    <ValidationObserver ref="personalInfoForm">
                                        <h5 v-if="id == 1 || id == 2">{{ '1. ' +  $t('certificate_persons') }}</h5>
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="name" rules="required" v-slot="{errors}">
                                                    <b-form-group :label="$t('name')">
                                                        <b-form-input v-model="form.name"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="surname" rules="required"
                                                                    v-slot="{errors}">
                                                    <b-form-group :label="$t('surname')">
                                                        <b-form-input v-model="form.surname"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="birthdate" rules="required"
                                                                    v-slot="{errors}">
                                                    <b-form-group :label="$t('birthdate')">
                                                        <b-form-input type="date"
                                                                      v-model="form.birthdate"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="gender" rules="required"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('gender')">
                                                        <gender-selectbox
                                                            v-model="form.gender"
                                                            :validateError="errors[0]"></gender-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="email" rules="required|email"
                                                                    v-slot="{errors}">
                                                    <b-form-group :label="$t('email')">
                                                        <b-form-input type="email"
                                                                      v-model="form.email"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="phone" rules="required" v-slot="{errors}">
                                                    <b-form-group :label="$t('mobile_number')">
                                                        <PhoneNumberInput
                                                            v-model="form.phone"
                                                            @update="mobileTelUpdated"
                                                            :validate-error="errors[0]"
                                                        ></PhoneNumberInput>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="country_id" rules="required"
                                                                    v-slot="{ valid, errors }">
                                                    <b-form-group>
                                                        <label>{{ $t('country') }}</label>
                                                        <country-selectbox :validateError="errors[0]"
                                                                           v-model="form.country_id"
                                                        ></country-selectbox>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <ValidationProvider name="company" rules="" v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('company_name')">
                                                        <b-form-input
                                                            v-model="form.company"
                                                            :validateError="errors[0]"></b-form-input>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-12 col-md-6" v-if="id == 3">
                                                <ValidationProvider name="file" rules="required"
                                                                    v-slot="{valid, errors}">
                                                    <b-form-group :label="$t('student_certificate')">
                                                        <div class="d-flex custom-file-upload">
                                                            <b-form-file v-model="file"
                                                                         :placeholder="$t('select_file')"
                                                                         :drop-placeholder="$t('drop_file')"
                                                                         ref="fileInput"
                                                                         accept="application/pdf,.docx"
                                                            />
                                                            <b-button variant="outline-secondary"
                                                                      @click="()=>{$refs.fileInput.$el.childNodes[0].click();}">
                                                                {{ $t('browse') }}
                                                            </b-button>
                                                        </div>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]"/>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <hr v-if="id == 1 || id == 2"
                                            style="border-top: 1px solid #ccc; margin: 20px 0;">
                                        <div v-if="(id == 1 || id == 2) && form.ticket_count > 1">
                                            <div v-for="(attendee, index) in attendees" :key="index">
                                                <h5>{{ index + 2 + '. ' +  $t('certificate_persons') }}</h5>
                                                <hr v-if="index > 0"
                                                    style="border-top: 1px solid #ccc; margin: 20px 0;">
                                                <div class="row">
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'name_' + index" rules="required"
                                                                            v-slot="{errors}">
                                                            <b-form-group :label="$t('name')">
                                                                <b-form-input v-model="attendee.name"></b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'surname_' + index" rules="required"
                                                                            v-slot="{errors}">
                                                            <b-form-group :label="$t('surname')">
                                                                <b-form-input v-model="attendee.surname"></b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'birthdate_' + index" rules="required"
                                                                            v-slot="{errors}">
                                                            <b-form-group :label="$t('birthdate')">
                                                                <b-form-input type="date"
                                                                              v-model="attendee.birthdate"></b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'gender_' + index" rules="required"
                                                                            v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('gender')">
                                                                <gender-selectbox
                                                                    v-model="attendee.gender"
                                                                    :validateError="errors[0]"></gender-selectbox>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'email_' + index" rules="required|email"
                                                                            v-slot="{errors}">
                                                            <b-form-group :label="$t('email')">
                                                                <b-form-input type="email"
                                                                              v-model="attendee.email"></b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'phone_' + index" rules="required"
                                                                            v-slot="{errors}">
                                                            <b-form-group :label="$t('mobile_number')">
                                                                <PhoneNumberInput
                                                                    v-model="attendee.phone"
                                                                    @update="mobileTelAttendeeUpdated"
                                                                    :validate-error="errors[0]"
                                                                ></PhoneNumberInput>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'country_id_' + index" rules="required"
                                                                            v-slot="{ valid, errors }">
                                                            <b-form-group>
                                                                <label>{{ $t('country') }}</label>
                                                                <country-selectbox :validateError="errors[0]"
                                                                                   v-model="attendee.country_id"
                                                                ></country-selectbox>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <ValidationProvider :name="'company_' + index" rules=""
                                                                            v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('company_name')">
                                                                <b-form-input
                                                                    v-model="attendee.company"
                                                                    :validateError="errors[0]"></b-form-input>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-12 col-md-6" v-if="id == 3">
                                                        <ValidationProvider name="file" rules="required"
                                                                            v-slot="{valid, errors}">
                                                            <b-form-group :label="$t('upload_file')">
                                                                <div class="d-flex custom-file-upload">
                                                                    <b-form-file v-model="file"
                                                                                 :placeholder="$t('select_file')"
                                                                                 :drop-placeholder="$t('drop_file')"
                                                                                 ref="fileInput"
                                                                                 accept="application/pdf,.docx"
                                                                    />
                                                                    <b-button variant="outline-secondary"
                                                                              @click="()=>{$refs.fileInput.$el.childNodes[0].click();}">
                                                                        {{ $t('browse') }}
                                                                    </b-button>
                                                                </div>
                                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                                         v-html="errors[0]"/>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                </div>
                                <b-button style="background-color: #f653fb" v-if="id == 3 || id == 4" class="d-flex justify-content-center mx-auto"
                                          @click="buyOtherTicket">{{ $t('apply') }}
                                </b-button>
                            </div>
                        </b-card-body>
                    </b-card>
                </div>
                <div v-if="id == 1 || id == 2" class="col-md-4">
                    <div class="ticket-details mb-5">
                        <div class="header">{{ $t('event_ticket_details').toUpper() }}</div>
                        <div class="content">
                            <span v-if="this.$i18n.locale === 'tr' && id == 2">
                            <p class="mt-2"><strong>- {{ form.ticket_count }} {{ $t('group_ticket_for') }}</strong>
                                <span>{{ ' ' + currencyFormat(form.totalAmount) }} x {{ form.ticket_count }}</span></p>
                            </span>
                            <span v-else-if="id == 2">
                            <p class="mt-2"><strong>{{ $t('group_ticket_for')}} - {{ form.ticket_count }} {{ $t('people') }} </strong>
                                <span>{{ ' ' + currencyFormat(form.totalAmount) }} x {{ form.ticket_count }}</span></p>
                            </span>
                            <span v-if="this.$i18n.locale === 'tr' && id == 1">
                            <p class="mt-2"><strong>- {{ form.ticket_count }} {{ $t('individual_ticket_for')}}</strong>
                                <span>{{ ' ' + currencyFormat(form.totalAmount) }} x {{ form.ticket_count }}</span></p>
                            </span>
                            <span v-else-if="id == 1">
                            <p class="mt-2"><strong>{{ $t('individual_ticket_for')}} - {{ form.ticket_count }} {{ $t('people') }} </strong>
                                <span>{{ ' ' + currencyFormat(form.totalAmount) }} x {{ form.ticket_count }}</span></p>
                            </span>
                            <p class="text-right m-0">{{ $t('total') + ':' }} {{ currencyFormat(amount) }}</p>
                            <p class="text-right m-0">KDV (%20): {{ currencyFormat(vatAmount) }}</p>
                            <p class="text-right m-0">{{ $t('discount') + ':' }} {{ '%' + promotionCodeDiscount }}</p>
                            <p class="text-right">{{ $t('grand_total') + ':' }} {{ currencyFormat(form.totalAmount) }} TL</p>
                            <p class="note">
                                {{ $t('ticket_message1')}}<br>
                                {{ $t('ticket_message2')}}<br>
                                {{ $t('ticket_message3')}}
                            </p>
                            <button @click="buyTicket" class="payment-button">{{ $t('complete_your_payment')}}</button>
                        </div>
                    </div>
                    <b-row>
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="8">
                                    <ValidationProvider name="promotion_code" rules="">
                                        <b-form-group :label="$t('promotion_code')">
                                            <b-form-input
                                                v-model="form.promotion_code"
                                            ></b-form-input>
                                            <span v-if="promotionAppliedMessage"
                                                  :class="promotionMessageClass"> {{
                                                    promotionAppliedMessage
                                                }}</span>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col cols="4" class="mt-5">
                                    <b-button style="background-color: #f653fb" @click="promotionCode">{{
                                            $t('apply_to')
                                        }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
        <CommonModal ref="emailVerifyModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                     :closeBtn="false">
            <template v-slot:CommonModalTitle>
                {{ $t('approve_email').toUpperCase() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-email v-if="formProcess==='sendEmail'" :file="file" :eventType="selectedTicket.id"
                              :emailAddress="form.email" @closeModal="closeModal"
                              @closeEmail="closeEmail" @closeStudentModal="closeStudentModal"
                />
            </template>
        </CommonModal>
        <CommonModal ref="paymentSystemModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalContent>
                <payment-system v-if="formProcess==='sendpayment'" :eventType="selectedTicket.id"
                                :totalAmount="form.totalAmount" :amount="amount" :ticketCount="form.ticket_count"
                                :promotionCodeDiscount="promotionCodeDiscount" :vatAmount="vatAmount" :id="id"
                                @openPayment="openPayment" @closePaymentModal="closePaymentModal"
                ></payment-system>
            </template>
        </CommonModal>
        <CommonModal ref="paymentStatusModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
            <template v-slot:CommonModalTitle>
                {{ $t('payment_info').toUpperCase() }}
            </template>
            <template v-slot:CommonModalContent>
                <p>{{ $t('payment_info_message') }}</p>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import CommonModal from "@/components/elements/CommonModal.vue";
import UpdateEmail from "@/modules/ticketSystem/pages/UpdateEmail.vue";
import PaymentSystem from "@/modules/ticketSystem/pages/PaymentSystem.vue";
import TicketEventApplicationService from "@/services/TicketEventApplicationService";
import PhoneNumberInput from "@/components/elements/PhoneNumberInput.vue";


export default {
    components: {
        UpdateEmail,
        PaymentSystem,
        ValidationProvider,
        ValidationObserver,
        CommonModal,
        PhoneNumberInput
    },
    metaInfo() {
        return {
            title: this.$t("purchase_ticket"),
        };
    },
    data() {
        return {
            form: {
                ticket_count: "",
                name: "",
                surname: "",
                email: "",
                totalAmount: 0,
                country_id: 180,
            },
            amount: 0,
            vatRate: 0.20,
            promotionCodeDiscount: 0,
            ticketOptions: [],
            attendees: [],
            formProcess: null,
            promotionApplied: false,
            promotionAppliedMessage: '',
            id: this.$route.params.ticket_event_id,
            ticketInfo: [],
            file: [],
            promotionMessageClass: "",
            code: null,
        };
    },
    watch: {
        'form.promotion_code'(newVal) {
            if (!newVal) {
                this.promotionApplied = false;
                this.promotionAppliedMessage = "";
                this.priceCalculator();
            }
        },
        'form.ticket_count': function (newCount) {
            const desiredCount = Math.max(newCount - 1, 0);
            if (this.attendees.length > desiredCount) {
                this.attendees.splice(desiredCount);
            } else {
                while (this.attendees.length < desiredCount) {
                    this.attendees.push({
                        name: '',
                        surname: '',
                        birthdate: '',
                        email: '',
                        phone: '',
                        gender: '',
                        company: '',
                        country_id: 180,
                    });
                }
            }
            this.priceCalculator();
        },
        'form.totalAmount': function (newTotalAmount) {
            this.amount = newTotalAmount / (1 + this.vatRate);
        },
    },
    mounted() {
        if (this.selectedTicket.id === 1) {
            this.form.ticket_count = this.selectedTicket.min_ticket_count;
        } else if (this.selectedTicket.id === 2) {
            this.form.ticket_count = this.selectedTicket.min_ticket_count;
        }
    },
    computed: {
        selectedTicket() {
            let data = JSON.parse(localStorage.getItem("selectedTicket"))
            return JSON.parse(data);
        },
        vatAmount() {
            return this.form.totalAmount - this.amount;
        },
    },
    created() {
        this.priceCalculatorAmount()
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        mobileTelUpdated(event) {
            this.code = event
            this.form.phone = this.code.formattedNumber;
        },
        mobileTelAttendeeUpdated(index, event) {
            this.attendees[index].phone = event.formattedNumber;
        },
        decreaseCount() {
            if (this.form.ticket_count > this.selectedTicket.min_ticket_count) {
                this.form.ticket_count--;
                this.attendees.pop()
            }
        },
        increaseCount() {
            if (this.form.ticket_count < this.selectedTicket.max_ticket_count) {
                this.form.ticket_count++;
                this.attendees.push({
                    name: '',
                    surname: '',
                    birthdate: '',
                    email: '',
                    phone: '',
                    gender: '',
                    company: '',
                    country_id: 180,
                })
            }
        },
        async buyOtherTicket() {
            const isValid = await this.$refs.personalInfoForm.validate();
            if (!isValid) {
                this.$toast.error(this.$t('empty_data_on_table'));
                return
            }
            let form = {
                ...this.form,
                tickets: [{name: this.form.name, surname: this.form.surname, email: this.form.email}],
                ticket_count: 1,
                ticket_event_type_id: this.id,
            }
            TicketEventApplicationService.startApplication(form)
                .then(response => {
                    let message = response.data.message
                    this.$toast.success(this.$t('api.' + message));
                    this.formProcess = 'sendEmail'
                    this.$refs.emailVerifyModal.$refs.commonModal.show()
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        async buyTicket() {
            const isValid = await this.$refs.personalInfoForm.validate();
            if (!isValid) {
                this.$toast.error(this.$t('empty_data_on_table'));
                return
            }

            let tickets = [
                {
                    name: this.form.name,
                    surname: this.form.surname,
                    email: this.form.email,
                    phone: this.form.phone,
                    gender: this.form.gender,
                    company: this.form.company,
                    country_id: this.form.country_id,
                },
                ...this.attendees,
            ];

            let form = {
                ...this.form,
                tickets,
                ticket_event_type_id: this.id,
            };

            TicketEventApplicationService.startApplication(form)
                .then(() => {
                    this.formProcess = 'sendEmail'
                    this.$refs.emailVerifyModal.$refs.commonModal.show()
                })
                .catch(e => {
                    this.showErrors(e);
                })
                .finally(() => {
                    this.attendees = this.attendees.slice(0, this.form.ticket_count - 1);
                });
        },
        priceCalculatorAmount() {
            let formData = {
                ticket_count: this.form.ticket_count,
                ticket_event_type_id: this.id,
                promotion_code: this.form.promotion_code
            }
            TicketEventApplicationService.priceCalculator(formData)
                .then(response => {
                    this.form.totalAmount = response.data.data;
                    this.amount = this.form.totalAmount / (1 + this.vatRate)
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        priceCalculator() {
            let formData = {
                ticket_count: this.form.ticket_count,
                ticket_event_type_id: this.id,
                promotion_code: this.form.promotion_code
            }
            TicketEventApplicationService.priceCalculator(formData)
                .then(response => {
                    this.form.totalAmount = response.data.data
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        promotionCode() {
            if (!this.form.promotion_code) {
                this.promotionApplied = false;
                this.promotionAppliedMessage = ""
                this.promotionMessageClass = ""
                return;
            }

            let formData = {
                promotion_code: this.form.promotion_code,
                ticket_event_type_id: this.id
            }
            TicketEventApplicationService.usePromotionCode(formData)
                .then(response => {
                    this.promotionApplied = true
                    this.promotionAppliedMessage = this.$t("promotion_code_message")
                    this.promotionMessageClass = "text-success"
                    this.promotionCodeDiscount = response.data.data.discount
                    this.priceCalculator()
                })
                .catch(() => {
                    this.promotionApplied = false
                    this.promotionAppliedMessage = this.$t("promotion_code_error_message")
                    this.promotionMessageClass = "text-danger"
                })
        },
        closeModal() {
            this.$refs.emailVerifyModal.$refs.commonModal.hide()
            this.formProcess = 'sendpayment'
            this.$refs.paymentSystemModal.$refs.commonModal.show()
        },
        closeStudentModal() {
            this.$refs.emailVerifyModal.$refs.commonModal.hide()
            this.formProcess = null
            this.$router.push('/ticket/success/message');
        },
        closePaymentModal() {
            this.$refs.paymentSystemModal.$refs.commonModal.hide()
            this.formProcess = null
        },
        closeEmail() {
            this.$refs.emailVerifyModal.$refs.commonModal.hide()
            this.$refs.paymentStatusModal.$refs.commonModal.show()
            this.formProcess = null
        },
        openPayment() {
            this.$refs.paymentSystemModal.$refs.commonModal.show()
        }
    },
}
</script>

<style scoped>
.ticket-details {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
    background-color: #f653fb;
    color: white;
    padding: 10px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-weight: bold;
}

.payment-button {
    width: 100%;
    padding: 10px;
    background-color: #f653fb;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.note {
    font-size: 12px;
    margin-top: 10px;
    color: #555;
}
</style>
