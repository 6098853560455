<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('adaptation_request')" :isColumns="true" :actionButtons="subButton"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('adaptation_request')" :isColumns="true" :actionButtons="subButton"
                              @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="true" @exportExcel="exportExcel">
                <b-row>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.student_number"
                                          v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('national_id_passport_no')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.national_id"
                                          v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col xs="12" lg="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" lg="4">
                        <b-form-group :label="$t('department')">
                            <department-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                  v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" lg="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                                               :department_code="datatable.queryParams.filter.department_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="3">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.status"
                                                 code="course_transfer_status_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('period')">
                            <semesters-selectbox
                                v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CourseTransferService from "@/services/CourseTransferService";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import ExamScheduleService from "@/services/ExamScheduleService";
import ApprovalStepService from "@/services/ApprovalStepService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('adaptation_request')
        }
    },
    data() {
        return {
            universities: [],
            semesters: [],
            subButton: [
                {
                    text: `${this.$t('course_transfer_guide')}`,
                    callback: async () => {
                        const link = document.createElement("a")
                        link.setAttribute("href", "/constants/download/file/course_transfer_file/" + this.$t('course_transfer_guide_filename'))
                        //link.setAttribute("download",  `${this.$t('course_transfer_guide')}`)
                        link.setAttribute("target", "_blank")
                        link.click()
                        link.remove()
                    }
                }
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                permission: "coursetransfer_show",
                                callback: (row) => {
                                    this.$router.push("/course/transfers/evaluation/" + row.id);
                                }
                            },
                            {
                                text: this.$t('do_cancel'),
                                class: 'ri-close-circle-line',
                                permission: "approvalstep_cancel",
                                show: (row) => {
                                    if (row.active_step || row.status === "approved") {
                                        return true;
                                    }
                                    return false;
                                },
                                callback: (row) => {
                                    if (row.status === "approved"){
                                        const lenght = row.steps.length - 1
                                        const selectedStep = row.steps[lenght]
                                        this.$swal.fire({
                                            text: this.$t('are_you_sure_to_cancel'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    ApprovalStepService.cancel(selectedStep.id).then((response) => {
                                                        this.filter();
                                                    }).catch(e => {
                                                        this.showErrors(e)
                                                    });
                                                }
                                            })

                                    }else{
                                        this.$swal.fire({
                                            text: this.$t('are_you_sure_to_cancel'),
                                            showCancelButton: true,
                                            confirmButtonText: this.$t('yes'),
                                            cancelButtonText: this.$t('no')
                                        })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    ApprovalStepService.cancel(row.active_step.id).then((response) => {
                                                        this.filter();
                                                    });
                                                }
                                            })
                                    }
                                }
                            },
                            {
                                text: this.$t('take_on_application'),
                                class: 'ri-file-check-line',
                                permission: "coursetransfer_take",
                                show: (row) => {
                                    if (row.read_only) {
                                        return false;
                                    }
                                    if (row.responsible_id) {
                                        return false;
                                    }
                                    return true;
                                },
                                callback: (row) => {
                                    this.$swal.fire({
                                        text: this.$t('are_you_sure_to_take_on_this_application'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('yes'),
                                        cancelButtonText: this.$t('no')
                                    })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                CourseTransferService.take(row.id).then((response) => {
                                                    this.showMessage(response);
                                                    this.filter();
                                                });
                                            }
                                        })
                                }
                            },
                            {
                                text: this.$t('release_application'),
                                class: 'ri-file-close-line',
                                permission: "coursetransfer_release",
                                show: (row) => {
                                    if (row.read_only) {
                                        return false;
                                    }
                                    if (!row.responsible_id) {
                                        return false;
                                    }
                                    return true;
                                },
                                callback: (row) => {
                                    this.$swal.fire({
                                        text: this.$t('are_you_sure_to_release_this_application'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('yes'),
                                        cancelButtonText: this.$t('no')
                                    })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                CourseTransferService.release(row.id).then((response) => {
                                                    this.showMessage(response);
                                                    this.filter();
                                                });
                                            }
                                        })
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('academic_year'),
                        field: 'academic_year',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('semester'),
                        field: 'semester',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('request_number'),
                        field: 'id',
                        sortable: false,
                    },

                    {
                        label: this.toUpperCase('student_number'),
                        field: 'student_number',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('national_id_passport_no'),
                        field: 'national_id',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('faculty'),
                        field: this.getLocaleField('faculty_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('program'),
                        field: this.getLocaleField('program_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('input_type'),
                        field: this.getLocaleField('registration_type'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('responsible'),
                        field: this.getLocaleField('responsible'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: this.getLocaleField('status_name'),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('application_date'),
                        field: 'created_at',
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    created() {
        if (this.$store.getters['courseTransfers/getFilters']) {
            this.datatable.queryParams.filter = this.$store.getters['courseTransfers/getFilters'];
        } else {
            this.filterClear();
        }
    },
    methods: {
        async exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams,
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            const response = await CourseTransferService.getAll(config)
            const data = response.data.data

            const headers = [
                {header: this.$t('university_id_and_name'), key: "course_transfer_file_universities", width: 35},
                {header: this.$t('academic_year'), key: "academic_year", width: 20},
                {header: this.$t('semester'), key: "semester", width: 20},
                {header: `${this.$t('request_number')}`, key: "id", width: 23},
                {header: `${this.$t('student_number')}`, key: "student_number", width: 15},
                {header: this.$t('national_id_passport_no'), key: "national_id", width: 20},
                {header: this.$t('name'), key: "name", width: 20},
                {header: this.$t('surname'), key: "surname", width: 20},
                {header: this.$t('faculty'), key: this.getLocaleField('faculty_name'), width: 20},
                {header: this.$t('program'), key: this.getLocaleField('program_name'), width: 20},
                {header: this.$t('input_type'), key: this.getLocaleField('registration_type'), width: 20},
                {header: this.$t('status'), key: this.getLocaleField('status_name'), width: 20},
                {header: this.$t('application_date'), key: "created_at", width: 20},
                {header: this.$t('last_approve_date'), key: "updated_at", width: 20}
            ]
            this._downloadExcelFile({
                data: data,
                filename: `${this.$t('adaptation_request').toLowerCase()}-${this.$t('excel').toLowerCase()}.xlsx`,
                headers
            })

        },
        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                student_number: null,
                national_id: null,
                name: null,
                semester_id: null,
                surname: null,
                faculty_code: null,
                program_code: null,
                student_program_id: null,
                registration_type: null,
                status: null,
            };
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            if (this.datatable.queryParams.filter) {
                this.$store.dispatch('courseTransfers/setFilters', this.datatable.queryParams.filter);
            }
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return CourseTransferService.getAll(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            ExamScheduleService.downloadAsExcel(config)
                .then(res => this._downloadFile(res, this.$t("university_courses") + '.xlsx'))
                .catch(err => this.showErrors(err))

        }
    }
}
</script>

