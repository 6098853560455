<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="semester_id" rules="" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('semester')">
                                                <semesters-selectbox :multiple="false" :validate-error="errors[0]"
                                                    :setActive="true" v-model="defineForm.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('excel_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file v-model="defineForm.file"
                                                     :state="errors[0] ? false : (valid ? true : null)"
                                                     :placeholder="$t('select_file')"
                                                     :drop-placeholder="$t('drop_file')"
                                                     ref="fileInput"
                                                     multiple
                                        />
                                        <b-button variant="outline-secondary"
                                                  @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                        </b-button>
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
<!--                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-table class="mt-2 mb-2" borderless small bordered responsive thead-tr-class="table-success" :items="tableItems" :fields="tableFields"></b-table>
                        </div>-->
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                            <b-button @click="download('registrations_import')" variant="outline-secondary" class="mr-2">{{ $t('download_excel_template') }}</b-button>
                            <b-button variant="primary"
                                      @click="sendForm"
                                      label="import"
                            />
                        </div>

                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
// Component


// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import JobService from "@/services/JobService";
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
    components: {

        ValidationProvider,
        ValidationObserver,
    }
    ,
    data() {
        return {
            defineForm: {
                semester_id: null,
                file: null,
                period_id: null
            },
            loading: false,
            tableFields: [
                {
                    key: 'tc',
                    label: this.$t('tc'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'name',
                    label: this.$t('name'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'surname',
                    label: this.$t('surname'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'nationality_code',
                    label: this.$t('nationality_code'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'gender',
                    label: this.$t('gender'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'birth_date',
                    label: this.$t('birth_date'),
                    tdClass: 'border text-center',
                },
                {
                    key: 'birthplace',
                    label: this.$t('birthplace')+"",
                    tdClass: 'border text-center',
                },
                 {
                    key: 'mother_name',
                    label: this.$t('mother_name'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'father_name',
                    label: this.$t('father_name'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'province_name',
                    label: this.$t('province_name'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'district_name',
                    label: this.$t('district_name'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'communication_address',
                    label: this.$t('communication_address'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'telephone',
                    label: this.$t('telephone'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'email',
                    label: this.$t('email'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'program_code',
                    label: this.$t('program_code'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'registration_type_code',
                    label: this.$t('registration_type_code'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'osym_scholarship_rate',
                    label: this.$t('osym_scholarship_rate'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'special_scholarship_rate',
                    label: this.$t('special_scholarship_rate'),
                    tdClass: 'border text-center',
                },

                {
                    key: 'special_scholarship_code',
                    label: this.$t('special_scholarship_code'),
                    tdClass: 'border text-center',
                },

            ],
            tableItems: [
                {
                    tc: this.$t('Tc'),
                    name: this.$t('name'),
                    surname: this.$t('surname'),
                    nationality_code: this.$t('nationality_code'),
                    gender: this.$t('gender'),
                    birth_date: this.$t('birth_date'),
                    birthplace: this.$t('birthplace'),
                    mother_name: this.$t('mother_name'),
                    father_name: this.$t('father_name'),
                    province_name: this.$t('province_name'),
                    district_name: this.$t('district_name'),
                    communication_address: this.$t('communication_address'),
                    telephone: this.$t('telephone'),
                    email: this.$t('email'),
                    program_code: this.$t('program_code'),
                    registration_type_code: this.$t('registration_type_code'),
                    osym_scholarship_rate: this.$t('osym_scholarship_rate'),
                    special_scholarship_rate: this.$t('special_scholarship_rate'),
                    special_scholarship_code: this.$t('special_scholarship_code'),

                },
            ]
        }
    }
    ,
    methods: {
        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])
                formData.append('semester_id', this.defineForm.semester_id)
                return JobService.registrationsImport(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        // this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        this.$emit('successProcess',true)
                    })
                    .catch(e => {
                        this.showErrors(e,this.$refs.updateForm)
                    })
            }
        },
        download(code) {
            excelImportTemplate.downloadTemplate(code)
                .then(response => {
                    this._downloadFile(response, code + '.xlsx')
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
    }
}
</script>
