<template>
    <layout>
        <div class="w-100 h-100 d-flex flex-column">
            <div class="d-flex justify-content-end p-4 pb-0">
                <button @click="goToLogin" class="btn btn-light btn-40">
                    <i class="ri-arrow-left-line"></i>
                </button>
            </div>
            <div class="flex-grow-1 w-100 d-flex flex-column p-4 justify-content-center">
                <div>
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <div class="logo-wrapper mb-5">
                            <img src="../../../assets/img/bau-logo-for-light.svg" height="40" />
                        </div>
                        <h4 class="flex-grow-1 text-center mb-5">
                            {{ $t('turkmer') }} {{ $t('application_system') }}
                        </h4>
                    </div>
                    <div class="fadeIn">
                        <div>
                            <ValidationObserver ref="formDataValidation">
                                <div class="border p-4 bg-white"
                                    style="margin-top: -2px; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px">
                                    <b-row>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('name')">
                                                    <b-form-input v-model="formData.name" :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('surname')">
                                                    <b-form-input v-model="formData.surname"
                                                        :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="passport_number" :rules="!isDisabledPassportNo ? '' : 'required'"
                                                                v-slot="{ errors }">
                                                <b-form-group :label="$t('passport_number')">
                                                    <b-form-input v-model="formData.passport_number"
                                                                  :disabled="isDisabledNationalId"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                          <ValidationProvider name="national_id" :rules="!isDisabledNationalId ? '' : 'required'" v-slot="{ errors }">
                                            <b-form-group :label="$t('national_id')">
                                              <b-form-input type="number" v-model="formData.national_id"
                                                            :disabled="isDisabledPassportNo"
                                                            maxlength="11" :state="errors[0] ? false : null" />
                                              <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="birthdate" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('birthdate')">
                                                    <select-date v-model="formData.birthdate"
                                                        :validationError="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col cols="12" md="6">
                                            <ValidationProvider name="email"
                                                                rules="email|required"
                                                                v-slot="{ errors }">
                                                <b-form-group :label="$t('email')">
                                                    <b-form-input v-model="formData.email"
                                                                  type="email"
                                                                  :state="errors[0] ? false : null" />
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="d-flex justify-content-center mt-3" v-if="!process">
                                    <processing-button :processing="formStart" variant="primary"
                                        :label="$t('email_send').toUpper()"
                                        @click="start"/>
                                </div>
                                <div class="border rounded pt-1 pb-2 pl-4 pr-4 mb-4 bg-white"
                                    style="margin-top: -10px;  border-top-right-radius: 0 !important; border-top-left-radius: 0 !important;"
                                    v-if="process">
                                    <div class="smsForm">
                                        <div class="fadeIn">
                                            <ValidationProvider name="pin"
                                                :rules="process == 'verify' ? 'required|length:6' : ''" v-slot="{ errors }">
                                                <div class="mb-2">
                                                    <sms-input v-model="formData.pin" :timerFrom.sync="smsTimer"
                                                        @timerExpired="timerExpired" />
                                                </div>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center" v-if="process">
                                    <processing-button :processing="formStart" variant="primary"
                                        :label="$t('send_again').toUpper()" @click="start" v-if="process == 'startAgain'" />
                                    <processing-button :processing="formVerify" variant="primary"
                                        :label="$t('verify').toUpper()" @click="verify" v-if="process == 'verify'" />
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
<!--            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG" />-->
        </div>
    </layout>
</template>

<script>
// Layout
import Layout from '@/modules/turkmerApplication/layout/Layout.vue'

// Components
import SelectDate from '@/components/interactive-fields/SelectDate.vue'
import SmsInput from '@/components/elements/SmsInput.vue'
import ProcessingButton from '@/components/elements/ProcessingButton.vue'

// import { VueRecaptcha } from 'vue-recaptcha'

// Services
import TurkmerApplicationStudentService from '@/services/TurkmerApplicationStudentService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Base from '@/plugins/Base'

export default {
    components: {
        Layout,
        //VueRecaptcha,
        SelectDate,
        SmsInput,
        ProcessingButton,
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            process: null,
            formStart: false,
            formVerify: false,
            formData: {
                nationality_code: null,
                national_id: null,
                passport_number: null,
                name: null,
                surname: null,
                birthdate: null,
                email: null
            },
            smsTimer: null,
            isDisabledPassportNo: false,
            isDisabledNationalId: false,
        }
    },

    watch: {
        'formData.passport_number'(newValue) {
            this.isDisabledPassportNo = !!newValue;
            if (this.isDisabledPassportNo) {
                this.formData.national_id = null;
            }
        },
        'formData.national_id'(newValue) {
            this.isDisabledNationalId = !!newValue;
            if (this.isDisabledNationalId) {
                this.formData.passport_number = null;
            }
        }
    },

    async created() {
        let turkmerApplicationTempData = Base.LocalStorage.get('turkmer_application_temp_data');
        if (turkmerApplicationTempData) {
            this.formData = {
                ...this.formData,
                ...turkmerApplicationTempData
            }
            Base.LocalStorage.remove('turkmer_application_temp_data');
        }
        else {
            this.formData.nationality_code = 'TR';
        }
    },
    methods: {
        onCaptchaVerified() {
          //  this.formData.g_recaptcha_token = recaptchaToken;
            const formattedBirthdate = new Date(this.formData.birthdate).toISOString().split('T')[0];
            let formData = { ...this.formData, birthdate: formattedBirthdate };

            this.formStart = true
            TurkmerApplicationStudentService.start(formData)
                .then(() => {
                    this.process = 'verify'
                })
                .then(() => {
                    this.smsTimer = 120
                })
                .catch(e => {
                    if (e.status == 423) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                        setTimeout(() => {
                            this.$router.push('/turkmer/login');
                        }, 1000)
                    }
                    else {
                        this.showErrors(e, this.$refs.formDataValidation)
                    }
                })
                .finally(() => {
                    this.formStart = false
                    // this.$refs.recaptcha.reset();
                })
        },
        async start() {
            const isValid = await this.$refs.formDataValidation.validate();
            if (isValid) {
                // this.$refs.recaptcha.execute()
                this.onCaptchaVerified()
            }
        },
        async verify() {
            const isValid = await this.$refs.formDataValidation.validate();
            if (isValid) {
                let formData = {
                    ...this.formData
                }

                this.formVerify = true;
                TurkmerApplicationStudentService.verify(formData)
                    .then(response => {
                        let data = response.data.data;
                        let loginForm = {
                            'username': data.national_id ? data.national_id : data.passport_number,
                            'pin': data.pin
                        }

                        // Login
                        TurkmerApplicationStudentService.login(loginForm)
                            .then(response => {
                                let data = response.data.data;

                                // Data
                                if (data) {
                                    this.$store.commit('turkmerApplication/setData', data)
                                    Base.LocalStorage.set('turkmer_application_data', data)
                                }

                                // Username
                                let username = data.national_id ?
                                    data.national_id :
                                    data.passport_number;
                                if (username) {
                                    this.$store.commit('turkmerApplication/setUsername', username)
                                    Base.LocalStorage.set('turkmer_application_username', username)
                                }

                                // Pin
                                if (data.pin) {
                                    this.$store.commit('turkmerApplication/setPin', data.pin)
                                    Base.LocalStorage.set('turkmer_application_pin', data.pin)
                                }
                            })
                            .then(() => {
                                this.$router.push('/turkmer');
                            })
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formDataValidation)
                    })
                    .finally(() => {
                        this.formVerify = false
                        //this.$refs.recaptcha.reset()
                    })
            }
        },
        timerExpired(event) {
            this.smsTimer = null
            this.process = 'startAgain'
        },
        goToLogin() {
            this.$router.push('/turkmer/login');
        }
    }
}
</script>

<style>
#applicationTypeButtons {
    width: 100%;
    display: flex;
}

#applicationTypeButtons label {
    flex-grow: 1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

#applicationTypeButtons label.active {
    background: #00aeef !important;
    color: #fff !important;
    position: relative;
    z-index: 10;
}

#applicationTypeButtons label.active::after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: -6px;
    left: 50%;
    border-top: 6px solid #00aeef;
    bottom: -6px;
}
</style>
