<template>
    <div>
        <ValidationObserver ref="filterForm">
        <b-row>
            <b-col cols="12">
                <b-button variant="primary" @click="getReport">
                    {{ $t('get_report').toUpper() }}
                </b-button>
            </b-col>
        </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import StudentReportService from "@/services/StudentReportService";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import qs from 'qs'

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            filter: {},
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                StudentReportService.coopScholarshipStudentReport()
                    .then((response) => {
                        this._downloadFile(response, 'coop-scholarship-student-report.xlsx')
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
