<template>
    <div>
        <div class="data-form">
            <ValidationObserver ref="importForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="file" rules="required" v-slot="{errors}">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file
                                        v-model="defineForm.file"
                                        :state="errors[0] ? false : null"
                                        :placeholder="$t('select_file')"
                                        :drop-placeholder="$t('drop_file')"
                                        ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                <b-button @click="download('staff_import')" variant="outline-secondary" class="my-2">{{ $t('download_excel_template') }}</b-button>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <processing-button :processing="processing"
                                           :label="$t('import')"
                                           variant="primary"
                                           @click="sendForm"/>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import ProcessingButton from "@/components/elements/ProcessingButton";
import StaffService from "@/services/StaffService";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import excelImportTemplate from "@/services/ExcelImportTemplate";

export default {
    components: {
        ProcessingButton,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            defineForm: {
                file: null
            },
            processing: false
        }
    },
    methods: {
        async sendForm() {
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                this.processing = true
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])

                StaffService.importExcel(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        this.$emit('successProcess')
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.importForm)
                    })
                    .finally(() => {
                        this.processing = false
                    })

            }
        },
        download(code) {
            excelImportTemplate.downloadTemplate(code)
                .then(response => {
                    this._downloadFile(response, code + '.xlsx')
                })
                .catch(error => {
                    if (error.status == 422) {
                        this.$toast.error(error.data.errors.file[0]);
                    }
                    if (error.status == 406) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
        },
    }
}
</script>
