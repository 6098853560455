<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exams')"
                        :isNewButton="checkPermission('examdefinition_store')"
                        :isColumns="true"
                        @new-button-click="showModal('create')"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('add_exam')"
                              :isNewButton="checkPermission('examdefinition_store')"
                              :isColumns="true"
                              @new-button-click="showModal('create')"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="5" lg="5" xl="4">
                        <b-form-group :label="$t('date')" class="position-relative">
                            <v-date-picker v-model="datatable.queryParams.filter.date"
                                           :locale="'en'"
                                           @input="changeDate"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input :readonly="true" :value="inputValue"
                                                      v-on="inputEvents"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                <i class="ri-calendar-line"></i></b-button>
                                        </b-input-group-append>

                                    </b-input-group>

                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="5" lg="5" xl="4">
                        <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('rank')">
                                <b-form-input type="number" v-model="datatable.queryParams.filter.rank">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="formModal" size="xl" :onHideOnlyX="true" :footer="true" :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ formProcess=='create' ? $t('new'):$t('edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('date')" class="position-relative">
                                            <v-date-picker v-model="form.date"
                                                           :locale="'en'"
                                                           @input="changeDate"
                                                           :validateError="errors[0]"
                                            >
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input :readonly="true" :value="inputValue"
                                                                      v-on="inputEvents"
                                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button variant="outline-secondary" class="btn-40" disabled>
                                                                <i class="ri-calendar-line"></i></b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                                                    </b-input-group>

                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="rank" rules="" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('rank')">
                                            <b-form-input type="number" v-model="form.rank">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary" @click="formCreate" v-if="formProcess=='create'">{{ $t('save') }}</b-button>
                        <b-button variant="primary" @click="formUpdate" v-if="formProcess=='update'">{{ $t('update') }}</b-button>
                    </b-form-group>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import PrepExamDefinitionsService from "@/services/PrepExamDefinitionsService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationProvider, ValidationObserver} from "vee-validate"
import qs from 'qs'
import AttendanceEntryService from "@/services/AttendanceEntryService";
import ExamDefinationService from "@/services/ExamDefinationService";
import ExamRequest from "@/services/ExamRequest";
import moment from "moment";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        ModuleSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('prep_exam_definitions')
        }
    },
    data() {
        return {
            masks: {
                input: "YYYY-MM-DD",
            },
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons:[
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "examdefinition_update",
                                callback:(row)=>{
                                    this.showModal('update', row.id)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "examdefinition_delete",
                                callback:(row)=>{
                                    this.formDelete(row.id)
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.$t('date'),
                        field: 'date',
                        hidden: false,
                    },
                    {
                        label: this.$t('rank'),
                        field: 'rank',
                        hidden: false,
                    },


                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            sectionsOptions:[],
            formProcess: null,
            formId: null,
            form: {},
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },

    methods: {
        changeDate(item){
            this.datatable.queryParams.filter.date=moment(item).format('YYYY-MM-DD')
        },
        filterSet(){
            this.datatable.queryParams.filter = {};
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key){
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return ExamRequest.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showModal(process, id){
            this.formProcess = process
            this.formId = id;
            this.formClear();
            this.$refs.form.reset();
            if(this.formProcess=='update' && id){
                ExamRequest.show(id)
                    .then(response => {
                        let data = response.data.data
                        this.form.date=data.date
                        this.form.rank=data.rank
                        this.filterSet()
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
            }
            this.$refs.formModal.$refs.commonModal.show();
        },
        formClear(){
            this.form ={
               date:null,
               rank:null
            }
        },
        async formCreate(){
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let formData = {
                    exam_definition_id:this.$route.params.id,
                    rank:this.form.rank,
                    date:this.form.date
                }
                ExamRequest.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide()
                        this.filterClear()
                        this.filterSet()
                        this.getRows()
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
            }
        },
        async formUpdate(){
            const isValid = await this.$refs.form.validate();
            if (isValid && this.formId) {
                let formData = {
                    exam_definition_id:this.$route.params.id,
                    rank:this.form.rank,
                    date:this.form.date
                }
                ExamRequest.put(this.formId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide()
                        this.filterClear()
                        this.filterSet()
                        this.getRows()

                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
            }
        },
        formDelete(id){
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        ExamRequest.deletes(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.formClear();
                                this.filterSet();
                                this.getRows()


                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
        showErrors(e){
            if (e.status == '422') {
                for (const [key, value] of Object.entries(e.data.errors)) {
                    this.$refs.form.errors[key].push(value[0]);
                }
            }
            else if (e.status == '406') {
                this.$toast.error(this.$t('api.' + e.data.message));
            }
        },
    }
}
</script>

