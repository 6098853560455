<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('prep_holiday_day')" :isNewButton="checkPermission('prepholiday_store')"
					@new-button-click="createFormShow()">
				</Header>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('prep_holiday_day')" :isNewButton="checkPermission('prepholiday_store')"
					@new-button-click="createFormShow()"
					>
				</HeaderMobile>
			</template>
<!--			<datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"-->
<!--				:exportExcel="checkPermission('course_excelexport')" @exportExcel="exportExcel">-->
<!--				<b-row>-->
<!--					&lt;!&ndash; COURSE CODE &ndash;&gt;-->
<!--					<b-col sm="6" md="6">-->
<!--						<b-form-group :label="$t('explanation')">-->
<!--							<b-form-input type="text" v-model="datatable.queryParams.filter.explanation"></b-form-input>-->
<!--						</b-form-group>-->
<!--					</b-col>-->
<!--                    <b-col sm="6" md="6">-->
<!--                    <b-form-group :label="$t('date')">-->
<!--                        <v-date-picker-->
<!--                            v-model="datatable.queryParams.filter.date"-->
<!--                            locale="tr"-->
<!--                            is-expanded-->
<!--                            :masks="{ input: 'DD-MM-YYYY'}"-->
<!--                            :popover="{ 'visibility': 'click' }">-->
<!--                            <template-->
<!--                                v-slot="{ inputValue, inputEvents }">-->
<!--                                <b-input-group>-->
<!--                                    <b-form-input-->
<!--                                        :readonly="true"-->
<!--                                        :value="inputValue"-->
<!--                                        v-on="inputEvents"-->

<!--                                    ></b-form-input>-->
<!--                                    <b-input-group-append>-->
<!--                                        <b-button-->
<!--                                            variant="outline-secondary"-->
<!--                                            class="btn-40"-->
<!--                                            disabled-->
<!--                                        >-->
<!--                                            <i class="ri-calendar-line"></i-->
<!--                                            ></b-button>-->
<!--                                    </b-input-group-append>-->

<!--                                </b-input-group>-->
<!--                            </template>-->
<!--                        </v-date-picker>-->
<!--                    </b-form-group>-->
<!--                    </b-col>-->

<!--				</b-row>-->
<!--			</datatable-filter>-->
			<datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
				:total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
				@on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
			</datatable>
			<CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('new').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<create-form @createFormSuccess="createFormSuccess" v-if="formProcess == 'create'" />
				</template>
			</CommonModal>
			<CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
				<template v-slot:CommonModalTitle>
					{{ $t('edit').toUpper() }}
				</template>
				<template v-slot:CommonModalContent>
					<update-form :formId="formId" @updateFormSuccess="updateFormSuccess" v-if="formProcess == 'update'" />
				</template>
			</CommonModal>
		</app-layout>
	</div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";

// Pages
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm.vue";

// Services

// Others
import qs from 'qs'
import PrepHolidayServices from "@/services/PrepHolidayServices";

export default {
	components: {
		AppLayout,
		Header,
		HeaderMobile,
		DatatableFilter,
		Datatable,
		CommonModal,
		CreateForm,
		UpdateForm,

	},
	metaInfo() {
		return {
			title: this.$t('prep_holiday_day')
		}
	},

	data() {
		return {
			datatable: {
				isLoading: false,
				columns: [
					{
						label: '',
						field: 'buttons',
						html: true,
						sortable: false,
						tdClass: 'p-0 text-center w-40 align-middle',
						buttons: [
							{
								text: this.$t('edit'),
								class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
								permission: "prepholiday_update",
								callback: (row) => {
									this.updateFormShow(row.id)
								}
							},
							{
								text: this.$t('delete'),
								class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
								permission: "prepholiday_delete",
								callback: (row) => {
									this.formDelete(row.id)
								}
							}
						]
					},
					{
						label: this.toUpperCase('id'),
						field: 'id',
						hidden: true,
					},
					{
						label: this.toUpperCase('explanation'),
						field: 'explanation',
						hidden: false,
					},
                    {
                        label: this.toUpperCase('date'),
                        field: 'date',
                        hidden: false,
                    },

				],
				rows: [],
				total: 0,
				filterStatus: true,
				showTable: false,
				queryParams: {
					filter: {},
					sort: '-date',
					page: 1,
					limit: 20
				}
			},
			formProcess: null,
			formId: null,
			form: {},
		}
	},
	created() {
        this.getRows()
	},
	methods: {
		filterSet() {
			this.datatable.queryParams.filter = {
                explanation:null,
                date:null
            }
		},
		filterClear() {
			this.filterSet()
			this.getRows();
		},
		filter() {
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		onPageChange(params) {
			this.datatable.queryParams.page = params.currentPage;
			this.getRows();
		},
		onPerPageChange(params) {
			this.datatable.queryParams.limit = params.currentPerPage;
			this.datatable.queryParams.page = 1;
			this.getRows();
		},
		changeColumn(key) {
			this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
		},
		onSortChange(params) {
			const sortType = params[0].type == 'desc' ? '-' : '';
			this.datatable.queryParams.sort = sortType + params[0].field
			this.getRows();
		},
		getRows() {
			this.datatable.showTable = true;
			this.datatable.isLoading = true;

			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};
			return PrepHolidayServices.getAll()
				.then((response) => {
					this.datatable.rows = response.data.data
					this.datatable.total = response.data.pagination.total

					this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
				})
				.finally(() => {
					this.datatable.isLoading = false;
				});
		},
		// Clear
		formClear() {
			this.formId = null
			this.formProcess = null
		},

		// Create
		createFormShow() {
			this.formId = null
			this.formProcess = 'create'
			this.$refs.createFormModal.$refs.commonModal.show()
		},
		createFormSuccess() {
			this.$refs.createFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		// Update
		updateFormShow(id) {
			this.formId = id
			this.formProcess = 'update'
			this.$refs.updateFormModal.$refs.commonModal.show()
		},
		updateFormSuccess() {
			this.$refs.updateFormModal.$refs.commonModal.hide()
			this.getRows()
			this.formClear()
		},

		formDelete(id) {
			this.$swal
				.fire({
					text: this.$t('are_you_sure_to_delete'),
					showCancelButton: true,
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('no'),
				})
				.then((result) => {
					if (result.isConfirmed) {
						PrepHolidayServices.del(id)
							.then(response => {
								this.$toast.success(this.$t('api.' + response.data.message));
								this.getRows();
								this.formClear();
							})
							.catch(error => {
								this.$toast.error(this.$t('api.' + error.data.message));
							});
					}
				})
		},

		exportExcel() {
			const config = {
				params: {
					...this.datatable.queryParams
				},
				paramsSerializer: (params) => qs.stringify(params, { encode: false })
			};

			return CourseService.exportExcel(config)
				.then((response) => {
					this._downloadFile(response, 'courses.xlsx')
				})
				.catch(e => {
				});
		},
		importData() {
			this.formProcess = 'import'
			this.$refs.importFormModal.$refs.commonModal.show()
		},
		importFormSuccess() {
			this.formProcess = null;
			this.$refs.importFormModal.$refs.commonModal.hide()
		}
	}
}
</script>

