<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('job_information')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('work_status') }}</label>
                                <div v-if="responseWorkingData.working_status === 'working'">{{ $t('working') }}</div>
                                <div v-else-if="responseWorkingData.working_status === 'not_working'">{{ $t('not_working') }}</div>
                                <div v-else>{{ responseWorkingData.working_status }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('not_working_reason') }}</label>
                                <div>
                                    {{ $i18n.locale == 'tr' ? responseWorkingData.not_working_reason_text : responseWorkingData.not_working_reason_text_en }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('job_corporation') }}</label>
                                <div>{{ responseWorkingData.working_organisation }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <div class="label-as-input-div">
                                <label>{{ $t('job_sector') }}</label>
                                <div>
                                    {{ $i18n.locale == 'tr' ? responseWorkingData.working_sector_text : responseWorkingData.working_sector_text_en }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('positional_status') }}</label>
                                <div>
                                    {{ $i18n.locale == 'tr' ? responseWorkingData.working_position_text : responseWorkingData.working_position_text_en }}
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('position_detail') }}</label>
                                <div>{{ responseWorkingData.working_position_detail }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['job_information'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others

export default {
    props: {
        isUpdate: {
            type: Boolean
        }
    },
    components: {

    },

    data() {
        return {
            responseWorkingData: {
                working_status: null,
                not_working_reason: null,
                working_organisation: null,
                working_sector: null,
                working_position: null,
                working_position_detail: null
            }
        }
    },
    created() {
        this.WorkingData()

    },
    watch: {
        isUpdate: {
            handler(value){
                this.WorkingData()
            }
        }
    },
    methods: {
        WorkingData(){
            return AlumniService.WorkingInfo(this.$route.params.id)
                .then(response => {
                    this.responseWorkingData  = response.data.data;
                })
                .catch(e => {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                })
        },

    }
}
</script>

