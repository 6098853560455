const routes = [
    {
        path: "/ticket/event/qr-code-scan/:eventId",
        component: () => import("../pages/Index"),
        meta: {
            isAuthenticated: true,
            permission:'ticketeventapplication_ticketinformation'
        }
    }
];

export default routes;
