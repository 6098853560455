<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ticket_event_promotion_codes')"
                        :isNewButton="checkPermission('ticketeventpromotioncode_store')"
                        :isFilter="false"
                        @new-button-click="createFormShow"
                        :otherButton="{ show: true, title: $t('generate_ticket_discount_codes'), emit:'generatePersonalPromotionCode'}"
                        @generatePersonalPromotionCode="generatePersonalPromotionCode"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ticket_event_promotion_codes')"
                              :isNewButton="checkPermission('ticketeventpromotioncode_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow"
                              :otherButton="{ show: true, title: $t('message_bulk_btn'), emit:'generatePersonalPromotionCode'}"
                              @generatePersonalPromotionCode="generatePersonalPromotionCode"
                              @filter-div-status="datatable.filterStatus = $event">
                    <div class="mb-1" v-for="(column,key) in datatable.columns">
                        <b-form-checkbox
                            :id="'checkbox-'+key"
                            :name="'checkbox-'+key"
                            :value="false"
                            :unchecked-value="true"
                            v-model="column.hidden"
                            v-if="column.field!='buttons'"
                        >
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </HeaderMobile>
            </template>

            <datatable-filter v-show="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="4" md="4">
                        <b-form-group :label="$t('type')">
                            <b-form-select v-model="datatable.queryParams.filter.type" :options="typeOptions"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" md="4">
                        <b-form-group :label="$t('event_type')">
                            <ticket-event-selectbox v-model="datatable.queryParams.filter.ticket_event_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" md="4">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="datatable.queryParams.filter.code"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            />
            <CommonModal ref="showModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()" v-if="checkPermission('ticketeventpromotioncode_show')">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId"
                               v-if="formProcess=='show'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="createFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('ticketeventpromotioncode_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('ticketeventpromotioncode_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
            <CommonModal ref="generatePromotionCodeModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('generate_ticket_discount_codes') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="generatePromotionCodeFormValidate">
                                <b-row>
                                    <b-col cols="6" md="6" sm="12">
                                        <ValidationProvider name="ticket_event_id" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('event_type')">
                                                <ticket-event-selectbox
                                                    v-model="formData.ticket_event_id"
                                                ></ticket-event-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="6" md="6" sm="12">
                                        <ValidationProvider name="count" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('count')">
                                                <b-form-input
                                                    v-model="formData.count"
                                                    type="number"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="6" md="6" sm="12">
                                        <ValidationProvider name="discount" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('discount')">
                                                <b-form-input
                                                    v-model="formData.discount"
                                                    type="number"
                                                    :min="1"
                                                    :max="100"
                                                ></b-form-input>
                                                <span class="position-absolute" style="right: 40px; top: 55%; transform: translateY(-50%);">%</span>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="6" md="6" sm="12">
                                        <ValidationProvider name="description" rules="" v-slot="{errors}">
                                            <b-form-group :label="$t('explanation')">
                                                <b-form-input
                                                    v-model="formData.description"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="5" md="5" sm="6" class="text-center">
                                        <b-button block variant="primary" @click="generatePromotionCode">
                                            {{$t('send')}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    //Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    //Component
    import DatatableFilter from '@/components/datatable/DatatableFilter';
    import Datatable from '@/components/datatable/Datatable';
    import CommonModal from '@/components/elements/CommonModal';
    import TicketEventSelectbox from "@/components/interactive-fields/TicketEventSelectbox.vue";

    //Page
    import CreateForm from './CreateForm'
    import ShowForm from './ShowForm'
    import UpdateForm from './UpdateForm'

    //Service
    import TicketEventPromotionCodeService from '@/services/TicketEventPromotionCodeService';

    //Other
    import qs from 'qs';
    import i18n from "@/plugins/i18n";

    export default {
        components: {
            TicketEventSelectbox,
            AppLayout,
            Header,
            HeaderMobile,

            DatatableFilter,
            Datatable,
            CommonModal,

            CreateForm,
            ShowForm,
            UpdateForm,
        },
        metaInfo() {
            return {
                title: this.$t('ticket_event_promotion_codes')
            }
        },
        data() {
            return {
                formId: 0,
                form: {},
                formData: {},
                formProcess: null,
                typeOptions: [
                    {value: 'general', text: this.$t('general') },
                    {value: 'personal', text: this.$t('individual') }
                ],
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            hidden: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line',
                                    permission: 'ticketeventpromotioncode_show',
                                    callback: (row) => {
                                        this.showForm(row.id);
                                    }
                                },
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: 'ticketeventpromotioncode_update',
                                    callback: (row) => {
                                        this.formId = row.id;
                                        this.loadData();
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-2-line',
                                    permission: 'ticketeventpromotioncode_delete',
                                    callback: (row) => {
                                        this.delete(row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase('type'),
                            field: 'type',
                            hidden: false,
                            formatFn: (value, row) => {
                                return row.type == 'general' ? this.$t('general') :this.$t('individual');
                            }
                        },
                        {
                            label: this.$t('code'),
                            field: 'code',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('discount'),
                            field: 'discount',
                            hidden: false,
                            formatFn: (value) => {
                                return `${value} %`;
                            }
                        },
                        {
                            label: this.toUpperCase('explanation'),
                            field: 'description',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('quota'),
                            field: 'quota',
                            hidden: false
                        },
                        {
                            label: this.toUpperCase('status'),
                            field: 'status',
                            hidden: false,
                            formatFn: (value, row) => {
                                return row.status == true ? this.$t('active') :this.$t('passive');
                            }
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            };
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {};
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return TicketEventPromotionCodeService.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data;
                                           this.datatable.total = response.data.pagination.total;
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false;
                                       });
            },
            generatePersonalPromotionCode(){
                this.$refs.generatePromotionCodeModal.$refs.commonModal.show()
            },

            generatePromotionCode(){
                const isValid = this.$refs.generatePromotionCodeFormValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    TicketEventPromotionCodeService.generatePromotionCode(this.formData)
                        .then((response) => {
                            this.$refs.generatePromotionCodeModal.$refs.commonModal.hide()
                            this.$toast.success(this.$t("api." + response.data.message));
                            this.getRows()
                            this.formData= {}
                        })
                        .catch((error) => {
                            this.showErrors(error, this.$refs.generatePromotionCodeFormValidate)
                        })
                }
            },
            loadData() {
                this.formProcess = 'update';
                TicketEventPromotionCodeService.get(this.formId)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.$refs.updateFormModal.$refs.commonModal.show();
                                })
                                .catch((error) => {
                                    if (error.data.message) {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    }
                                });
            },
            delete(id) {
                this.deleteModal(() => {
                    TicketEventPromotionCodeService.deleteTicket(id)
                                    .then((response) => {
                                        this.filter();
                                        this.$toast.success(this.$t('api.' + response.data.message));
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('api.' + error.data.message));
                                    });
                });
            },
            async createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            async updateFormSuccess() {
                this.$refs.updateFormModal.$refs.commonModal.hide()
                this.getRows()
                this.formClear()
            },
            createFormShow() {
                this.formId = null
                this.form = {};
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            formClear() {
                this.formId = null
                this.formProcess = null
            },
            showForm(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.showModal.$refs.commonModal.show()
            },
        },
    }
</script>
