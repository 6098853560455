import Api from '@/services/Index';

const getAll = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/coordinator-courses')
}

const updateCourseByCoordinator = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course-by-coordinator/'+courseId, formData)
}

const getProgramCoursesAll = async (programCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-courses/'+programCode)
}

const updateCourseByProgramCoordinator = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course-by-program-coordinator/'+courseId, formData)
}

const updateCourseByAdmin = async (courseId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/update-course/'+courseId, formData)
}

const getProgramCoordinatorCourses = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-coordinator-courses/')
}

const getCourseCoordinatorWeeklyCoursePlans = async (courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/course-coordinator-weekly-course-plans/'+courseId)
}

const storeCourseCoordinatorWeeklyCoursePlan = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/ects/course-coordinator-store-weekly-course-plan', formData)
}

const updateCourseCoordinatorWeeklyCoursePlan = async (weeklyCoursePlanId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/course-coordinator-update-weekly-course-plan/'+weeklyCoursePlanId, formData)
}

const deleteCourseCoordinatorWeeklyCoursePlan = async (weeklyCoursePlanId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/ects/course-coordinator-delete-weekly-course-plan/'+weeklyCoursePlanId)
}

const getProgramCoordinatorWeeklyCoursePlans = async (courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-coordinator-weekly-course-plans/'+courseId)
}

const storeProgramCoordinatorWeeklyCoursePlan = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/ects/program-coordinator-store-weekly-course-plan', formData)
}

const updateProgramCoordinatorWeeklyCoursePlan = async (weeklyCoursePlanId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/program-coordinator-update-weekly-course-plan/'+weeklyCoursePlanId, formData)
}

const deleteProgramCoordinatorWeeklyCoursePlan = async (weeklyCoursePlanId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/ects/program-coordinator-delete-weekly-course-plan/'+weeklyCoursePlanId)
}

const getCourseCoordinatorCourseAssessments = async (courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/course-coordinator-course-assessments/'+courseId)
}

const storeCourseCoordinatorCourseAssessments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/ects/course-coordinator-store-course-assessments', formData)
}

const updateCourseCoordinatorCourseAssessments = async (assessmentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/course-coordinator-update-course-assessment/'+assessmentId, formData)
}

const deleteCourseCoordinatorCourseAssessments = async (assessmentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/ects/course-coordinator-delete-course-assessment/'+assessmentId)
}

const getProgramCoordinatorCourseAssessments = async (courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/ects/program-coordinator-course-assessments/'+courseId)
}

const storeProgramCoordinatorCourseAssessments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.post('/ects/program-coordinator-store-course-assessments', formData)
}

const updateProgramCoordinatorCourseAssessments = async (assessmentId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.put('/ects/program-coordinator-update-course-assessment/'+assessmentId, formData)
}

const deleteProgramCoordinatorCourseAssessments = async (assessmentId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.delete('/ects/program-coordinator-delete-course-assessment/'+assessmentId)
}

export default {
    getAll,
    updateCourseByCoordinator,
    getProgramCoursesAll,
    updateCourseByProgramCoordinator,
    updateCourseByAdmin,
    getProgramCoordinatorCourses,
    getCourseCoordinatorWeeklyCoursePlans,
    storeCourseCoordinatorWeeklyCoursePlan,
    updateCourseCoordinatorWeeklyCoursePlan,
    deleteCourseCoordinatorWeeklyCoursePlan,
    getProgramCoordinatorWeeklyCoursePlans,
    storeProgramCoordinatorWeeklyCoursePlan,
    updateProgramCoordinatorWeeklyCoursePlan,
    deleteProgramCoordinatorWeeklyCoursePlan,
    getCourseCoordinatorCourseAssessments,
    storeCourseCoordinatorCourseAssessments,
    updateCourseCoordinatorCourseAssessments,
    deleteCourseCoordinatorCourseAssessments,
    getProgramCoordinatorCourseAssessments,
    storeProgramCoordinatorCourseAssessments,
    updateProgramCoordinatorCourseAssessments,
    deleteProgramCoordinatorCourseAssessments
}
