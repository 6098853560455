<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduate_card_create')" :isNewButton="false" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('graduate_card_create')" :isNewButton="false" :isColumns="true"
                    @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-mask="'#######'" v-model="datatable.queryParams.filter.student_number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('national_number')">
                            <b-form-input v-model="datatable.queryParams.filter.national_number" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                :is_faculty_code_required="true" v-model="datatable.queryParams.filter.program_code" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.registration_academic_year" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('registration_season')">
                            <semester-type-selectbox v-model="datatable.queryParams.filter.registration_season" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('card_status')">
                            <multi-selectbox :multiple="false" :options="cardStatusOptions"
                                v-model="datatable.queryParams.filter.card_status" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="photoModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal">
                <template v-slot:CommonModalContent>
                    <div id="printCard">
                        <img v-if="asPrint" :src="cardPhoto" style="width: 100%" />
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="printModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ $t('print').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <card-print :cardData="selectedData"></card-print>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter"
import Datatable from "@/components/datatable/Datatable"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import CommonModal from "@/components/elements/CommonModal";
import toBase64 from "@/helpers/toBase64"

//Pages
import CardPrint from "./CardPrint";
// Services
import AlumniService from "@/services/AlumniService";
import AlumniCardService from "@/services/AlumniCardService";

// Others
import { ValidationProvider, ValidationObserver } from "vee-validate"
import qs from 'qs'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ValidationProvider,
        ValidationObserver,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        AcademicYearsSelectbox,
        SemesterTypeSelectbox,
        MultiSelectbox,
        CommonModal,
        CardPrint
    },
    metaInfo() {
        return {
            title: this.$t('graduate_card_create')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('create'),
                                class: 'ri-chat-new-line align-middle top-minus-1 mr-3 text-muted',
                                //permission: "graduateapplication_show",
                                callback: ({ id }) => {
                                    this.cardCreate(id)
                                }
                            },
                            {
                                text: this.$t('print'),
                                class: 'ri-printer-line align-middle top-minus-1 mr-3 text-muted',
                                //permission: "graduateapplicationmeeting_show",
                                callback: ({ id }) => {
                                    this.cardPrint(id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        field: 'student_number',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('national_number'),
                        field: 'national_id',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('card_status') + '/' + this.toUpperCase('issue_date'),
                        field: 'alumni_card_status',
                        hidden: false,
                        html: true,
                        formatFn: (row, itm) => {
                            let result = ''
                            if (itm.alumni_card_status != null) {
                                result += itm.alumni_card_status === 'card_created'
                                    ? `<span class="badge badge-pill badge-success mr-1"> ${this.$t('card_created')} </span>`
                                    : `<span class="badge badge-pill badge-danger"> ${this.$t('card_not_created')} </span>`;
                            } else {
                                result += '';
                            }
                            if (itm.alumni_card_print_date != null) {
                                result += '<span class="badge badge-pill badge-primary">' + itm.alumni_card_print_date + '</span>';
                            } else {
                                result += '';
                            }
                            return result;
                        }
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },

            cardStatusOptions: [
                {
                    text: this.$t('card_created'),
                    value: 'card_created'
                },
                {
                    text: this.$t('card_not_created'),
                    value: 'card_not_created'
                }
            ],

            selectedData: {},
            cardPhoto: null,
            asPrint: null,

        }
    },
    created() {
        this.filterSet();
        this.formClear();
        this.checkUrlForStudentNumber()
    },
    methods: {
        toBase64: toBase64,
        filterSet() {
            this.datatable.queryParams.filter = {

            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return AlumniService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        // Clear
        formClear() {

        },

        // cardCreate(row) {
        //     if(row.card_status == this.$t('card_created')) {
        //         this.$swal({
        //             icon: "info",
        //             title: this.$t('info'),
        //             text: this.$t('created_card_info',{date: row.issue_date}),
        //             confirmButtonText: this.$t('ok')
        //         })
        //     } else {
        //         this.$toast.success(this.$t('card_created'))
        //     }
        // },

        async cardCreate(id) {
            try {
                let data = await AlumniCardService.createCard(id);
                this.asPrint = true;
                this.cardPhoto = toBase64(data);
                this.$refs.photoModal.$refs.commonModal.show();
            } catch (err) {
                this.showErrors(err);
            }
        },
        async cardPrint(id) {
            try {
                let data = await AlumniCardService.printCard(id);
                let b64 = toBase64(data);
                const winHtml = '<html><body style="margin:0"><img src="' + b64 + '" width="319"></body></html>';

                const winUrl = URL.createObjectURL(
                    new Blob([winHtml], { type: "text/html" })
                );

                let x = screen.width / 2 - 160;
                const win = window.open(
                    winUrl,
                    "Print",
                    "width=330,height=300,screenX=" + x
                );
                win.print();
            } catch (err) {
                this.showErrors(err);
            }
        },
        // cardPrint(row) {
        //     if(row.card_status == this.$t('card_created')) {
        //         this.selectedData = row
        //         this.$refs.printModal.$refs.commonModal.show()
        //     } else {
        //         this.$swal({
        //             icon: "info",
        //             title: this.$t('info'),
        //             text: this.$t('card_not_created'),
        //             confirmButtonText: this.$t('ok')
        //         })
        //     }
        //
        // },
        closeModal() {
            this.$refs.photoModal.$refs.commonModal.hide();
            this.asPrint = null;
            this.cardPhoto = null;
        },

        checkUrlForStudentNumber() {
            if (this.$route.params.studentNumber !== undefined) {
                this.datatable.queryParams.filter.student_number = parseInt(this.$route.params.studentNumber)
                this.getRows()
            }
        }

    }
}
</script>
