import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exam/schedules/observer', config);
}

const downloadExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/exam/schedules/observer/excel-export',{...config,responseType:'arraybuffer'})
}

export default {
    getAll,
    downloadExcel
}
