<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="row">
                <div class="col-6">
                    <b-form-group :label="$t('club_name')">
                        <b-form-input  readonly v-model="form.club_name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_name')">
                        <b-form-input  readonly v-model="form.event_name"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_type')">
                        <b-form-input  readonly v-model="form.event_type"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_format')">
                        <b-form-input  readonly v-model="form.event_format"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_start_date')">
                        <b-form-input  readonly v-model="form.event_start_date"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_end_date')">
                        <b-form-input  readonly v-model="form.event_end_date"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('event_location')">
                        <b-form-input  readonly v-model="form.event_location"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('purpose')">
                        <b-form-input  readonly v-model="form.purpose"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('number_of_participants')">
                        <b-form-input  readonly v-model="form.number_of_participants"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('external_participation')">
                        <b-form-input  readonly v-model="form.external_participation"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('speaker')">
                        <b-form-input  readonly v-model="form.speaker"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('sponsor')">
                        <b-form-input  readonly v-model="form.sponsor"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('requirement_list')">
                        <b-form-input  readonly v-model="form.requirement_list"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('transport_audio_video_systems')">
                        <b-form-input  readonly v-model="form.transport_audio_video_systems"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('budget_items')">
                        <b-form-input  readonly v-model="form.budget_items"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('material_requests')">
                        <b-form-input  readonly v-model="form.material_requests"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('registration_date_just')">
                        <b-form-input  readonly v-model="form.registration_date_just"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group :label="$t('academic_advisor_approval_date')">
                        <b-form-input  readonly v-model="form.academic_advisor_approval_date"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <ValidationProvider name="academic_advisor_approval" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('academic_advisor_approval')">
                            <b-form-select v-model="form.academic_advisor_approval" :options="statusOptions" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider name="message" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('message')">
                            <b-form-textarea v-model="form.message"></b-form-textarea>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" ></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>

        <div class="col-6 mt-3 d-flex">
            <b-button
                @click="updateForm"
                type="button"
                :disabled="formLoading"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Component
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import CertificateCenterService from "@/services/CertificateCenterService";

export default {
    components: {
        ValidationProvider, ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                akademik_danisman_onayi: null,
                name: null,
            },
            statusOptions: [
                {value: 1, text: this.$t('approved')},
                {value: 0, text: this.$t('declined')}
            ],
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            CertificateCenterService.get(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.formLoading = false;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                });
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                CertificateCenterService.update(this.formId, this.form)
                    .then((response) => {
                        this.formLoading=false;
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    })
            }
        }
    }
}
</script>
